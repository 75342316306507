import React, { Fragment, memo } from 'react'
import { IDiscount } from '../model/order.store'

import styles from './DiscountItems.module.scss'
import tableStyles from '../scss/table.module.scss'
import { FormattedCurrency } from './FormattedCurrency'

interface DiscountItemsProps {
  discounts: IDiscount[]
}

export const DiscountItems = memo(({ discounts }: DiscountItemsProps) => {
  return (
    <Fragment>
      <tr className={`${tableStyles['top-bordered']} ${tableStyles['header-row']}`}>
        <td colSpan={2}>Giảm giá</td>
      </tr>
      {discounts.map((discount, index) => (
        <tr key={index} data-testid="row-discount-item">
          <td className={styles['discount-description']}>{discount.description}</td>
          <td className={tableStyles['quantity-cell']}>
            -<FormattedCurrency value={discount.amount} />
          </td>
        </tr>
      ))}
    </Fragment>
  )
})
