import { observable, decorate, action, runInAction } from 'mobx'
import { clone } from 'lodash'

import {
  IOrderItem,
  IDiscount,
  IComputedItem,
  TShippingProvider,
  OrderStore,
  TOrderPlatform,
  IPayment
} from './order.store'
import { ICustomer, IVoucher } from './customer.model'
import Bluebird from 'bluebird'
import { TShift } from './current-shift.store'
import { IOutlet } from './outlet-list.store'
import { RootStore } from './root-store'
import { toast } from 'react-toastify'
import { getObjectId } from '../libs/object-id'
import { handleAgentErrorResponse } from '../libs/error-handler'

export interface IOrder {
  _id: string
  status: 'ordered' | 'done' | null
  orderId: string
  date: Date | string
  shift: TShift | string
  outlet: IOutlet | string
  items: IOrderItem[]
  couponCode?: string
  computedItems: IComputedItem[]
  computedCustomer: ICustomer | null
  availableVouchers: IVoucher[]
  discounts: IDiscount[]
  customer: ICustomer | null
  voucher: IVoucher | null
  orderPlatform: TOrderPlatform
  selectedOrderPlatform: TOrderPlatform
  availableOrderPlatforms: TOrderPlatform[]
  payments?: IPayment[]
  subTotal: number
  discountTotal: number
  saleTotal: number
  deposit: number
  created: string | Date
  ordered: string | Date
  done?: string | Date
  updated?: string | Date
  cancelled?: string | Date
  cancelReason?: string
  shippingProvider?: TShippingProvider | null
  shippingBillNumber?: string
  shippingPaymentMethod?: string
  shippingPay: number
  shippingCollect: number
  shippingCustomerName?: string
  shippingCustomerPhone?: string
  shippingCustomerAddress?: string
  onlineOrderId?: string
  serviceMode: string
  cashTotal: number
  receivedMoney?: number
  computedReceivableAmount: number
  computedReceiveAmount: number
  commissionAmount: number
  commissionableAmount: number
  commissionPercentage: number
  nowOrderCode: string
  momoTransId: string
  orderCode?: String | null
  goFoodPin?: String | null
  transferCode?: String | null
  groupPaid?: boolean
}

export class ListOrderStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  orderStores: OrderStore[] = []

  get orderedOrders() {
    return this.orderStores
      .filter(order => order.status === 'ordered')
      .sort((a, b) => {
        if (a.ordered && b.ordered) return a.ordered < b.ordered ? -1 : 1
        return 1
      })
  }

  loadOrderStores(orderStores: OrderStore[]) {
    this.orderStores = orderStores
  }

  createOrderStore = (order: IOrder) => {
    const orderStore = new OrderStore()
    orderStore.loadRootStore(this.rootStore)
    orderStore.loadOrderStore(order)
    orderStore.setAutoSave(true)
    orderStore.setAutoUpdateProductStatus(false)
    if (orderStore.payments && orderStore.payments.length) {
      orderStore.payments.forEach(payment => {
        if (payment.paymentType === 'CASH') {
          orderStore.removePayment(payment.uniqueId)
          // Đoạn này phải loại các payment có loại CASH vì trên server lúc nào
          // cũng sẽ thêm payment kiểu CASH cho đủ số tiền
        }
      })
    }
    return orderStore
  }

  findAndUpdateOrder(order: IOrder) {
    const matchOrderStoreIndex = this.orderStores.findIndex(_order => _order._id === order._id)
    const orderStore = this.createOrderStore(order)
    if (matchOrderStoreIndex > -1) {
      this.orderStores[matchOrderStoreIndex] = orderStore
    } else {
      this.orderStores.push(orderStore)
    }
  }

  async doneOrder(order: OrderStore) {
    if (!this.rootStore.currentShiftStore.currentShift) {
      throw new Error('Chưa đăng nhập vào ca trực')
    }
    const currentShift = this.rootStore.currentShiftStore.currentShift
    return this.rootStore.agent
      .put<IOrder>('/orders/' + order._id + '/done', {
        currentShift: {
          outlet: currentShift.outlet,
          shift: currentShift.shift,
          date: currentShift.date
        }
      })
      .then(order => {
        this.findAndUpdateOrder(order)
      })
  }

  cancelOrder(order: OrderStore, cancelReason: String) {
    return this.rootStore.agent
      .put('/orders/' + order._id + '/cancel', { cancelReason })
      .then(order => {
        this.findAndUpdateOrder(order)
      })
  }

  async printOrder(order: OrderStore) {
    try {
      await this.rootStore.agent.get('/orders/' + order._id + '/print-order')
      toast.success('Gửi lệnh in nhãn thành công !')
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }

  updateOrder(order: OrderStore) {
    return Bluebird.resolve(
      this.rootStore.agent.put('/orders/' + order._id, order).then((order: IOrder) => {
        this.findAndUpdateOrder(order)
      })
    )
  }

  listOrders(options?: { status: string }) {
    const params: { status?: string; outlet?: string | IOutlet; shift?: TShift } = {}

    if (
      !this.rootStore.currentShiftStore.loggedInShift &&
      !this.rootStore.authenticationStore.canUseCurrentOutlet
    ) {
      toast.error('Chưa xem được đơn hàng do chưa đăng nhập vào ca trực')
      return
    }

    if (
      !this.rootStore!.authenticationStore.canUseCurrentOutlet ||
      this.rootStore!.currentShiftStore.loggedInShift
    ) {
      params.outlet = getObjectId(this.rootStore.currentShiftStore.currentShift!.outlet)
    }

    if (options && options.status) {
      params.status = options.status
    }

    return this.rootStore.agent
      .get<IOrder[]>('/orders', {
        params
      })
      .then(orders => {
        this.loadOrderStores(orders.map(this.createOrderStore))
      })
  }
}

decorate(ListOrderStore, {
  orderStores: observable,
  updateOrder: action,
  findAndUpdateOrder: action,
  loadOrderStores: action,
  createOrderStore: action
})
