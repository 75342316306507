import React from 'react'
import Downshift from 'downshift'
import Districts, { IDistrict } from '../model/district.store'
import { Form, Segment, List, Input, FormField } from 'semantic-ui-react'

interface InputDistrictProps {
  onSelect: (item: any, state?: any) => void
  initialSelectedItem?: any
  districtLabel?: string
  required?: boolean
}

const itemToString = (district: IDistrict | null) => {
  if (!district) return ''
  return district.label
}

export function InputDistrict({
  onSelect,
  required,
  initialSelectedItem,
  districtLabel
}: InputDistrictProps) {
  let district: IDistrict | undefined
  if (districtLabel) {
    district = Districts.getDistricts().find(distirct => distirct.label === districtLabel)
  }
  return (
    <Downshift
      onChange={onSelect}
      itemToString={itemToString}
      initialSelectedItem={district || initialSelectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
        clearSelection,
        selectedItem
      }) => (
        <div className={`field ${required && 'required'}`}>
          <label>Tìm quận huyện</label>
          <Input
            {...getInputProps()}
            placeholder="Nhập tên quận/huyện..."
            icon={
              selectedItem
                ? {
                    name: 'remove',
                    onClick: clearSelection,
                    link: true
                  }
                : undefined
            }
          />
          {isOpen && inputValue ? (
            <Segment style={{ position: 'relative', zIndex: '500' }}>
              <List {...getMenuProps()} divided size="massive">
                {Districts.getDistricts()
                  .filter(
                    item =>
                      !inputValue ||
                      item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                      item.latinLabel.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .splice(0, 10)
                  .map((item, index) => (
                    <List.Item
                      {...getItemProps({
                        key: item.label,
                        index,
                        item,
                        style: {
                          fontWeight: highlightedIndex === index ? 'bold' : 'normal'
                        }
                      })}
                    >
                      {item.label}
                    </List.Item>
                  ))}
              </List>
            </Segment>
          ) : null}
        </div>
      )}
    </Downshift>
  )
}
