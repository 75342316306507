import React, { SyntheticEvent, Fragment } from 'react'
import {
  Modal,
  Form,
  Divider,
  InputProps,
  Header,
  Dimmer,
  Loader,
  Segment,
  Input
} from 'semantic-ui-react'

import { IVoucher, customerStore } from '../model/customer.model'
import Bluebird from 'bluebird'
import { handleErrorResponse } from '../libs/error-handler'

interface IModalEditVoucherProps {
  onSelectVoucher: Function
}

interface IModalEditVoucherState {
  openModal: boolean
  clickedSearch: boolean
  loadingVoucher: boolean
  couponCode: string
  voucher: IVoucher | null
}

export class ModalSelectVoucher extends React.Component<
  IModalEditVoucherProps,
  IModalEditVoucherState
> {
  constructor(props: any) {
    super(props)
    this.state = this.initialState
  }

  initialState: IModalEditVoucherState = {
    openModal: false,
    couponCode: '',
    clickedSearch: false,
    voucher: null,
    loadingVoucher: false
  }

  searchInput: Input | null = null
  nameInput: Input | null = null

  handleInputRef = (input: Input) => {
    this.searchInput = input
  }

  resetState = () => {
    this.setState(this.initialState)
  }

  handleCouponCodeChange = (e: SyntheticEvent, { value }: InputProps) => {
    this.setState({ couponCode: value })
  }

  selectVoucher = (voucher: IVoucher) => {
    this.props.onSelectVoucher(voucher)
    this.resetState()
  }

  updateOrderPromise?: Bluebird<any>

  searchingVoucherPromise?: Bluebird<any>
  handleSearchVoucher = async () => {
    this.setState({
      loadingVoucher: true,
      clickedSearch: true
    })
    try {
      this.searchingVoucherPromise = customerStore.searchVoucher(this.state.couponCode)
      const voucher = await this.searchingVoucherPromise

      this.setState({ voucher })
      this.selectVoucher(voucher)
      this.handleCloseModal()
    } catch (e) {
      handleErrorResponse(e)
    } finally {
      if (!this.searchingVoucherPromise!.isCancelled()) {
        this.setState({ loadingVoucher: false })
      }
    }
  }

  componentWillUnmount() {
    if (this.searchingVoucherPromise) this.searchingVoucherPromise.cancel()
    if (this.updateOrderPromise) this.updateOrderPromise.cancel()
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.focus()
      }
    })
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  render() {
    return (
      <Modal
        trigger={this.props.children}
        open={this.state.openModal}
        onOpen={this.handleOpenModal}
        onClose={this.handleCloseModal}
      >
        <Modal.Header>Tìm Voucher</Modal.Header>
        <Modal.Content>
          <Divider />

          <Fragment>
            <Form>
              <Form.Field>
                <Input
                  type="tel"
                  value={this.state.couponCode}
                  onChange={this.handleCouponCodeChange}
                  ref={this.handleInputRef}
                  action={{
                    color: 'teal',
                    labelPosition: 'right',
                    icon: 'search',
                    content: 'Tìm kiếm',
                    onClick: this.handleSearchVoucher,
                    disabled: this.state.loadingVoucher
                  }}
                  placeholder="Nhập mã voucher"
                />
              </Form.Field>
            </Form>
            <Segment basic vertical>
              <Dimmer inverted active={this.state.loadingVoucher}>
                <Loader />
              </Dimmer>
              {!this.state.voucher && this.state.clickedSearch && (
                <Header>Không tìm thấy voucher!</Header>
              )}
            </Segment>
          </Fragment>
        </Modal.Content>
      </Modal>
    )
  }
}
