import React from 'react'
import { Tab } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import { RootStore } from '../../model/root-store'
import { handleAgentErrorResponse } from '../../libs/error-handler'
import { toast } from 'react-toastify'

import {
  ToggleProductStatusesInput,
  StockProduct
} from '../../components/ToggleProductStatusesInput'
import { ListProductStatuses } from './ListProductStatuses'
import { ListDishStatuses } from './ListDishStatuses'
import { IProduct } from '../../model/product.store'

interface UpdateProductStatusesProps {
  rootStore?: RootStore
}
interface UpdateProductStatusesState {
  products: StockProduct[]
}

export const UpdateProductStatuses = inject('rootStore')(
  observer(
    class extends React.Component<UpdateProductStatusesProps, UpdateProductStatusesState> {
      state = {
        products: []
      }

      componentDidMount() {
        this.getProducts()
      }

      getProducts = async () => {
        try {
          const currentShift = this.props.rootStore!.currentShiftStore.currentShift
          if (!currentShift) return toast.error('Chưa đăng nhập ca trực')
          const products = await this.props.rootStore!.agent.get('/api_savor/products', {
            params: {
              currentShiftId: currentShift._id
            }
          })
          this.setState({ products })
        } catch (e) {
          handleAgentErrorResponse(e)
        }
      }

      handleToggle = async (product: StockProduct, inStock: boolean) => {
        let toastId: number = 0

        try {
          const currentShift = this.props.rootStore!.currentShiftStore.currentShift
          if (!currentShift) return toast.error('Chưa đăng nhập ca trực')

          toastId = toast(`Đang cập nhật trạng thái cho ${product.description}`, {
            type: toast.TYPE.INFO,
            autoClose: false
          })

          await this.props.rootStore!.agent.post(
            `/api_savor/products/${product._id}/update-status`,
            {
              product,
              inStock,
              currentShiftId: currentShift._id
            }
          )

          await this.getProducts()

          toast.update(toastId, {
            render: `Cập nhật ${product.description} thành công !`,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000
          })
        } catch (e) {
          handleAgentErrorResponse(e)
          toast.update(toastId, {
            render: `Cập nhật ${product.description} thành công !`,
            type: toast.TYPE.ERROR
          })
        }
      }

      evaluatePriorityProduct = (product: StockProduct) => {
        if (/^Món/.test(product.savorTag) && product.savorTag !== 'Món Abby Kitchen') {
          return {
            ...product,
            priority: 1
          }
        } else if (product.savorTag === 'Nguyên liệu trà sữa') {
          return {
            ...product,
            priority: 2
          }
        } else if (/^Vận hành/.test(product.savorTag)) {
          return {
            ...product,
            priority: 3
          }
        } else {
          return {
            ...product,
            priority: 4
          }
        }
      }

      render() {
        const panes = [
          {
            menuItem: 'Cập nhật trạng thái cho nguyên liệu',
            render: () => (
              <Tab.Pane>
                <div
                  style={{
                    visibility: this.props.rootStore!.uiStore.showProductStatuses
                      ? 'visible'
                      : 'hidden'
                  }}
                >
                  <ListProductStatuses
                    products={this.state.products}
                    onToggle={this.handleToggle}
                    onEvaluatePriorityProduct={this.evaluatePriorityProduct}
                  />
                </div>
              </Tab.Pane>
            )
          },
          {
            menuItem: 'Trạng thái cho các món',
            render: () => {
              const products: IProduct[] = this.state.products.filter((product: IProduct) => {
                return product.bundledProducts && product.bundledProducts.length > 0
              })

              return (
                <Tab.Pane>
                  <ListDishStatuses
                    products={products}
                    onToggle={this.handleToggle}
                    onEvaluatePriorityProduct={this.evaluatePriorityProduct}
                  />
                </Tab.Pane>
              )
            }
          }
        ]

        return (
          <div
            style={{
              height: '100%',
              overflowY: 'auto',
              overflowX: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <Tab panes={panes} />
          </div>
        )
      }
    }
  )
)
