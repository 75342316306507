import React, { useContext } from 'react'

import { Menu, Icon } from 'semantic-ui-react'

import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'

import { routes } from '../config/routes'
import { withRouter, Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../model/root-store'

interface HeaderProps extends RouteComponentProps {}

export const MainMenu = withRouter(
  observer(function({ location }: HeaderProps) {
    const rootStore = useContext(RootStoreContext)
    const uiStore = rootStore.uiStore
    return (
      <Menu fixed="bottom" inverted style={{ overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>
        <Menu.Item
          name="bars"
          onClick={() => {
            if (uiStore.openSidebar) {
              uiStore.setOpenSidebar(false)
            } else {
              uiStore.setOpenSidebar(true)
            }
          }}
        >
          <Icon name="bars" size="big" />
        </Menu.Item>
        <Menu.Item
          name="pencil alternate"
          active={location.pathname === '/orders/create'}
          as={Link}
          to="/orders/create?category=bread"
        >
          <Icon name="pencil alternate" size="big" />
        </Menu.Item>
        {/* <Menu.Item
          name="sticky note"
          active={location.pathname === '/orders'}
          as={Link}
          to="/orders"
        >
          <Icon name="sticky note" size="big" />
        </Menu.Item> */}
        <Menu.Item
          name="Update Stock Status"
          as={Link}
          to="/update-stock"
          active={location.pathname === '/update-stock'}
        >
          <Icon name="nintendo switch" size="big" />
        </Menu.Item>
        <Menu.Item>
          <Icon name="angle right" color="black" />
        </Menu.Item>
        <Switch>
          <Redirect path="/" exact to="/orders/create" />
          {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} component={route.title} />
          ))}
        </Switch>
      </Menu>
    )
  })
)
