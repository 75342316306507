import React from 'react'
import { Route, Redirect } from 'react-router'
import { observer, inject } from 'mobx-react'
import { AuthentiationStore } from '../model/authentication.store'
import { RootStore } from '../model/root-store'

interface NoAuthRouteProps {
  component: any
  rootStore?: RootStore
  [key: string]: any
}

export const NoAuthRoute = inject('rootStore')(
  observer(function({ component: Component, rootStore, ...rest }: NoAuthRouteProps) {
    const authenticationStore = rootStore!.authenticationStore
    return (
      <Route
        {...rest}
        render={props =>
          !authenticationStore.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  })
)
