import { observable, action, decorate, runInAction, reaction, computed } from 'mobx'

import { RootStore } from './root-store'
import { IProduct } from './product.store'
import { toast } from 'react-toastify'
import { handleAgentErrorResponse } from '../libs/error-handler'

export interface IProductStatus extends IProduct {
  inStock: boolean
}

export interface TProductMap {
  [key: string]: IProductStatus
}

export class ProductStatusStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  products: IProductStatus[] = []
  get productMap(): TProductMap {
    return this.products.reduce<TProductMap>((result, product) => {
      result[product.sku] = product
      return result
    }, {})
  }

  loadProducts(products: IProductStatus[]) {
    this.products = products
  }

  async getProducts() {
    try {
      const currentShift = this.rootStore!.currentShiftStore.currentShift
      let products = []

      if (
        this.rootStore!.authenticationStore.canUseCurrentOutlet &&
        this.rootStore!.currentShiftStore.currentOutlet
      ) {
        const outlet = this.rootStore.outletListStore.findOutletById(
          this.rootStore!.currentShiftStore.currentOutlet
        )
        const outletCode = outlet ? outlet._id : ''
        products = await this.rootStore!.agent.get('/api_savor_server/products', {
          params: { outletCode }
        })
      } else {
        if (
          this.rootStore!.authenticationStore.canUseCurrentOutlet &&
          !this.rootStore!.currentShiftStore.currentOutlet &&
          !this.rootStore!.currentShiftStore.loggedInShift
        ) {
          return toast.error('Chưa chọn cơ sở để cập nhật tình hàng còn/hết')
        }
        if (!currentShift) return toast.error('Chưa đăng nhập ca trực')
        products = await this.rootStore!.agent.get('/api_savor/products', {
          params: {
            currentShiftId: currentShift._id
          }
        })
      }

      this.loadProducts(products)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }

  socketReaction = reaction(
    () => {
      return this.rootStore!.realtimeEvent.namespace
    },
    () => this.init(),
    {
      fireImmediately: true
    }
  )

  init() {
    this.getProducts()
    this.rootStore!.realtimeEvent.subcribe('productStatuses', () => this.getProducts())
  }
}

decorate(ProductStatusStore, {
  products: observable,
  productMap: computed,
  loadProducts: action
})
