import request from 'superagent'
import { WORK_URL } from '../config/environments'
import Bluebird from 'bluebird'

export type TVoucherType =
  | 'OPENING_20_PERCENT'
  | 'STAFF_DISCOUNT'
  | 'AMOUNT'
  | 'NONE'
  | 'DISCOUNT_CAKE_ORDER_BIG'
  | 'DISCOUNT_CAKE_ORDER_NORMAL'
  | 'DISCOUNT_CAKE_ORDER'
  | 'DISCOUNT_CAKE_ORDER_10_PERCENT'
  | 'VOUCHER_50K'
  // baemin
  | 'HANOIPA1'
  | 'HANOIPA2'
  | 'HANOIPA3'
  | 'HANOIPA4'
  | 'HANOIPA5'
  | 'HANOIPA6'
  | 'HANOIPA7'
  | 'HANOIPA8'
  // Befood
  | 'BE150'
  | 'BE250'
  | 'BE200'
  | 'BE500'
  // GOJEK
  | 'CART_OFF_50K'
  | 'CART_OFF_65K'
  | 'CART_OFF_85K'
  | 'CART_OFF_100K'
  | 'CART_OFF_120K'
  | 'CART_OFF_150K'
  | 'CART_OFF_170K'
  // GRAB
  | 'BOSS20'
  | 'BOSS30B'
  | 'BOSS40'
  | 'BOSS50'
  | 'BOSS70'
  // shopeefood
  | 'SIEUTIEC10'
  | 'SIEUTIEC20'
  | 'SIEUTIEC30'
  | 'SIEUTIEC40'
  | 'SIEUTIEC50'
  | 'SIEUTIEC50K'
  | 'SIEUTIEC70'
  | 'SIEUTIEC99'
  | 'SIEUTIEC30K'
  | 'SIEUTIEC40K'
  | 'SIEUTIEC35K'
  | 'SIEUTIEC45K'
  | 'SIEUTIEC75K'
  | 'SIEUTIEC109K'
  | 'SIEUTIEC139K'
  | 'SIEUTIEC199K'
  | '1111SIEUDEAL45'
  | '1111SIEUDEAL35'
  | '1111SIEUDEAL110'

export interface IVoucher {
  _id: string
  name: string
  couponCode?: string
  voucherType: TVoucherType
  amount: number
  quantity?: number
  withoutCard?: boolean
}

export interface ICustomer {
  _id: string
  fullName: string
  cellphone: string
  customerId: string
  searchCustomerType?: string
  numRemainDiscount?: number
  remainDiscountValue?: number
  customerPoints?: number
  isStaff?: boolean
  vouchers?: IVoucher[]
  birthday?: Date
  address?: {
    district?: string
    street?: string
    city?: string
  }
  registered?: boolean
  registerTime?: Date
  drinkSkus?: []
  mooncakeSkus?: []
  mooncakePercent?: number
  numRemainMooncakeDiscount?: number
  tetCakeCandySkus?: []
  tetCakeCandyProducts?: []
  tetCakeCandyPercent?: number
  boughtSavorStoreSalesMap?: {}
  sharkTankFireworkGiftedOnlineOrder?: string
  sharkTankFireworkSold?: boolean
  discountStaffSavorBread?: boolean
  discountStaffSavorCake?: boolean
  numSaleBread?: number
  numSaleCake?: number
}

export class CustomerStore {
  searchCustomer = (searchText: string) => {
    return Bluebird.resolve(
      request
        .get(WORK_URL + '/api/customers/search')
        .query({
          searchText
        })
        .then(response => response.body)
    )
  }
  searchVoucher = (couponCode: string) => {
    return Bluebird.resolve(
      request
        .get(`${WORK_URL}/savor_api/vouchers/search`)
        .query({
          couponCode: couponCode
        })
        .then(response => response.body)
    )
  }

  createCustomer = (customer: ICustomer) => {
    return Bluebird.resolve(
      request
        .post(`${WORK_URL}/api/customers`)
        .send(customer)
        .then(response => response.body)
    )
  }

  updateCustomer = (customer: ICustomer) => {
    return request
      .put(`${WORK_URL}/api_savor/customers/${customer._id}`)
      .send(customer)
      .then(response => response.body as ICustomer)
  }
}

export const customerStore = new CustomerStore()
