import React, { useEffect, useContext, useState } from 'react'
import sound from '../assets/audio/new-order.mp3'
import reloadBrowserSound from '../assets/audio/tai-lai-trinh-duyet.mp3'
import { RootStoreContext } from '../model/root-store'
import { observer } from 'mobx-react-lite'
import { IOrder } from '../model/list-order.store'
import { toast } from 'react-toastify'

export const SoundNotification = observer(({ children }) => {
  const rootStore = useContext(RootStoreContext)
  const uiStore = rootStore.uiStore

  // useEffect(() => {
  //   console.log(`subcirbe ${rootStore.realtimeEvent.namespace}`)
  //   rootStore!.realtimeEvent.subcribe('newOrder', handleNewOrder)
  //   return () => {
  //     console.log(`unsubcirbe`)
  //     rootStore.realtimeEvent.unsubcribe('newOrder', handleNewOrder)
  //   }
  // }, [rootStore.realtimeEvent.namespace])

  // function handleNewOrder(order: IOrder) {
  //   if (order.orderPlatform === 'SAVOR_ONLINE' && rootStore.currentShiftStore.loggedInShift) {
  //     toast.info(`Có đơn hàng mới từ tổng đài: #${order.orderId}`)
  //     const audio = new Audio(sound)
  //     audio.play()
  //   }
  // }

  useEffect(() => {
    rootStore!.realtimeEvent.subcribe('reloadPage', handleReloadBrowser)
    return () => {
      rootStore.realtimeEvent.unsubcribe('reloadPage', handleReloadBrowser)
    }
  }, [rootStore.realtimeEvent.namespace])

  function handleReloadBrowser() {
    if (rootStore.currentShiftStore.loggedInShift && !uiStore.notifyingReload) {
      const notifyReloadPage = () => {
        const audio = new Audio(reloadBrowserSound)
        audio.play()
        toast.info(`Nhấn CTRL + SHIFT + R để tải lại trình duyệt!`)
      }
      notifyReloadPage()
      setInterval(notifyReloadPage, 20 * 1000)
      uiStore.setNotifyingReload(true)
    }
  }

  return <>{children}</>
})
