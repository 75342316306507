import socketio from 'socket.io-client'
import { SERVER_URL } from './environments'
import { reaction, runInAction, decorate, observable, computed } from 'mobx'
import { find, toLower } from 'lodash'
import { RootStore } from '../model/root-store'
import { getCodeFromShiftRecordId } from '../model/current-shift.store'

export class RealtimeEvent {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }
  socket: SocketIOClient.Socket | null = null
  get namespace() {
    return this.socket ? this.socket.nsp : ''
  }

  autoReconnect = reaction(
    () => {
      return this.rootStore!.currentShiftStore.currentOutlet
        ? this.rootStore!.currentShiftStore.currentOutlet
        : this.rootStore!.currentShiftStore.currentShift
        ? this.rootStore!.currentShiftStore.currentShift!.shiftRecordId
        : ''
    },
    data => {
      if (this.socket) {
        this.socket.disconnect()
      }

      const outlet = this.rootStore.outletListStore.findOutletById(data)
      const outletCode = getCodeFromShiftRecordId(data) || (outlet ? outlet.code : '')
      runInAction(() => {
        this.socket = socketio(SERVER_URL + `/${toLower(outletCode)}/mobile-app`)
      })
    },
    {
      fireImmediately: true
    }
  )

  subcribe<T>(event: string, callback: Function) {
    if (!this.socket) return null
    return this.socket.on(event, callback)
  }
  unsubcribe(event: string, cb: Function) {
    if (!this.socket) return null
    if (cb) {
      this.socket.removeListener(event, cb)
    }
  }
}

decorate(RealtimeEvent, {
  socket: observable,
  namespace: computed
})
