import React, { useContext, useEffect } from 'react'

import { Container, Segment } from 'semantic-ui-react'
import { get } from 'lodash'

import ReviewSaleEntry from '../../components/ReviewSaleEntry'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../model/root-store'
import { toast } from 'react-toastify'

interface IHTMLElement extends HTMLElement {
  contentWindow: any
}

interface IPrintSaleEntryProps {
  successText?: string
}

export const PrintSaleEntry: React.SFC<IPrintSaleEntryProps> = observer(function ({ successText }) {
  const { orderStore } = useContext(RootStoreContext)
  const { orderPrinting: order, saleEntryPrinting: saleEntry } = orderStore
  const onlineOrder = get(saleEntry, 'onlineOrder', {})

  useEffect(() => {
    if (order && saleEntry) {
      setTimeout(() => {
        const content: IHTMLElement = document.getElementById('print-container') as IHTMLElement
        const iframe: IHTMLElement = document.getElementById('ifmcontentstoprint') as IHTMLElement
        const pri = iframe.contentWindow

        pri.document.open()
        pri.document.write(`
        <html>
          <head>
            <style>
            @media print {
              @page {
                margin: 0;
              }

              body {
                margin: 0.25cm;
                color: black !important;
                font-size: 10px;
                font-family: Arial, Helvetica, sans-serif;
              }

              .header {
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              .header h1 {
                margin: 0 0 10px 0;
                font-size: 10px;
              }

              .header h2 {
                margin: 0 0 5px 0;
                text-align: center;
                font-size: 10px;
              }

              

              .information {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 5px;
                font-size: 10px;
              }

              .information .left, .information .right {
                max-width: 50%;
              }

              .right {
                max-width: 70%;
              }

              .highlight {
                font-size: 11px;
                text-decoration: underline;
              }

              .details {
                margin-top: 10px;
              }

              .details table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0 0.1cm;
              }

              .orderCode {
                line-height: 1.4em;
              }

              th, td {
                border-bottom: 1px solid black;
                padding-bottom: 0.1cm;
                font-size: 10px;
              }

              td.stt {
                text-align: center;
              }

              p {
                margin: 0 !important;
                margin-bottom: 10px;
              }

              td.quantity {
                text-align: center;
              }

              td.price {
                text-align: right;
              }

              td.line-total {
                text-align: right;
              }

              .total-section {
                margin-top: 10px;
              }

              .total-section > p {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #ccc;
                font-size: 10px;
                padding: 3px;
              }

              .total-section > p:last-child {
                border-bottom: none;
              }
            }
            </style>
          </head>
          <body>
            ${content.innerHTML}
          </body>
        </html>
        `)
        pri.document.close()
        pri.focus()
        pri.print()
        orderStore.setOrderAndSaleEntryPrinting(null, null)
        toast.success(successText || 'In hóa đơn thành công!')
      }, 500)
    }
  }, [order, saleEntry])

  if (!order || !saleEntry) return null

  return (
    <>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: 0, width: 0, position: 'absolute' }}
        title="print"
      />
      <Container id="print-container">
        <Segment>
          <ReviewSaleEntry
            receivedMoney={saleEntry.receivedMoney}
            referrer={order.orderPlatform}
            orderCode={order.orderCode}
            goFoodPin={order.goFoodPin}
            orderId={order._id}
            transferCode={order.transferCode}
            deposit={order.deposit}
            nowOrderCode={order.nowOrderCode}
            shippingCollect={order.shippingCollect}
            address={get(saleEntry, 'outlet.address')}
            created={saleEntry.created}
            createdAt={saleEntry.createdAt}
            serviceMode={saleEntry.serviceMode}
            saleEntryId={saleEntry.saleEntryId}
            voucherToken={saleEntry.voucherToken}
            onlineOrderId={get(onlineOrder, 'onlineOrderId', '')}
            paymentMethod={get(onlineOrder, 'payment.method', '')}
            customerPhone={get(onlineOrder, 'orderCustomer.cellphone', '')}
            receiverCustomer={get(onlineOrder, 'receiverCustomer')}
            orderNumber={get(saleEntry, 'orderNumber')}
            outletCode={get(saleEntry, 'outlet.code', '')}
            items={order.computedItems.map((item: any) => {
              return {
                id: item.id,
                sku: item.sku,
                description: item.description,
                originalPrice: item.normalPrice,
                soldPrice: item.soldPrice,
                modifiers: item.modifiers,
                bundledProducts: item.bundledProducts,
                onSale: false,
                quantity: item.quantity,
                lineTotal: item.lineTotal,
                note: item.note,
                isCombo: item.isCombo,
                category: item.category,
                subCat: item.subCat
              }
            })}
            saleTotal={order.saleTotal}
            discount={order.discountTotal}
            cashier={`${get(saleEntry, 'cashier.code')}`}
          />
        </Segment>
      </Container>
    </>
  )
})
