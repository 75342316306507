import request from 'superagent'
import { WORK_URL } from '../config/environments'
import Bluebird from 'bluebird'
import { handleErrorResponse } from '../libs/error-handler'

export interface IVoucher {
  _id: string
  name: string
  couponCode?: string
  voucherType:
    | 'STAFF_DISCOUNT'
    | 'AMOUNT'
    | 'FREE_BREAD_OR_DRINK'
    | 'NONE'
    | 'DISCOUNT_CAKE_ORDER'
    | 'DISCOUNT_CAKE_ORDER_BIG'
    | 'DISCOUNT_CAKE_ORDER_NORMAL'
  amount: number
  quantity?: number
  withoutCard?: boolean
}

export class VoucherStore {
  searchVoucher = (couponCode: string) => {
    return Bluebird.resolve(
      request
        .get(WORK_URL + '/api/vouchers/search')
        .query({
          couponCode
        })
        .then(response => response.body)
        .catch(err => {
          handleErrorResponse(err)
        })
    )
  }
}

export const voucherStore = new VoucherStore()
