import React, { Component, ReactNode, ChangeEvent } from 'react'
import { get, findLast, isEqual, find, some, forEach, cloneDeep, reduce } from 'lodash'
import { Modal, Card, Grid, Header, List, Icon } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { TComboElement, OrderStore, IOrderItem } from '../../../model/order.store'
import { IProduct } from '../../../model/product.store'
import { RootStore } from '../../../model/root-store'
import { ObjectId, getObjectId } from '../../../libs/object-id'

interface ModalChooseComboProps {
  product: IProduct
  rootStore?: RootStore
  trigger: any
}

interface ModalChooseComboState {
  openModal: boolean
  comboElements: TComboElement[]
}
let comboElements: TComboElement[]

export const ModalChooseCombo = inject('rootStore')(
  observer(
    class extends Component<ModalChooseComboProps, ModalChooseComboState> {
      state = {
        openModal: false,
        comboElements: []
      }

      setComboElement(comboElements: TComboElement[]) {
        this.setState({ comboElements: comboElements })
      }

      findProducts(sku: String) {
        return find(this.props.rootStore!.productStore.products, (product: IProduct) =>
          isEqual(product.sku, sku)
        )
      }

      handleSelectItem = (comboElement: TComboElement, itemOption: { sku: string }) => () => {
        this.props.rootStore!.orderStore.selectItemOptionForCombo(comboElement, itemOption)
        comboElements = get(this.props.rootStore!.orderStore, 'product.comboElements', [])
        this.setState({ comboElements: comboElements })
      }
      handleOpenModal = () => {
        this.setState({ openModal: true })
        this.setState({ comboElements: comboElements })
      }
      handleCloseModal = () => {
        this.setState({ openModal: false })
      }
      handleChooseCombo = () => {
        if (
          some(this.props.rootStore!.orderStore.product!.comboElements, comboElement => {
            return !get(comboElement, 'selectedItem.sku')
          })
        ) {
          return
        }
        let normalPrice = this.props.rootStore!.orderStore.product!.normalPrice
        const setNormalPrice = some(
          this.props.rootStore!.orderStore.product!.comboElements!,
          comboElement => {
            return some(comboElement.itemOptions, itemOption => {
              return itemOption.price
            })
          }
        )
        if (setNormalPrice) {
          normalPrice = reduce(
            this.props.rootStore!.orderStore.product!.comboElements,
            (result, comboElement) => {
              result +=
                get(comboElement, 'selectedItem.price', 0) * get(comboElement, 'maxQuantity', 1)
              return result
            },
            0
          )
          if (this.props.rootStore!.orderStore.product!.sku === 'V1408') {
            this.props.rootStore!.orderStore.addProductToItems({
              _id: getObjectId('62579fa53fc8f53105a806f8'),
              sku: 'V1408',
              description: 'Combo 2 bánh sinh nhật',
              normalPrice,
              category: 'combo',
              isCombo: true
            })
          }
        } else {
          this.props.rootStore!.orderStore.addProductToItems(
            this.props.rootStore!.orderStore.product!
          )
        }

        const comboProduct = findLast(this.props.rootStore!.orderStore.items, (item: IOrderItem) =>
          isEqual(item.sku, this.props.rootStore!.orderStore.product!.sku)
        )

        this.props.rootStore!.orderStore.product!.comboElements!.forEach(comboElement => {
          const matchProduct = cloneDeep(this.findProducts(comboElement.selectedItem!.sku))

          if (matchProduct) {
            matchProduct.comboId = comboProduct!.id
            matchProduct.comboName = comboProduct!.description
            matchProduct.quantity = get(comboElement, 'maxQuantity', 1)
            this.props.rootStore!.orderStore.addProductToItems(matchProduct)
          }
        })

        if (setNormalPrice) {
          if (this.props.rootStore!.orderStore.product!.sku !== 'V1408') {
            this.props.rootStore!.orderStore.removeItem({
              id: get(this.props.rootStore!.orderStore.product!, 'id', ''),
              quantity: get(this.props.rootStore!.orderStore.product!, 'quantity', 0),
              normalPrice: get(this.props.rootStore!.orderStore.product!, 'quantity', 0),
              sku: get(this.props.rootStore!.orderStore.product!, 'sku', ''),
              description: get(this.props.rootStore!.orderStore.product!, 'description', ''),
              category: get(this.props.rootStore!.orderStore.product!, 'category', 'combo'),
              noLoyalty: true,
              modifiers: []
            })
          }
        } else {
          this.props.rootStore!.orderStore.removeProduct()
        }

        this.setState({ openModal: false })
      }
      render() {
        this.props.rootStore!.orderStore.selectProduct(this.props.product)
        comboElements = get(this.props.rootStore!.orderStore, 'product.comboElements', [])
        forEach(comboElements, comboElement => {
          if (comboElement.itemOptions.length === 1) {
            const itemOption = comboElement.itemOptions[0]
            this.props.rootStore!.orderStore.selectItemOptionForCombo(comboElement, itemOption)
          }
        })

        return (
          <Modal
            trigger={this.props.trigger}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>Combo</Modal.Header>
            <Modal.Content>
              {' '}
              <Grid columns={2} divided>
                {comboElements.length
                  ? comboElements.map((comboElement: TComboElement) => (
                      <Grid.Column key={comboElement.name}>
                        <Header>{comboElement.name}</Header>
                        <List key={comboElement.name}></List>
                        {comboElement.itemOptions.map(itemOption => (
                          <List.Item
                            key={itemOption.sku}
                            onClick={this.handleSelectItem(comboElement, itemOption)}
                          >
                            <Icon
                              color={
                                get(comboElement, 'selectedItem.sku') === itemOption.sku
                                  ? 'green'
                                  : 'grey'
                              }
                              name={
                                get(comboElement, 'selectedItem.sku') === itemOption.sku
                                  ? 'checkmark box'
                                  : 'square outline'
                              }
                            />
                            {itemOption.sku} - {itemOption.description}
                          </List.Item>
                        ))}
                      </Grid.Column>
                    ))
                  : ''}
              </Grid>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 'done',
                  content: 'Xong',
                  onClick: this.handleChooseCombo
                }
              ]}
            />
          </Modal>
        )
      }
    }
  )
)
