import React from 'react'
import { Button } from 'semantic-ui-react'

interface ButtonConfirmProps {
  onClick: Function
  [key: string]: any
}

interface ButtonConfirmState {
  isConfirm: boolean
}

class ButtonConfirm extends React.PureComponent<ButtonConfirmProps, ButtonConfirmState> {
  state = {
    isConfirm: false
  }

  handleClick = () => {
    if (!this.state.isConfirm) {
      this.setState({ isConfirm: true })
    } else {
      this.props.onClick()
      this.setState({ isConfirm: false })
    }
  }

  render() {
    const { onClick, color, icon, content, ...rest } = this.props
    return (
      <Button
        {...rest}
        color={this.state.isConfirm ? 'yellow' : color}
        icon={this.state.isConfirm ? 'checkmark' : icon}
        content={this.state.isConfirm ? 'Xác nhận' : content}
        onClick={this.handleClick}
      />
    )
  }
}

export default ButtonConfirm
