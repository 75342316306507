import React, { ChangeEvent } from 'react'
import { Container, Form } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { AuthentiationStore } from '../../model/authentication.store'
import { handleErrorResponse } from '../../libs/error-handler'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { RootStore } from '../../model/root-store'

interface LoginViewProps extends RouteComponentProps {
  rootStore?: RootStore
}

export const LoginView = inject('rootStore')(
  withRouter<LoginViewProps>(
    observer(
      class extends React.Component<LoginViewProps> {
        state: { username: string; password: string } = {
          username: '',
          password: ''
        }

        handleClickLogin = async () => {
          try {
            await this.props.rootStore!.authenticationStore!.login(
              this.state.username,
              this.state.password
            )
            toast.success('Đăng nhập thành công')
            this.props.history.push('/orders/create')
          } catch (e) {
            handleErrorResponse(e)
          }
        }

        render() {
          return (
            <Container>
              <Form>
                <Form.Field>
                  <Form.Input
                    label="Tên đăng nhập"
                    type="username"
                    id="username"
                    name="username"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({ username: e.target.value })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    type="password"
                    id="password"
                    name="password"
                    label="Mật khẩu"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({ password: e.target.value })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Button onClick={this.handleClickLogin} content="Đăng nhập" />
                </Form.Field>
              </Form>
            </Container>
          )
        }
      }
    )
  )
)
