import React, { Fragment, useContext } from 'react'
import { RootStoreContext } from '../../../model/root-store'
import tableStyles from '../../../scss/table.module.scss'
import { CurrentOrderItem } from './CurrentOrderItem'
import { FormattedCurrency } from '../../../components/FormattedCurrency'
import styles from './CurrentOrder.module.scss'
import { observer } from 'mobx-react-lite'
import { Transition } from 'semantic-ui-react'

export const CurrentOrderItems = observer(function CurrentOrderItems() {
  const rootStore = useContext(RootStoreContext)
  const orderStore = rootStore.orderStore

  return (
    <Fragment>
      <table
        className={tableStyles['table']}
        style={{
          width: '100%'
        }}
      >
        <Transition.Group animation="fade up" duration={200} as="tbody">
          {orderStore.computedItems.map(item => (
            <CurrentOrderItem order={orderStore} key={item.id} item={item} />
          ))}
          {orderStore.discounts && orderStore.discounts.length > 0 && (
            <Fragment>
              <tr className={`${tableStyles['top-bordered']} ${tableStyles['header-row']}`}>
                <td colSpan={2}>Giảm giá</td>
              </tr>
              {orderStore!.discounts.map((discount, index) => (
                <tr key={index}>
                  <td className={styles['discount-description']}>{discount.description}</td>
                  <td className={tableStyles['quantity-cell']}>
                    -<FormattedCurrency value={discount.amount} />
                  </td>
                </tr>
              ))}
            </Fragment>
          )}
          {(orderStore.orderType === 'SHIP' ||
            orderStore.shippingCollect > 0 ||
            orderStore.shippingPay > 0) && (
            <Fragment>
              <tr className={`${tableStyles['top-bordered']} ${tableStyles['header-row']}`}>
                <td className={styles['discount-description']}>Phí ship thu KH</td>
                <td className={tableStyles['quantity-cell']}>
                  <FormattedCurrency value={orderStore.shippingCollect} />
                </td>
              </tr>
              <tr className={`${tableStyles['top-bordered']}`}>
                <td className={styles['discount-description']}>Phí ship thực tế</td>
                <td className={tableStyles['quantity-cell']}>
                  -<FormattedCurrency value={orderStore.shippingPay} />
                </td>
              </tr>
            </Fragment>
          )}
          {orderStore.commissionAmount > 0 && (
            <Fragment>
              <tr className={`${tableStyles['top-bordered']}`}>
                <td className={styles['discount-description']}>Chiết khấu đối tác</td>
                <td className={tableStyles['quantity-cell']}>
                  -<FormattedCurrency value={orderStore.commissionAmount} />
                </td>
              </tr>
            </Fragment>
          )}
          {orderStore.deposit > 0 && (
            <Fragment>
              <tr className={`${tableStyles['top-bordered']}`}>
                <td className={styles['discount-description']}>Trà trước</td>
                <td className={tableStyles['quantity-cell']}>
                  -<FormattedCurrency value={orderStore.deposit} />
                </td>
              </tr>
            </Fragment>
          )}
          {!orderStore.computedItems.length && (
            <tr>
              <td colSpan={2}>Chọn các món ở bên trái...</td>
            </tr>
          )}
        </Transition.Group>
        {(orderStore.orderPlatform !== 'SAVOR_SHOP' || orderStore.orderType === 'SHIP') && (
          <tfoot>
            <tr className={`${tableStyles['top-double-bordered']}`}>
              <td>Phải thu</td>
              <td className={tableStyles['quantity-cell']}>
                <FormattedCurrency value={orderStore.computedReceiveAmount} />
              </td>
            </tr>
            {(orderStore.orderType === 'SHIP' || orderStore.shippingCollect > 0) && (
              <tr>
                <td>Shipper thu của khách</td>
                <td className={tableStyles['quantity-cell']}>
                  <FormattedCurrency
                    value={orderStore.saleTotal - orderStore.deposit + orderStore.shippingCollect}
                  />
                </td>
              </tr>
            )}
          </tfoot>
        )}
      </table>
    </Fragment>
  )
})
