import { startOfDay } from 'date-fns'
import { get, filter } from 'lodash'
import { inject, observer } from 'mobx-react'
import { userInfo } from 'os'
import React, { ChangeEvent, Component, Fragment } from 'react'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { Form, Modal } from 'semantic-ui-react'
import { handleAgentErrorResponse } from '../libs/error-handler'
import { TShift } from '../model/current-shift.store'
import { IOutlet } from '../model/outlet-list.store'
import { rootStore, RootStore } from '../model/root-store'
import { InputCurrency } from './InputCurrency'
import { InputOutlet } from './InputOutlet'

interface ModalCurrentShiftProps {
  rootStore?: RootStore
  [key: string]: any
}

interface ModalCurrentShiftState {
  date: Date | null
  outlet: string | IOutlet | null
  shift: TShift | null
  createNewShiftRecord: boolean
  openModal: boolean

  initialPettyCash: number
  staffs: {
    _id: string
    code?: string
    displayName: string
    came?: boolean | undefined
  }[]

  currentLocation?: {
    lat?: string
    lng?: string
  }
}

export const ModalCurrentShift = inject('rootStore')(
  observer(
    class extends Component<ModalCurrentShiftProps, ModalCurrentShiftState> {
      state: ModalCurrentShiftState = {
        outlet: this.props.rootStore!.authenticationStore.user!.outlet,
        date: startOfDay(new Date()),
        shift: 'S',
        createNewShiftRecord: false,
        openModal: false,
        initialPettyCash: 0,
        staffs: []
      }

      convertToShift(str: string): TShift | null {
        if (str === 'S' || str === 'C' || str === 'T' || str === 'T_1') return str
        return null
      }

      handleChangeOutlet = (outlet: IOutlet) => {
        this.setState({ outlet })
      }

      handleChangeDate = (date: Date) => {
        this.setState({ date })
      }

      handleChangeShift = (e: ChangeEvent<HTMLSelectElement>) => {
        this.setState({ shift: this.convertToShift(e.target.value) })
      }

      handleCheckShift = async () => {
        if (!this.state.outlet) {
          toast.error('Chưa chọn cơ sở')
          return
        }
        if (!this.state.date) {
          toast.error('Chưa chọn ngày')
          return
        }
        if (!this.state.shift) {
          toast.error('Chưa chọn ca trực')
          return
        }
        try {
          const data = await this.props.rootStore!.currentShiftStore.checkCurrentShift({
            outlet: this.state.outlet,
            date: this.state.date,
            shift: this.state.shift,
            currentLocation: this.state.currentLocation
          })
          if (data.exists) {
            this.handleCloseModal()
          } else {
            toast.info('Hãy điền thêm thông tin về ca trực!')
            this.setState({
              createNewShiftRecord: true,
              staffs: data.shiftEntry.staffs
            })
          }
        } catch (e) {
          handleAgentErrorResponse(e)
        }
      }

      handleCreateShift = async () => {
        const { outlet, date, shift } = this.state

        if (!outlet) {
          toast.error('Chưa chọn cơ sở')
          return
        }
        if (!date) {
          toast.error('Chưa chọn ngày')
          return
        }
        if (!shift) {
          toast.error('Chưa chọn ca trực')
          return
        }
        if (!this.state.initialPettyCash) {
          toast.error('Thiếu tiền lẻ đầu ca trực')
          return
        }

        const user = rootStore.authenticationStore.user

        try {
          await this.props.rootStore!.currentShiftStore.createShiftRecord({
            outlet,
            date,
            shift,
            initialPettyCash: this.state.initialPettyCash,
            staffs: this.state.staffs,
            employees: filter(this.state.staffs, { code: get(user, 'code') }).map(staff => {
              return {
                staff: staff._id,
                displayName: staff.displayName,
                code: staff.code,
                came: new Date(),
                lat: get(this.state, 'currentLocation.lat'),
                lng: get(this.state, 'currentLocation.lng')
              }
            })
          })

          this.handleCloseModal()
        } catch (e) {
          handleAgentErrorResponse(e)
        }
      }

      handleOpenModal = () => {
        this.setState({ openModal: true })
      }
      handleCloseModal = () => {
        this.setState({ openModal: false })
      }

      componentDidMount() {
        const user = rootStore.authenticationStore.user

        if (user) {
          const locationPromise = new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (pos) {
              const lat = pos.coords.latitude
              const lng = pos.coords.longitude
              resolve({ lat, lng })
            })
          })
          locationPromise.then(({ lat, lng }: any) => {
            this.setState({
              currentLocation: { lat, lng }
            })
          })
        }
      }

      render() {
        return (
          <Modal
            trigger={this.props.children}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Content>
              <Form>
                <InputOutlet
                  onSelect={this.handleChangeOutlet}
                  initialValue={this.props.rootStore!.authenticationStore.user!.outlet}
                  disabled={this.state.createNewShiftRecord}
                />
                <Form.Field required>
                  <label>Ngày</label>
                  <ReactDatePicker
                    selected={this.state.date}
                    onChange={this.handleChangeDate}
                    disabled={this.state.createNewShiftRecord}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Ca trực</label>
                  <select
                    className="ui dropdown"
                    onChange={this.handleChangeShift}
                    disabled={this.state.createNewShiftRecord}
                  >
                    <option value="S">Sáng</option>
                    <option value="C">Chiều</option>
                    <option value="T">Tối</option>
                    <option value="T_1">Tối 21h - 24h</option>
                  </select>
                </Form.Field>
                {this.state.createNewShiftRecord && (
                  <Fragment>
                    <Form.Field required>
                      <label>Tiền lẻ đầu ca</label>
                      <InputCurrency
                        value={this.state.initialPettyCash}
                        onChange={(e: any) => {
                          this.setState({ initialPettyCash: parseInt(e.target.rawValue) })
                        }}
                      />
                    </Form.Field>
                  </Fragment>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 'check',
                  content: 'Kiểm tra ca trực',
                  onClick: this.handleCheckShift
                },
                {
                  key: 'create',
                  content: 'Đăng nhập ca trực',
                  onClick: this.handleCreateShift
                }
              ].filter(action => {
                if (this.state.createNewShiftRecord) return action.key === 'create'
                else return action.key === 'check'
              })}
            />
          </Modal>
        )
      }
    }
  )
)
