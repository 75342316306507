import React, { Component } from 'react'

import { Input, Button, Header } from 'semantic-ui-react'

interface NoteChangerProps {
  onNoteChanged: any
  note: string
  label?: string
  [key: string]: any
}

export class NoteChanger extends Component<NoteChangerProps> {
  render() {
    const { onNoteChanged, note, label, ...rest } = this.props
    return (
      <div {...rest} style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
        <Header
          style={{
            marginRight: 10,
            marginBottom: 0,
            marginTop: 10
          }}
        >
          {this.props.label || 'Ghi chú: '}
        </Header>
        <Input
          type="text"
          value={this.props.note}
          onChange={(e, { value }) => this.props.onNoteChanged(value)}
          style={{ width: 400 }}
        ></Input>
      </div>
    )
  }
}
export default NoteChanger
