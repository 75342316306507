import { decorate, observable, action, computed, runInAction } from 'mobx'
import { IOutlet } from './outlet-list.store'
import request, { Response } from 'superagent'
import { WORK_URL } from '../config/environments'
import store from 'store'
import { RootStore } from './root-store'
import { isAfter } from 'date-fns'

interface IUser {
  _id: string
  fullName: string
  cellphone: string
  roles: string[]
  outlet: IOutlet | string
  code: string
}

export class AuthentiationStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  user: IUser | null = null
  token: string | null = null
  expired: Date | null = null
  get isAuthenticated(): boolean {
    return !!this.user && !!this.token
  }

  get roles(): string[] {
    return this.user ? this.user.roles : []
  }

  get canUseCurrentOutlet() {
    return (
      this.roles.includes('admin') ||
      this.roles.includes('marketing') ||
      this.roles.includes('ship')
    )
  }

  get isAdmin() {
    return this.roles.includes('admin')
  }

  loadLocalData() {
    const user = store.get('user', null)
    const token = store.get('token', null)
    const expired = store.get('expired', null)

    if (expired && isAfter(expired, new Date())) {
      this.setUser(user)
      this.setToken(token)
      this.setExpired(expired)
    } else {
      store.remove('user')
      store.remove('token')
      store.remove('expired')
    }
  }
  setUser(user: IUser) {
    this.user = user
    store.set('user', user)
  }
  setToken(token: string) {
    this.token = token
    store.set('token', token)
  }
  setExpired(expired: Date) {
    this.expired = expired
    store.set('expired', expired)
  }
  clearData() {
    this.rootStore!.currentShiftStore.clearData()
    this.user = null
    this.token = null
    this.expired = null
    store.remove('user')
    store.remove('token')
    store.remove('expired')
  }
  async login(username: string, password: string) {
    const { user, token, expired } = (await request
      .post(WORK_URL + '/api_v2/login')
      .send({
        username,
        password
      })
      .then(
        (response: Response) =>
          response.body as {
            user: IUser
            token: string
            expired: Date
          }
      )) || { user: null, token: null, expired: null }
    this.setUser(user)
    this.setToken(token)
    this.setExpired(expired)
  }
}

decorate(AuthentiationStore, {
  user: observable,
  roles: computed,
  canUseCurrentOutlet: computed,
  isAdmin: computed,
  token: observable,
  expired: observable,
  isAuthenticated: computed,
  setUser: action,
  setToken: action,
  setExpired: action,
  clearData: action
})
