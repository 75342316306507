import { format, getTime } from 'date-fns'
import { forEach, get } from 'lodash'
import clone from 'lodash/clone'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import sumBy from 'lodash/sumBy'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { FormattedNumber } from 'react-intl'

const itemName = (item: any, index: number, items: any) => {
  return (
    <span key={item.id || item.sku}>
      {item.name || item.description}
      {item.quantity > 1 && ` x ${item.quantity}`}
      {index < items.length - 1 && ', '}
    </span>
  )
}
interface ReviewSaleEntryProps {
  saleTotal: number
  deposit: number
  shippingCollect: number
  discount: number
  referrer: string
  saleEntryId: string
  voucherToken: string
  orderId: string
  created: any
  items: any
  createdAt: any
  address: string
  receivedMoney: number | null
  serviceMode: string
  cashier: string
  orderNumber: number
  orderCode: String
  goFoodPin: String
  transferCode: String
  nowOrderCode: String
  customerPhone: any
  receiverCustomer: any
  onlineOrderId: any
  paymentMethod: any
  outletCode: any
  onEditItem?: (item: any) => void
}

class ReviewSaleEntry extends React.Component<ReviewSaleEntryProps> {
  state = {
    openDrinkModal: false,
    currentEditingItem: null
  }

  getCategoryFromDescription = (description: String, category: String) => {
    if (
      description.match(
        /^(Trà|Hồng trà|Sữa tươi|Tiger Sugar|Xanh sữa|Lục trà|Kem (cháy|trứng|cheese) (trà )?sữa|Nước ép|Cà phê|Sữa chua|Socola nóng)/gim
      )
    ) {
      return 'Nước'
    } else if (description.match(/^(Tokbokki)/gim)) {
      return 'Tokbokki'
    } else if (description.match(/^(Sandwich|Combo \d* Sandwich)/gim)) {
      return 'Sandwich'
    } else if (description.match(/^(Xôi|Combo xôi)/gim)) {
      return 'Xôi'
    } else if (description.match(/^(Bánh mì|Combo Bánh mì)/gim)) {
      return 'Bánh mì'
    } else if (description.match(/^(Nem chua|Phô mai que)/gim)) {
      return 'Nem'
    } else if (description.match(/^(Khoai tây|Khoai lang)/gim)) {
      return 'Khoai'
    } else if (description.match(/^(Gà rán)/gim)) {
      return 'Gà rán'
    } else return 'Khác'
  }

  updateQuantity = (item: any, quantity: number) => {
    const newItem = clone(item)

    newItem.quantity = quantity

    this.props.onEditItem && this.props.onEditItem(newItem)
  }

  shouldRenderQRCode = (voucherToken: string) => {
    return !!voucherToken
  }

  getContentQRCode = (voucherToken: string): string => {
    return `https://savor.vn/get-voucher/?voucherToken=${voucherToken}`
  }

  render() {
    const {
      items,
      saleTotal,
      deposit,
      shippingCollect,
      discount,
      referrer,
      orderId,
      orderCode,
      created,
      address,
      receivedMoney,
      serviceMode,
      voucherToken,
      cashier,
      orderNumber,
      nowOrderCode,
      goFoodPin,
      createdAt,
      customerPhone,
      receiverCustomer,
      onlineOrderId,
      paymentMethod,
      outletCode
    } = this.props

    const checkDrink = (item: any, quantity: Number, id: any) => {
      if (
        ['V0327', 'V0716', 'V0001', 'V0398', 'V0399', 'V0400', 'V0401', 'V0458'].includes(
          get(item, 'sku', 'W')
        ) ||
        ['drink-addon'].includes(item.modifierSetCode)
      ) {
        return {
          id: id + Math.fround(Math.random() * 10),
          description: get(item, 'description'),
          quantity: get(item, 'quantity', quantity),
          lineTotal: 0,
          originalPrice: 0,
          soldPrice: 0,
          index: 0
        }
      }
      return null
    }
    let index = 1

    const reduceItems = reduce(
      items,
      (result: any, item: any) => {
        if (!item.isCombo) {
          item.index = index
          index += 1
        }
        result.push(item)
        // Tặng kèm BSN
        if (item.subCat === 'sinh-nhat') {
          result.push({
            description: 'Bộ dao dĩa đĩa nhựa bánh sinh nhật (đi kèm)',
            quantity: item.quantity,
            index,
            lineTotal: 0,
            soldPrice: 0
          })
          index += 1
        }
        // Check addon
        forEach(item.modifiers, itemM => {
          const drink = checkDrink(itemM, item.quantity, item.id)
          if (drink) {
            result.push(drink)
          }
        })
        // Check bundleProduct
        forEach(item.bundledProducts, itemM => {
          const drink = checkDrink(itemM, item.quantity, item.id)
          if (drink) {
            result.push(drink)
          }
        })
        return result
      },
      []
    )
    const countItemByCategory = reduce(
      items,
      (result: any, item: any) => {
        if (item.isCombo) {
          return result
        }

        const category = this.getCategoryFromDescription(item.description, item.category)
        let quantity = item.quantity
        if (item.description.match(/Combo \d* Sandwich/)) {
          quantity = item.quantity * 2
        }
        result[category] += quantity

        // Các loại combo cho nước vào bundledProducts thì thêm nước
        if (item.bundledProducts && item.bundledProducts.length) {
          item.bundledProducts.forEach((bundledProduct: any) => {
            if (['V0327', 'V0001', 'V0716'].includes(bundledProduct.sku)) {
              result['Nước'] += quantity
            }
          })
        }

        // Combo tặng nước 10k => thêm nước
        if (
          some(item.modifiers, modifier => {
            return modifier && ['combo-drink', 'drink-addon'].includes(modifier.modifierSetCode)
          })
        ) {
          result['Nước'] += quantity
        }

        return result
      },
      {
        Nước: 0,
        Tokbokki: 0,
        Sandwich: 0,
        Xôi: 0,
        'Bánh mì': 0,
        'Gà rán': 0,
        Nem: 0,
        Khoai: 0,
        'Bánh ngọt': 0,
        Khác: 0
      }
    )

    const categories = map(countItemByCategory, (count, category) => {
      return {
        category,
        count
      }
    }).filter(cat => cat.count > 0)

    const itemDetail = categories.map(d => `${d.category} (${d.count})`).join(', ')

    const numItems = sumBy(categories, 'count')

    const isKitchen = outletCode.includes('B')

    const totalCollect = (shippingCollect || 0) + saleTotal - (deposit || 0)
    return (
      <div className="sale-entry-view">
        <div className="header">
          <h2 style={{ fontWeight: 'normal' }}>
            {!isKitchen && (
              <span>
                Địa chỉ Savor: {address} <br />
              </span>
            )}
            Khiếu nại xin gọi 091.708.6650 hoặc inbox fb.com/savor.vn
          </h2>
        </div>
        <div className="information">
          <div className="left">
            <p style={{ margin: '0' }}>
              Ngày: {format(created, 'DD/MM/YY')}, Giờ: {format(created, 'HH:mm')}
            </p>
            {/* <p style={{ margin: '0', marginBottom: '10px' }}>Giờ: {format(created, 'HH:mm')}</p> */}
            {!isKitchen && (
              <span className="order-number">
                Số HD: <b style={{ fontSize: '14px' }}> {orderNumber}</b>
              </span>
            )}
          </div>
          <div className="right">
            <span className="highlight">{referrer}</span>
            {isKitchen && (
              <strong className="orderCode">
                {' '}
                <br />
                Mã đơn: {onlineOrderId}
                {'. '}
              </strong>
            )}
            {referrer !== 'SAVOR_SHOP' && referrer !== 'NOW' && (
              <strong className="orderCode">
                {' '}
                <br />
                Mã đơn: {orderCode}
                {'. '}
              </strong>
            )}
            {referrer === 'NOW' && (
              <strong className="orderCode">
                <br /> Mã đơn: {nowOrderCode}
                {'. '}
              </strong>
            )}
            {referrer === 'GO_FOOD' && <strong className="orderCode">PIN: {goFoodPin}</strong>}
            <br />
            Thu ngân: {cashier}
          </div>
        </div>
        <div className="details">
          <table style={{ fontSize: '10px' }}>
            <colgroup>
              <col width="8%" />
              <col width="72%" />
              <col width="5%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reduceItems &&
                reduceItems.map((item: any, key: number) => (
                  <tr key={item.id} className="itemRow">
                    <td className="stt">
                      <span>{item.index}</span>
                    </td>

                    <td className="description">
                      <b>
                        {item.description}
                        {item.size ? (item.size === 'small' ? ' - nhỏ' : ' - to') : ''}
                      </b>

                      <div>
                        {!!item.veggies && item.veggies.length > 0 && (
                          <p>{map(orderBy(item.veggies, 'order'), itemName)}</p>
                        )}

                        {!!item.addons && item.addons.length > 0 && (
                          <p>{map(orderBy(item.addons, 'order'), itemName)}</p>
                        )}

                        {!!item.stickyRiceItems && item.stickyRiceItems.length > 0 && (
                          <p>{map(item.stickyRiceItems, itemName)}</p>
                        )}

                        {!!item.modifiers && item.modifiers.length > 0 && (
                          <p>{map(item.modifiers, itemName)}</p>
                        )}

                        {item.isBreakfastCombo && (
                          <div>
                            <p>
                              <strong>Nước trong combo:</strong> {item.comboDrink.sku}{' '}
                              {item.comboDrink.name}
                            </p>

                            {item.comboDessert && (
                              <p>
                                <strong>Tráng miệng trong combo:</strong> {item.comboDessert.sku}{' '}
                                {item.comboDessert.name}
                              </p>
                            )}
                          </div>
                        )}
                        {item.makeItAMeal && (
                          <div>
                            <p>
                              <strong>Combo full bữa</strong>
                            </p>

                            <p>
                              {item.miamDrink.sku} {item.miamDrink.name}
                            </p>

                            <p>
                              {item.miamDessert.sku} {item.miamDessert.description}
                            </p>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="quantity">
                      <span>{item.quantity}</span>
                    </td>

                    <td className="line-total">
                      <span>
                        {item.lineTotal !== item.quantity * item.originalPrice &&
                        this.props.referrer === 'SAVOR_SHOP' ? (
                          <s style={{ display: 'block', fontSize: '9px' }}>
                            <FormattedNumber value={item.quantity * item.originalPrice} />
                          </s>
                        ) : null}
                        <FormattedNumber value={item.lineTotal} />
                      </span>
                    </td>
                    <td className="note">
                      <span>{item.note}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {isKitchen && (
          <div>
            <span style={{ fontSize: '16px', margin: '5px 0', display: 'inline-block' }}>
              Địa chỉ: {get(receiverCustomer, 'name', '')}, {get(receiverCustomer, 'address', '')}
            </span>{' '}
            <br />
            <span>SĐT: {customerPhone}</span> <br />
            <span>
              Hình thức thanh toán:{' '}
              {paymentMethod.toUpperCase() === 'TRANSFER' ? (
                <span>CHUYỂN KHOẢN</span>
              ) : (
                <span>{paymentMethod.toUpperCase()}</span>
              )}
            </span>
          </div>
        )}
        <div className="total-section">
          <p>
            <b>
              Tổng <FormattedNumber value={numItems} /> món: {itemDetail}
            </b>
          </p>
          <React.Fragment>
            <p>
              <span>Thành tiền:</span>
              <span>
                {discount > 0 ? (
                  <>
                    <FormattedNumber value={totalCollect + discount} />đ
                  </>
                ) : (
                  <>
                    <FormattedNumber value={totalCollect} />đ
                  </>
                )}
              </span>
            </p>
            {discount > 0 && (
              <p>
                <span>Giảm giá:</span>
                <span>
                  <>
                    -<FormattedNumber value={discount} />đ
                  </>
                </span>
              </p>
            )}
          </React.Fragment>
          {shippingCollect ? (
            <p>
              <span>Phí ship: </span>
              <span>
                <FormattedNumber value={shippingCollect} />đ
              </span>
            </p>
          ) : null}
          <p>
            <b>Tổng cộng:</b>
            <b>
              <FormattedNumber value={totalCollect} />đ
            </b>
          </p>
          {receivedMoney ? (
            <>
              <p>
                <span>Tiền khách đưa: </span>
                <span>
                  <FormattedNumber value={receivedMoney} />đ
                </span>
                <span>Tiền trả lại: </span>
                <span>
                  <FormattedNumber value={receivedMoney - totalCollect} />đ
                </span>
              </p>
            </>
          ) : null}
        </div>
        <div
          className="header"
          style={{
            width: '100%'
          }}
        >
          {this.shouldRenderQRCode(voucherToken) && (
            <div
              style={{
                borderTop: '1px solid black',
                marginTop: 5,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: 10
              }}
            >
              <div
                style={{
                  maxWidth: '200px'
                }}
              >
                <h3>KHUYẾN MÃI</h3>
                <p>
                  Quét mã QR để nhận voucher <strong>30.000đ - 50.000đ</strong>
                  <br />
                  <i>Bạn lưu ý quét mã trong vòng 24h nhé.</i>
                </p>
              </div>
              <QRCodeSVG value={this.getContentQRCode(voucherToken)} size={150} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ReviewSaleEntry
