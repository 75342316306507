import React from 'react'
import { Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { inject, observer } from 'mobx-react'
import { RootStore } from '../../model/root-store'
import { handleAgentErrorResponse } from '../../libs/error-handler'
import { OrderStore } from '../../model/order.store'

interface DoneOrderbuttonProps {
  order: OrderStore
  rootStore?: RootStore
  [key: string]: any
}

interface DoneOrderbuttonState {
  loading: boolean
}

export const DoneOrderbutton = inject('rootStore')(
  observer(
    class extends React.Component<DoneOrderbuttonProps, DoneOrderbuttonState> {
      state = {
        loading: false
      }

      handleClickDoneOrder = async () => {
        this.setState({ loading: true })
        try {
          await this.props.rootStore!.listOrderStore.doneOrder(this.props.order)
          toast.success('OK')
        } catch (e) {
          handleAgentErrorResponse(e)
          this.setState({ loading: false })
        }
      }

      render() {
        const { order, rootStore, ...rest } = this.props
        return (
          <Button
            positive
            disabled={this.state.loading}
            onClick={this.handleClickDoneOrder}
            {...rest}
          >
            Done
          </Button>
        )
      }
    }
  )
)
