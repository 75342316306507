import React, { Fragment, useContext, useEffect } from 'react'

import { Sidebar, Menu, Icon } from 'semantic-ui-react'
import { ModalCurrentShift } from '../components/ModalCurrentShift'
import { RootStoreContext } from '../model/root-store'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { MainView } from './MainView'
import { observer } from 'mobx-react-lite'

const DriverStatus = React.memo(function({ status }: { status: string }) {
  if (status === 'connected') return <span style={{ color: 'green' }}>Connected</span>
  if (status === 'disconnected') return <span style={{ color: 'red' }}>Disconnected</span>
  return <span>Loading</span>
})

const Pusher = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const uiStore = rootStore.uiStore
  const visible = uiStore.openSidebar
  return (
    <Sidebar.Pusher
      dimmed={visible}
      onClick={() => {
        if (uiStore.openSidebar) {
          uiStore.setOpenSidebar(false)
        }
      }}
    >
      <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'block' }}>
        <MainView />
      </div>
    </Sidebar.Pusher>
  )
})

const MySidebar = withRouter(
  observer(({ location }: RouteComponentProps) => {
    const rootStore = useContext(RootStoreContext)
    const authenticationStore = rootStore.authenticationStore
    const currentShiftStore = rootStore.currentShiftStore
    const deviceStore = rootStore.deviceStore
    const pathname = location.pathname
    const uiStore = rootStore.uiStore

    useEffect(() => {
      uiStore.setOpenSidebar(false)
    }, [location.pathname])

    return (
      <Sidebar
        inverted
        as={Menu}
        animation="overlay"
        duration="0"
        icon="labeled"
        vertical
        visible={uiStore.openSidebar}
        width="thin"
      >
        {authenticationStore!.isAuthenticated && (
          <Fragment>
            {currentShiftStore.currentShift ? (
              <Menu.Item header>
                <Menu.Header>Ca trực</Menu.Header>
                <Menu.Menu>
                  <Menu.Item>{currentShiftStore.currentShift.shiftRecordId}</Menu.Item>
                </Menu.Menu>
              </Menu.Item>
            ) : (
              <ModalCurrentShift>
                <Menu.Item>Đăng nhập ca trực</Menu.Item>
              </ModalCurrentShift>
            )}
            <Menu.Item
              as={Link}
              to="/orders/create?category=bread"
              active={pathname === '/orders/create'}
            >
              <Icon name="pencil alternate" />
              Create Order
            </Menu.Item>
            {/* <Menu.Item as={Link} to="/orders" active={pathname === '/orders'}>
              <Icon name="sticky note" />
              Orders
            </Menu.Item> */}
            {currentShiftStore.loggedInShift && (
              <Fragment>
                <Menu.Item as={Link} to="/sale-entries" active={pathname === '/sale-entries'}>
                  <Icon name="cloud" />
                  Sale Entries
                </Menu.Item>
                <Menu.Item as={Link} to="/update-stock" active={pathname === '/update-stock'}>
                  <Icon name="nintendo switch" />
                  Update Stock Status
                </Menu.Item>
                <Menu.Item as={Link} to="/food-prep">
                  <Icon name="retweet" />
                  Quy đổi
                </Menu.Item>
                <Menu.Item>
                  <Menu.Header>Status</Menu.Header>
                  <Menu.Menu>
                    <Menu.Item>
                      Label Printer: <DriverStatus status={deviceStore!.labelPrinterStatus} />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu.Item>
              </Fragment>
            )}
          </Fragment>
        )}

        {!authenticationStore!.isAuthenticated && (
          <Menu.Item as={Link} to="/login" position="right">
            Login
          </Menu.Item>
        )}
        {authenticationStore!.isAuthenticated && currentShiftStore.loggedInShift && (
          <Menu.Item
            position="right"
            onClick={() => {
              currentShiftStore.logOutShift()
            }}
          >
            Logout Shift
          </Menu.Item>
        )}
        {authenticationStore!.isAuthenticated && (
          <Menu.Item
            position="right"
            onClick={() => {
              authenticationStore!.clearData()
              window.location.reload()
            }}
          >
            Logout
          </Menu.Item>
        )}
      </Sidebar>
    )
  })
)

export const MainSidebar = function() {
  return (
    <Sidebar.Pushable as="div" style={{ marginBottom: 54 }}>
      <MySidebar />
      <Pusher />
    </Sidebar.Pushable>
  )
}
