import React from 'react'
import { sortBy, uniqBy } from 'lodash'
import { Checkbox } from 'semantic-ui-react'
import { IProduct } from '../../model/product.store'
import { StockProduct } from '../../components/ToggleProductStatusesInput'
import ReactTable from 'react-table'
import { cleanAccents } from '../../libs/clean-accents'

interface ListProductStatusesProps {
  products: IProduct[]
  onToggle: (product: StockProduct, inStock: boolean) => void
  onEvaluatePriorityProduct: (product: StockProduct) => StockProduct
}

export const ListProductStatuses: React.SFC<ListProductStatusesProps> = React.memo(
  ({ products, onToggle, onEvaluatePriorityProduct }) => {
    const columns = [
      {
        Header: 'Sku',
        id: 'sku',
        accessor: (product: StockProduct) => {
          if (product.inStock) {
            return product.sku
          }

          return <s>{product.sku}</s>
        },
        style: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center'
        },
        maxWidth: 200,
        filterMethod: (filter: any, row: any) => {
          const sku = row.sku.props ? row.sku.props.children.toLowerCase() : row.sku.toLowerCase()

          if (sku.indexOf(filter.value.toLowerCase()) >= 0) {
            return true
          }
          return false
        }
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: (product: StockProduct) => {
          if (product.inStock) {
            return product.description
          }

          return <s>{product.description}</s>
        },
        maxWidth: 300,
        style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
        filterMethod: (filter: any, row: any) => {
          const description = row.description.props
            ? row.description.props.children.toLowerCase()
            : row.description.toLowerCase()

          if (
            cleanAccents(description).includes(cleanAccents(filter.value.toLowerCase())) ||
            description.includes(filter.value.toLowerCase())
          ) {
            return true
          }
          return false
        }
      },
      {
        Header: 'Category',
        id: 'savorTag',
        accessor: (product: StockProduct) => {
          if (product.inStock) {
            return product.savorTag
          }

          return <s>{product.savorTag}</s>
        },
        maxWidth: 300,
        style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
        filterMethod: (filter: any, row: any) => {
          const savorTag = row.savorTag.props
            ? row.savorTag.props.children.toLowerCase()
            : row.savorTag.toLowerCase()

          if (
            cleanAccents(savorTag).includes(cleanAccents(filter.value.toLowerCase())) ||
            savorTag.includes(filter.value.toLowerCase())
          ) {
            return true
          }
          return false
        }
      },
      {
        Header: 'Action',
        id: 'action',
        style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
        accessor: (product: any) => {
          return (
            <Checkbox
              key={product.sku}
              toggle
              checked={product.inStock}
              onClick={() => onToggle(product, !product.inStock)}
            />
          )
        },
        filterable: false
      }
    ]
    const flattenProducts: StockProduct[] = []

    products.forEach((product: IProduct) => {
      if (product.bundledProducts && product.bundledProducts.length > 0) {
        product.bundledProducts.forEach((pro: any) => {
          flattenProducts.push(pro)
        })
      } else {
        flattenProducts.push(product as StockProduct)
      }
    })

    const finalProducts = sortBy(
      uniqBy(flattenProducts, 'sku')
        .filter(pro => pro.savorTag)
        .map((pro: StockProduct) => {
          return onEvaluatePriorityProduct(pro)
        }),
      ['priority']
    )

    return (
      <>
        <ReactTable
          data={finalProducts}
          columns={columns}
          filterable
          defaultPageSize={15}
          minRows={15}
          className="-highlight -striped"
          noDataText={!products.length && 'Không có sản phẩm'}
        />
      </>
    )
  }
)
