import React, { Component, ReactNode, ChangeEvent } from 'react'

import { Modal, Card } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { TOrderPlatform, OrderStore } from '../model/order.store'
import { RootStore } from '../model/root-store'

interface ModalChangePartnershipProps {
  children: ReactNode
  order: OrderStore
  rootStore?: RootStore
}

interface ModalChangePartnershipState {
  openModal: boolean
}

const orderPlatforms: TOrderPlatform[] = [
  'SAVOR_SHOP',
  'NOW',
  'BE_FOOD',
  'LALAMOVE',
  'GRAB_FOOD',
  'GO_FOOD'
]

export const ModalChangePartnership = inject('rootStore')(
  observer(
    class extends Component<ModalChangePartnershipProps, ModalChangePartnershipState> {
      state: ModalChangePartnershipState = {
        openModal: false
      }
      handleChangeOrderPlatform = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.order.setOrderPlatform(e.target.value as TOrderPlatform)
      }
      handleClickOrderPlatform = (orderPlatform: TOrderPlatform) => () => {
        this.props.order.setOrderPlatform(orderPlatform)
        this.props.order.clearCustomer()
        this.props.order.setVoucherCode('')
        this.handleCloseModal()
      }
      handleOpenModal = () => {
        this.setState({ openModal: true })
      }
      handleCloseModal = () => {
        this.setState({ openModal: false })
      }
      render() {
        const availableOrderPlatforms = this.props.rootStore!.orderStore.availableOrderPlatforms
        return (
          <Modal
            trigger={this.props.children}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>Đối tác</Modal.Header>
            <Modal.Content>
              <Card.Group>
                {orderPlatforms.map(orderPlatform => (
                  <Card key={orderPlatform} onClick={this.handleClickOrderPlatform(orderPlatform)}>
                    <Card.Content>
                      <Card.Header>
                        {availableOrderPlatforms.includes(orderPlatform) ? (
                          orderPlatform
                        ) : (
                          <s>{orderPlatform}</s>
                        )}
                      </Card.Header>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 'done',
                  content: 'Xong',
                  onClick: this.handleCloseModal
                }
              ]}
            />
          </Modal>
        )
      }
    }
  )
)
