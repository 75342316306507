import { IProduct } from './product.store'
import { combos } from './combo-products.data'
import { concat } from 'lodash'

export const products: IProduct[] = concat(combos, [
  {
    sku: 'V0287',
    description: 'Bánh mì thịt nướng mật ong',
    normalPrice: 35000,
    category: 'bread',
    imageLink: require('../img/thit-nuong-mat-ong.jpg'),
    defaultModifierSkus: ['V0081', 'V0087', 'V0091'],
    variants: [
      {
        group: { size: 'big' },
        sku: 'V0287',
        description: 'Cỡ to',
        normalPrice: 35000
      }
    ]
  },
  {
    sku: 'V0098',
    description: 'Bánh mì bò sốt tiêu',
    normalPrice: 35000,
    category: 'bread',
    imageLink: require('../img/bo-sot-tieu.jpg'),
    defaultModifierSkus: ['V0081', 'V0084', 'V0090'],
    variants: [
      {
        group: { size: 'big' },
        sku: 'V0098',
        description: 'Cỡ to',
        normalPrice: 35000
      }
    ]
  },
  {
    sku: 'V2861',
    description: 'Bánh mì trứng thịt',
    normalPrice: 30000,
    category: 'bread',
    defaultModifierSkus: ['V0081', 'V0085', 'V0087']
  },
  {
    sku: 'V2862',
    description: 'Bánh mì trứng thịt pate',
    normalPrice: 35000,
    category: 'bread',
    defaultModifierSkus: ['V0081', 'V0085', 'V0087']
  },
  {
    sku: 'V1036',
    description: 'Bánh mì bơ tỏi',
    normalPrice: 15000,
    category: 'bread'
  },
  {
    sku: 'V8504',
    description: 'Bánh mì pate tự chọn',
    normalPrice: 18000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V0085', 'V0091']
  },
  {
    sku: 'V8505',
    description: 'Bánh mì pate thập cẩm',
    normalPrice: 35000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V0085', 'V0091']
  },
  {
    sku: 'V8337',
    description: 'Bánh mì trứng tự chọn',
    normalPrice: 18000,
    category: 'bread',
    defaultModifierSkus: ['V0085', 'V0081']
  },
  {
    sku: 'V8338',
    description: 'Bánh mì trứng full topping',
    normalPrice: 39000,
    category: 'bread',
    defaultModifierSkus: ['V0085', 'V0081']
  },
  {
    sku: 'V8502',
    description: 'Bánh mì gà xé sốt cay',
    normalPrice: 30000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V8515', 'V0085']
  },
  {
    sku: 'V8503',
    description: 'Bánh mì gà xé',
    normalPrice: 30000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V8515', 'V0085']
  },
  {
    sku: 'V8500',
    description: 'Xôi gà xé',
    normalPrice: 35000,
    category: 'sticky-rice',
    defaultModifierSkus: ['V0384', 'V0364', 'V0353']
  },
  {
    sku: 'V8501',
    description: 'Xôi gà xé sốt nấm',
    normalPrice: 40000,
    category: 'sticky-rice',
    defaultModifierSkus: ['V0384', 'V0364', 'V0353']
  },
  {
    sku: 'V0379',
    description: 'Xôi ruốc lạp xưởng',
    normalPrice: 30000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-ruoc-lap-xuong.jpg'),
    defaultModifierSkus: ['V0353', 'V0359', 'V0350', 'V0384', 'V0364', 'V1046']
  },
  {
    sku: 'V0381',
    description: 'Xôi thịt trứng chả',
    normalPrice: 45000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-thit-trung-cha.jpg'),
    defaultModifierSkus: ['V0384']
  },

  {
    sku: 'V1023',
    description: 'Xôi thịt, trứng ốp la',
    normalPrice: 40000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-pate-xuc-xich.jpg'),
    defaultModifierSkus: ['V0353', 'V0355', 'V0364', 'V0384', 'V1046', 'V0543']
  },

  {
    sku: 'V1029',
    description: 'Xôi ruốc, chả, trứng ốp la',
    normalPrice: 25000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-pate-xuc-xich.jpg'),
    defaultModifierSkus: ['V0353', 'V0358', 'V0364', 'V0384', 'V0350', 'V1046', 'V0543']
  },
  {
    sku: 'V0380',
    description: 'Xôi trứng chả pate',
    normalPrice: 30000,
    category: 'sticky-rice'
  },
  {
    sku: 'V0385',
    description: 'Xôi tự chọn',
    normalPrice: 18000,
    category: 'sticky-rice',
    defaultModifierSkus: ['	V0353', 'V0364', 'V0384', 'V1046']
  },
  {
    sku: 'V1410',
    description: 'Khô heo vị bò 50g',
    normalPrice: 15000,
    category: 'other',
    defaultModifierSkus: []
  },
  {
    sku: 'V1409',
    description: 'Khô gà lá chanh 50g',
    normalPrice: 15000,
    category: 'other',
    defaultModifierSkus: []
  },
  {
    sku: 'V0400',
    description: 'Cà phê đen đá 250ml',
    normalPrice: 18000,
    category: 'milk-tea',
    subCat: 'savor-drink',
    defaultModifierSkus: []
  },
  {
    sku: 'V0401',
    description: 'Cà phê sữa đá 250ml',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'savor-drink',
    defaultModifierSkus: []
  },
  {
    sku: 'V1091',
    description: 'Bạc xỉu đá',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'savor-drink',
    defaultModifierSkus: []
  },
  {
    normalPrice: 18000,
    sku: 'V0704',
    description: 'Nước ép chanh leo 500ml',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0704',
        description: 'Size M',
        normalPrice: 18000
      }
    ],
    category: 'juice'
  },
  {
    sku: 'V9081',
    description: 'Nước chanh leo (M)',
    normalPrice: 15000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9082',
    description: 'Nước chanh leo (L)',
    normalPrice: 20000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9083',
    description: 'Trà xanh kim quất (M) mới',
    normalPrice: 15000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9084',
    description: 'Trà xanh kim quất (L)',
    normalPrice: 20000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9085',
    description: 'Trà xanh chanh leo (M)',
    normalPrice: 17000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9086',
    description: 'Trà xanh chanh leo (L)',
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9087',
    description: 'Trà xoài thạch đào (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9088',
    description: 'Trà xoài thạch đào (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9089',
    description: 'Trà xoài chanh leo thạch đào (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9090',
    description: 'Trà xoài chanh leo thạch đào (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    normalPrice: 25000,
    sku: 'V0710',
    category: 'milk-tea',
    subCat: 'olong-tra',
    description: 'Trà sữa bạc hà',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0710',
        description: 'Size M',
        normalPrice: 25000
      }
    ]
  },
  {
    sku: 'V2329',
    description: 'Trà Ô Long nhài sữa trân châu (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2330',
    description: 'Trà Ô Long nhài sữa trân châu (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2327',
    description: 'Trà Ô Long nhài sữa',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'olong-tra',
    variants: [
      {
        group: {
          size: 'small'
        },
        description: 'Size M',
        sku: 'V2327',
        normalPrice: 22000
      },
      {
        group: {
          size: 'big'
        },
        description: 'Size L',
        sku: 'V2328',
        normalPrice: 30000
      }
    ]
  },
  {
    sku: 'V2333',
    description: 'Trà Ô Long kim quất (M)',
    normalPrice: 18000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2334',
    description: 'Trà Ô Long kim quất (L)',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2331',
    description: 'Trà Ô Long chanh leo (M)',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2332',
    description: 'Trà Ô Long chanh leo (L)',
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V1050',
    description: 'Đường nâu trân châu sữa size M',
    normalPrice: 30000,
    category: 'milk-tea',
    subCat: 'tiger-sugar',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V1050',
        description: 'Size M',
        normalPrice: 30000
      }
    ]
  },
  {
    sku: 'V1051',
    description: 'Đường nâu trân châu sữa size L',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'tiger-sugar',
    variants: [
      {
        group: {
          size: 'big'
        },
        sku: 'V1051',
        description: 'Size L',
        normalPrice: 35000
      }
    ]
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1804',
    description: 'Trà sữa matcha (M)'
  },
  {
    sku: 'V0608',
    description: 'Trà Sữa TCDD (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    defaultModifierSkus: ['V0621', 'V0618']
  },
  {
    sku: 'V0613',
    description: 'Trà Sữa TCDD (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    defaultModifierSkus: ['V0621', 'V0618']
  },
  {
    normalPrice: 15000,
    sku: 'V0694',
    description: 'Hồng trà',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0694',
        description: 'Size M',
        normalPrice: 15000
      }
    ],
    category: 'milk-tea',
    subCat: 'hong-tra'
  },
  {
    normalPrice: 22000,
    sku: 'V0695',
    description: 'Hồng trà sữa',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0695',
        description: 'Size M',
        normalPrice: 22000
      },
      {
        group: {
          size: 'big'
        },
        sku: 'V0696',
        description: 'Size L',
        normalPrice: 30000
      }
    ],
    category: 'milk-tea',
    subCat: 'hong-tra'
  },
  {
    normalPrice: 25000,
    sku: 'V0708',
    description: 'Trà sữa hokkaido',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0708',
        description: 'Size M',
        normalPrice: 25000
      }
    ],
    category: 'milk-tea',
    subCat: 'hong-tra'
  },
  {
    normalPrice: 25000,
    sku: 'V0713',
    category: 'milk-tea',
    subCat: 'hong-tra',
    description: 'Hồng trà latte',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0713',
        description: 'Size M',
        normalPrice: 25000
      },
      {
        group: {
          size: 'big'
        },
        sku: 'V0714',
        description: 'Size L',
        normalPrice: 32000
      }
    ]
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1805',
    description: 'Trà sữa socola (M)'
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1807',
    description: 'Trà sữa dưa lưới (M)'
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1809',
    description: 'Trà sữa sầu riêng (M)'
  },
  // valentine
  // {
  //   sku: 'V2204',
  //   description: 'Hộp truffle 9 viên',
  //   normalPrice: 149000,
  //   category: 'valentine'
  // },
  // {
  //   sku: 'V2205',
  //   description: 'Hộp truffle 12 viên',
  //   normalPrice: 199000,
  //   category: 'valentine'
  // },
  // bánh trung thu
  // {
  //   sku: 'V2760',
  //   description: 'Hộp dạ cầm (4 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2757',
  //   description: 'Hộp hoa mẫu đơn (2 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2759',
  //   description: 'Hộp Hoa mẫu đơn (4 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2758',
  //   description: 'Hộp tranh Nhật (2 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2761',
  //   description: 'Hộp tranh Nhật (6 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // bánh kẹo tết
  // {
  //   sku: 'V8390',
  //   description: 'Set quà tết lộc xuân',
  //   normalPrice: 500000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8391',
  //   description: 'Set quà tết như ý',
  //   normalPrice: 800000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8394',
  //   description: 'Combo mix 5 loại kẹo + bánh thuyền túi 1kg',
  //   normalPrice: 390000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8395',
  //   description: 'Combo mix 5 loại kẹo túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8370',
  //   description: 'Kẹo dẻo cherry túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8371',
  //   description: 'Kẹo dẻo chanh leo túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8372',
  //   description: 'Kẹo dẻo mix vị túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8373',
  //   description: 'Bánh thuyền gạo lứt 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8376',
  //   description: 'Kẹo nougat vị lục trà nhài sữa chua túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8377',
  //   description: 'Kẹo nougat vị socola hạnh nhân túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8378',
  //   description: 'Kẹo nougat vị sữa siêu hạt túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8379',
  //   description: 'Kẹo nougat mix vị túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // bánh sinh nhật
  {
    sku: 'V2667',
    description: 'Bánh bông lan trứng muối sốt phô mai size vừa 20x8cm',
    normalPrice: 450000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2666',
    description: 'Bánh bông lan trứng muối sốt phô mai size nhỏ 16x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2665',
    description: 'Bánh bông lan trứng muối sốt phô mai size mini 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2664',
    description: 'Bánh bông lan trứng muối xoài size vừa 20x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2663',
    description: 'Bánh bông lan trứng muối xoài  size nhỏ 16x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2662',
    description: 'Bánh bông lan trứng muối xoài size mini 14x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2451',
    description: 'Bánh bông lan trứng muối việt quất size vừa 20x8cm',
    normalPrice: 450000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2450',
    description: 'Bánh bông lan trứng muối việt quất size nhỏ 16x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2449',
    description: 'Bánh bông lan trứng muối việt quất size mini 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2432',
    description: 'Bông lan trứng muối trái tim size vừa - 20x8cm',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2431',
    description: 'Bông lan trứng muối trái tim size nhỏ - 16x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2430',
    description: 'Bông lan trứng muối trái tim size mini - 14x6cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V1667',
    description: 'Bánh bông lan trứng muối Bông Hoa size vừa - 20x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V1400',
    description: 'Bánh bông lan trứng muối Bông Hoa size nhỏ - 16x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V1399',
    description: 'Bánh bông lan trứng muối Bông Hoa size mini - 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-bltm'
  },
  {
    sku: 'V2605',
    description: 'Bánh kem chữ nhật cắt miếng vị sữa chua hoa quả',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-event'
  },
  {
    sku: 'V2603',
    description: 'Bánh kem chữ nhật cắt miếng viết chữ',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-event'
  },
  {
    sku: 'V2215',
    description: 'Bánh kem thần tài đỏ size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-event'
  },
  {
    sku: 'V2213',
    description: 'Bánh kem thần tài đỏ size nhỏ - 17x8cm',
    normalPrice: 370000,
    category: 'birthday-cake',
    subCat: 'bcake-event'
  },
  {
    sku: 'V2255',
    description: 'Bánh hoa tulip màu xanh size nhỏ - 17x8cm',
    normalPrice: 320000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V2254',
    description: 'Bánh hoa tulip màu hồng size nhỏ - 17x8cm',
    normalPrice: 320000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V2252',
    description: 'Bánh hoa tulip màu xanh size mini - 13x6cm',
    normalPrice: 220000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V2251',
    description: 'Bánh hoa tulip màu hồng size mini - 13x6cm',
    normalPrice: 220000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V1886',
    description: 'Bánh kem hoa mặt cười size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V1882',
    description: 'Bánh hoa Tulip vải voan size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V1871',
    description: 'Bánh kem hoa mặt cười size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V1867',
    description: 'Bánh hoa Tulip vải voan size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V1856',
    description: 'Bánh kem hoa mặt cười size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V1852',
    description: 'Bánh hoa Tulip vải voan size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-flower'
  },
  {
    sku: 'V2552',
    description: 'Bánh kem Baby Shark size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2551',
    description: 'Bánh kem Baby Shark size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2550',
    description: 'Bánh kem Baby Shark size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2534',
    description: 'Bánh kem Siêu anh hùng size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2533',
    description: 'Bánh kem Siêu anh hùng size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2532',
    description: 'Bánh kem Siêu anh hùng size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2522',
    description: 'Bánh kem Elsa size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2521',
    description: 'Bánh kem Elsa size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2520',
    description: 'Bánh kem Elsa size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2510',
    description: 'Bánh kem khủng long size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2509',
    description: 'Bánh kem khủng long size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V2508',
    description: 'Bánh kem khủng long size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1673',
    description: 'Bánh kem Red Car size nhỏ - 21x13x10cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1671',
    description: 'Bánh kem Choco Bear size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1669',
    description: 'Bánh kem Choco Bear size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1575',
    description: 'Bánh kem Mango Chick size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1558',
    description: 'Bánh kem Mango Chick size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1501',
    description: 'Bánh kem Red Car size mini - 16x10x8cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1500',
    description: 'Bánh kem Pinky Princess size mini - 13x18cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1499',
    description: 'Bánh kem Mango Chick size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1498',
    description: 'Bánh kem Choco Bear size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-kid'
  },
  {
    sku: 'V1888',
    description: 'Bánh kem trái tim nhí size vừa - 21x7cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1887',
    description: 'Bánh kem cherry hồng pastel size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1873',
    description: 'Bánh kem trái tim nhí size nhỏ - 18x6cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1872',
    description: 'Bánh kem cherry hồng pastel size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1858',
    description: 'Bánh kem trái tim nhí size mini - 14x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1857',
    description: 'Bánh kem cherry hồng pastel size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1652',
    description: 'Bánh kem 3 Màu Pastel size vừa - 21x8cm',
    normalPrice: 340000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1651',
    description: 'Bánh kem Galaxy Blue size vừa - 21x8cm',
    normalPrice: 340000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1390',
    description: 'Bánh kem 3 Màu Pastel size nhỏ - 17x8cm',
    normalPrice: 240000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1389',
    description: 'Bánh kem 3 Màu Pastel size mini - 13x6cm',
    normalPrice: 150000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1388',
    description: 'Bánh kem Galaxy Blue size nhỏ - 17x8cm',
    normalPrice: 240000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V1387',
    description: 'Bánh kem Galaxy Blue size mini - 13x6cm',
    normalPrice: 150000,
    category: 'birthday-cake',
    subCat: 'bcake-korea'
  },
  {
    sku: 'V2585',
    description: 'Mousse việt quất socola size vừa 20x6cm',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2584',
    description: 'Mousse việt quất socola size nhỏ 18x6cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2583',
    description: 'Mousse việt quất socola size mini 14x6cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2579',
    description: 'Mousse matcha yogurt size vừa 20x6cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2578',
    description: 'Mousse matcha yogurt size nhỏ 18x6cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2577',
    description: 'Mousse matcha yogurt size mini 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2573',
    description: 'Mousse cà phê cốt dừa size vừa 20x6cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2572',
    description: 'Mousse cà phê cốt dừa size nhỏ 18x6cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2571',
    description: 'Mousse cà phê cốt dừa size mini 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2416',
    description: 'Mousse sữa chua việt quất size vừa 20x6cm',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2415',
    description: 'Mousse sữa chua việt quất size nhỏ 18x6cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2414',
    description: 'Mousse sữa chua việt quất size mini - 14x6cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1951',
    description: 'Mousse socola size vừa - 20x6cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1880',
    description: 'Bánh tiramisu size vừa - 20x5cm',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1879',
    description: 'Mousse xoài size vừa - 20x6cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1878',
    description: 'Mousse sữa chua dâu tây size vừa - 20x6cm',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1865',
    description: 'Bánh tiramisu size nhỏ - 18x5cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1864',
    description: 'Mousse xoài size nhỏ - 18x6cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1863',
    description: 'Mousse sữa chua dâu tây size nhỏ - 18x6cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1850',
    description: 'Bánh tiramisu size mini - 14x4cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1849',
    description: 'Mousse xoài size mini - 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1848',
    description: 'Mousse sữa chua dâu tây size mini - 14x6cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1741',
    description: 'Mousse Chanh Leo size vừa - 20x6cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1402',
    description: 'Mousse Chanh Leo size nhỏ - 18x6cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V1401',
    description: 'Mousse Chanh Leo size mini - 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-mousse'
  },
  {
    sku: 'V2609',
    description: 'Bánh kem chữ nhật order',
    normalPrice: 560000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V2308',
    description: 'Bánh kem bento order 11x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V2307',
    description: 'Bánh kem bento vẽ 11x6cm',
    normalPrice: 150000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1538',
    description: 'Bánh sinh nhật order size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1537',
    description: 'Bánh sinh nhật order size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1536',
    description: 'Bánh sinh nhật order size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1496',
    description: 'Bánh vẽ sinh nhật size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1445',
    description: 'Bánh vẽ sinh nhật size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1444',
    description: 'Bánh vẽ sinh nhật size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1553',
    description: 'Mousse socola size nhỏ - 18x6cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-socola'
  },
  {
    sku: 'V1551',
    description: 'Mousse socola size mini - 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-socola'
  },
  {
    sku: 'V1460',
    description: 'Bánh kem Triple Choco size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-socola'
  },
  {
    sku: 'V1394',
    description: 'Bánh kem Triple Choco size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-socola'
  },
  {
    sku: 'V1393',
    description: 'Bánh kem Triple Choco size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-socola'
  },
  {
    sku: 'V2673',
    description: 'Bánh kem sữa chua dâu mix hoa quả size vừa 21x8cm',
    normalPrice: 450000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V2672',
    description: 'Bánh kem sữa chua dâu mix hoa quả size nhỏ 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V2671',
    description: 'Bánh kem sữa chua dâu mix hoa quả size mini 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V2670',
    description: 'Bánh kem sữa chua dâu tây size vừa 21x8cm',
    normalPrice: 450000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V2669',
    description: 'Bánh kem sữa chua dâu tây size nhỏ 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V2668',
    description: 'Bánh kem sữa chua dâu tây size mini 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1610',
    description: 'Bánh kem màu loang dâu Đà Lạt size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1609',
    description: 'Bánh kem màu loang dâu Đà Lạt size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1608',
    description: 'Bánh kem màu loang dâu Đà Lạt size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1607',
    description: 'Bánh hồng kem chảy dâu Đà Lạt size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1606',
    description: 'Bánh hồng kem chảy dâu Đà Lạt size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1605',
    description: 'Bánh hồng kem chảy dâu Đà Lạt size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1598',
    description: 'Bánh red velvet tròn dâu Đà Lạt size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1597',
    description: 'Bánh red velvet tròn dâu Đà Lạt size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V1596',
    description: 'Bánh red velvet tròn dâu Đà Lạt size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-strawberry'
  },
  {
    sku: 'V2374',
    description: 'Bánh kem hoa quả nhiệt đới size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2372',
    description: 'Bánh kem mixed berries size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2370',
    description: 'Bánh kem sữa chua việt quất size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2369',
    description: 'Bánh kem nho xoài size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2367',
    description: 'Bánh kem nho xanh size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2366',
    description: 'Bánh kem việt quất dâu tây size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2365',
    description: 'Bánh kem xoài size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2364',
    description: 'Bánh kem hoa quả nhiệt đới size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2362',
    description: 'Bánh kem mixed berries size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2360',
    description: 'Bánh kem sữa chua việt quất size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2359',
    description: 'Bánh kem nho xoài size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2357',
    description: 'Bánh kem nho xanh size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2356',
    description: 'Bánh kem việt quất dâu tây size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2355',
    description: 'Bánh kem xoài size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2354',
    description: 'Bánh kem hoa quả nhiệt đới size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2352',
    description: 'Bánh kem mixed berries size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2350',
    description: 'Bánh kem sữa chua việt quất size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2349',
    description: 'Bánh kem nho xoài size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2347',
    description: 'Bánh kem nho xanh size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2346',
    description: 'Bánh kem việt quất dâu tây size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2345',
    description: 'Bánh kem xoài size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'fresh-fruit'
  },
  {
    sku: 'V2634',
    description: 'Bánh kem chữ nhật sữa chua hoa quả 24x32x6cm',
    normalPrice: 600000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V2596',
    description: 'Bánh red velvet kem sữa chua hoa quả size vừa 20x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V2594',
    description: 'Bánh red velvet kem sữa chua hoa quả size nhỏ 18x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V2592',
    description: 'Bánh red velvet kem sữa chua hoa quả size mini 14x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1531',
    description: 'Bánh sữa chua hoa quả size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1530',
    description: 'Bánh red velvet tim kem sữa chua size vừa - 21x7cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1507',
    description: 'Bánh sữa chua hoa quả size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1506',
    description: 'Bánh sữa chua hoa quả size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1505',
    description: 'Bánh red velvet tim kem sữa chua size nhỏ - 18x6cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1504',
    description: 'Bánh red velvet tim kem sữa chua size mini - 14x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1463',
    description: 'Bánh kem Cà Phê Cốt Dừa size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1462',
    description: 'Bánh kem Xoài Dừa size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1461',
    description: 'Bánh kem Green Tea size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1398',
    description: 'Bánh kem Xoài Dừa size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1397',
    description: 'Bánh kem Xoài Dừa size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1396',
    description: 'Bánh kem Green Tea size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1395',
    description: 'Bánh kem Green Tea size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1392',
    description: 'Bánh kem Cà Phê Cốt Dừa size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  {
    sku: 'V1391',
    description: 'Bánh kem Cà Phê Cốt Dừa size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'special-taste'
  },
  // Bánh ngọt
  {
    sku: 'V2319',
    normalPrice: 49000,
    category: 'cake',
    subCat: 'banh-ngot',
    description: 'Bánh sừng bò chấm sốt phô mai (hộp 3 chiếc)'
  },
  {
    sku: 'V1644',
    normalPrice: 40000,
    category: 'cake',
    subCat: 'banh-ngot',
    description: 'Bánh lưỡi mèo hộp 150g'
  },
  // Phụ kiện
  {
    sku: 'V2616',
    description: 'Mũ sinh nhật giấy hình vương miện (chiếc)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 5000
  },
  {
    sku: 'V1426',
    description: 'Mũ sinh nhật',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 5000
  },
  {
    sku: 'V1790',
    description: 'In ảnh bánh sinh nhật',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 20000
  },
  {
    sku: 'V1457',
    description: 'Que cắm Love you Mom',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 15000
  },
  {
    sku: 'V1278',
    description: 'Que cắm Happy birthday tròn vàng 16cm',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 9000
  },
  {
    sku: 'V1279',
    description: 'Nến sinh nhật nhũ vàng(10 chiếc)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 10000
  },
  {
    sku: 'V1360',
    description: 'Bộ dao dĩa đĩa nhựa bánh sinh nhật Savor',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 10000
  },
  {
    sku: 'V1403',
    description: 'Nến chữ Happy Birthday (màu ngẫu nhiên)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 25000
  },
  {
    sku: 'V1413',
    description: 'Nến nhũ số 0',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1414',
    description: 'Nến nhũ số 1',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1415',
    description: 'Nến nhũ số 2',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1416',
    description: 'Nến nhũ số 3',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1417',
    description: 'Nến nhũ số 4',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1418',
    description: 'Nến nhũ số 5',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1419',
    description: 'Nến nhũ số 6',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1420',
    description: 'Nến nhũ số 7',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1421',
    description: 'Nến nhũ số 8',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1422',
    description: 'Nến nhũ số 9',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1423',
    description: 'Nến pháo bông sinh nhật(1 chiếc)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 30000
  },
  {
    sku: 'V1953',
    description: 'Hộp gato mica trong suốt 22cm kèm ruy băng',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 40000
  },
  {
    sku: 'V1954',
    description: 'Hộp gato mica trong suốt 26cm kèm ruy băng',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 45000
  },
  {
    sku: 'V1955',
    description: 'Hộp gato mica trong suốt 30cm kèm ruy băng',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 50000
  },
  {
    category: 'birthday-cake',
    subCat: 'accessory',
    sku: 'V2203',
    description: 'Thiệp valentine (bộ)',
    normalPrice: 8000
  },
  {
    category: 'birthday-cake',
    subCat: 'accessory',
    sku: 'V2208',
    description: 'Que cắm Happy Valentine My Love (chiếc)',
    normalPrice: 0
  }
  // {
  //   category: 'gift',
  //   sku: 'V2857',
  //   description: 'Gói tương ớt Chinsu',
  //   normalPrice: 3000
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2340',
  //   description: 'Sữa Dutch Lady',
  //   normalPrice: 10000
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2469',
  //   description: 'Sprite tặng (Baemin) (chai)',
  //   normalPrice: 0
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2470',
  //   description: 'Fanta tặng (Baemin) (chai)',
  //   normalPrice: 0
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2570',
  //   description: 'Pepsi tặng 245ml (ShopeeFood) (lon)',
  //   normalPrice: 10000
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2638',
  //   description: 'Coca lon (tặng kèm đơn Grab)',
  //   normalPrice: 10000
  // }
])
