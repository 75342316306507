import React, { Component } from 'react'
import { Modal, Form } from 'semantic-ui-react'
import { TShippingProvider, OrderStore } from '../model/order.store'
import { observer, inject } from 'mobx-react'
import _, { clone, toNumber } from 'lodash'
import { InputCurrency } from './InputCurrency'
import { toast } from 'react-toastify'
interface ModalSelectSaleCodeProps {
  order: OrderStore
}
interface ModalSelectSaleCodeState {
  saleCodeId: string
  openModal: boolean
}

const DEFAULT_STATE: ModalSelectSaleCodeState = {
  saleCodeId: '',
  openModal: false
}

export const ModalSelectSaleCode = inject('rootStore')(
  observer(
    class extends Component<ModalSelectSaleCodeProps, ModalSelectSaleCodeState> {
      state: ModalSelectSaleCodeState = clone(DEFAULT_STATE)

      handleSubmitSaleCodeData = async () => {
        const currentSaleCode = this.state.saleCodeId
        if (!currentSaleCode) {
          return toast.error('Chưa điền mã Sale code')
        }
        if (_.some(this.props.order.items, { saleCodeId: currentSaleCode })) {
          return toast.error(`Sale code ${currentSaleCode} đã có trong đơn rồi`)
        }
        await this.props.order.fetchSaleCode(currentSaleCode)
        if (!this.props.order.autosave) {
          this.setState(DEFAULT_STATE)
        } else {
          this.setState({ openModal: false })
        }
      }

      handleClearSaleCodeData = () => {
        this.setState(clone(DEFAULT_STATE))
      }

      handleOpenModal = () => {
        this.setState({
          openModal: true
        })
      }

      handleCloseModal = () => {
        this.setState({ openModal: false })
      }

      render() {
        return (
          <Modal
            trigger={this.props.children}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>Sử dụng Sale code BSN</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>Nhập mã Sale Code</label>
                  <input
                    type="text"
                    value={this.state.saleCodeId}
                    onChange={(e: any) => {
                      this.setState({ saleCodeId: e.target.value.trim() })
                    }}
                    placeholder="VD: S12345678"
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        this.handleSubmitSaleCodeData()
                      }
                    }}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 'done',
                  color: 'green',
                  content: 'Tìm',
                  style: { minWidth: '50%' },
                  onClick: this.handleSubmitSaleCodeData
                },
                {
                  key: 'clear',
                  color: 'blue',
                  content: 'Hủy',
                  onClick: this.handleClearSaleCodeData
                }
              ]}
            />
          </Modal>
        )
      }
    }
  )
)
