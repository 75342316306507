import React from 'react'
import { observer, inject } from 'mobx-react'
import { IComputedItem, OrderStore } from '../../model/order.store'
import { Modal, Header, Image, Divider, Card, Button, Icon } from 'semantic-ui-react'
import { ModifierStore, IModifier } from '../../model/modifier.store'
import QuantityChanger from '../CreateOrderView/CurrentOrder/QuantityChanger'
import placeholder from './../../img/placeholder.png'
import { FormattedCurrency } from '../../components/FormattedCurrency'
import styles from './../CreateOrderView/CurrentOrder/ModalChangeOrderItem.module.scss'
import { IVariant } from '../../model/product.store'
import { toast } from 'react-toastify'

interface ModalEditOrderItemProps {
  trigger: any
  order: OrderStore
  itemId: string
  modifierStore?: ModifierStore
}

export const ModalEditOrderItem = inject('modifierStore')(
  observer(
    class extends React.Component<ModalEditOrderItemProps> {
      state = {
        openModal: false
      }

      handleAddButtonClick(item: IComputedItem, modifier: IModifier) {
        const orderStore = this.props.order
        return function() {
          const err = orderStore.addModifier(item, modifier)
          if (err) {
            toast.error(err)
          }
        }
      }

      handleSubtractButtonClick(item: IComputedItem, modifier: IModifier) {
        const orderStore = this.props.order
        return function() {
          orderStore.subtractModifier(item, modifier)
        }
      }

      handleSelectVariant = (item: IComputedItem, variant: IVariant) => () => {
        this.props.order.selectVariant(item, variant)
      }

      handleChangeItemQuantity = (item: IComputedItem) => {
        return (quantity: number) => {
          this.props.order.changeItemQuantity(item, quantity)
        }
      }

      handleRemoveItem = (item: IComputedItem) => {
        return () => {
          this.props.order.removeItem(item)
          this.handleCloseModal()
        }
      }

      handleOpenModal = () => {
        this.setState({ openModal: true })
      }

      handleCloseModal = () => {
        this.setState({ openModal: false })
      }

      render() {
        const item = this.props.order.computedItems.find(item => item.id === this.props.itemId)
        const modifierStore = this.props.modifierStore
        const modifierSets = item ? modifierStore!.getComputedModifierSets(item) : null

        return (
          <Modal
            trigger={this.props.trigger}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            {item ? (
              <>
                <Modal.Header>Sửa món {item.description}</Modal.Header>
                <Modal.Content image>
                  <div className="ui medium image">
                    <Image src={item.imageLink || placeholder} />
                  </div>
                  <Modal.Description>
                    <Header as="h2">Số lượng</Header>
                    <QuantityChanger
                      onQuantityChanged={this.handleChangeItemQuantity(item)}
                      quantity={item.quantity}
                    />
                    <Divider />
                    {item.variants &&
                      item.variants.length > 0 && (
                        <div>
                          <Header>Chọn loại</Header>
                          <Card.Group itemsPerRow={3} className={styles.cards}>
                            {item.variants.map(variant => (
                              <Card
                                key={variant.sku}
                                className={
                                  item.selectedVariant!.sku === variant.sku ? styles.selected : ''
                                }
                                onClick={this.handleSelectVariant(item, variant)}
                              >
                                <Card.Content>
                                  <Card.Header className={styles.header}>
                                    {variant.description}
                                  </Card.Header>
                                  <Card.Meta className={styles.description}>
                                    <FormattedCurrency
                                      value={variant.salePrice || variant.normalPrice}
                                    />
                                  </Card.Meta>
                                </Card.Content>
                              </Card>
                            ))}
                          </Card.Group>
                          <Divider />
                        </div>
                      )}
                    {modifierSets!.map(modifierSet => (
                      <div key={modifierSet.code}>
                        <Header>{modifierSet.name}</Header>
                        <Card.Group key={modifierSet.code} itemsPerRow={4}>
                          {modifierSet.modifiers.map(modifier => {
                            const matchItemModifier = item.modifiers.find(
                              itemModifier => itemModifier.sku === modifier.sku
                            )
                            return (
                              <Card
                                key={modifier.sku}
                                className={
                                  matchItemModifier && matchItemModifier.quantity > 0
                                    ? styles.selected
                                    : ''
                                }
                              >
                                <Card.Content>
                                  <Card.Description>
                                    {modifier.description}
                                    {matchItemModifier &&
                                      matchItemModifier.quantity > 0 && (
                                        <span className={styles['modifier-quantity']}>
                                          {' '}
                                          x{matchItemModifier.quantity}
                                        </span>
                                      )}
                                  </Card.Description>
                                  {modifier.price &&
                                    modifier.price > 0 && (
                                      <Card.Meta>
                                        <FormattedCurrency value={modifier.price} />
                                      </Card.Meta>
                                    )}
                                </Card.Content>
                                <Card.Content extra>
                                  <div className="ui two buttons">
                                    <Button
                                      className={styles['modifier-quantity-button']}
                                      basic
                                      onClick={this.handleSubtractButtonClick(item, modifier)}
                                      icon={<Icon name="minus" />}
                                    />
                                    <Button
                                      className={styles['modifier-quantity-button']}
                                      basic
                                      onClick={this.handleAddButtonClick(item, modifier)}
                                      icon={<Icon name="plus" />}
                                    />
                                  </div>
                                </Card.Content>
                              </Card>
                            )
                          })}
                        </Card.Group>
                        <Divider />
                      </div>
                    ))}
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions
                  actions={[
                    {
                      key: 'Remove',
                      content: 'Xóa món',
                      color: 'red',
                      onClick: this.handleRemoveItem(item),
                      floated: 'left'
                    },
                    {
                      key: 'Done',
                      content: 'Xong',
                      color: 'green',
                      onClick: this.handleCloseModal
                    }
                  ]}
                />
              </>
            ) : null}
          </Modal>
        )
      }
    }
  )
)
