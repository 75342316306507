import React from 'react'
import Cleave from 'cleave.js/react'
import { ChangeEventHandler } from 'cleave.js/react/props'

interface InputCurrencyProps {
  onChange: ChangeEventHandler<HTMLInputElement>
  [key: string]: any
}

export const InputCurrency: React.SFC<InputCurrencyProps> = ({ onChange, ...rest }) => {
  return (
    <Cleave
      {...rest}
      options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
      onChange={onChange}
      data-testid="input-currency"
    />
  )
}
