import { isBefore, isAfter } from 'date-fns'
import { IOrderItem, TOrderPlatform, TOrderType } from '../model/order.store'
import { ICustomer, IVoucher } from '../model/customer.model'
import { getItemSoldPrice } from './order.util'
import { some, get, reduce, includes } from 'lodash'
import { IOutlet } from '../model/outlet-list.store'

interface DrinkDiscountMap {
  [key: string]: {
    salePrice: number
    description: string
  }
}
export function getItemDiscount(item: IOrderItem, discountItemMap: any) {
  return get(item, 'onlineOrderDiscount.discount', 0) || discountItemMap[item.id].discount
}

export function validGiftSharkTankDealFirework(
  items: IOrderItem[],
  outlet: IOutlet | string,
  customer: ICustomer
) {
  return (
    isAfter(new Date(), new Date('2024-01-16T00:00:00+07:00')) &&
    isBefore(new Date(), new Date('2024-02-17T00:00:00+07:00')) &&
    some(items, { category: 'birthday-cake' }) &&
    (get(outlet, 'code') === 'E9' || outlet === '59dc89206f240111e143771c') &&
    !get(customer, 'sharkTankFireworkSold') &&
    !get(customer, 'sharkTankFireworkGiftedOnlineOrder')
  )
}
export function isChinsuGiftValid(items: IOrderItem[]) {
  const giftedCats = ['bread', 'sticky-rice', 'sandwich']
  const validProduct = some(items, item => {
    return giftedCats.includes(item.category) || item.description.match(/(bánh mì|xôi|sandwich)/i)
  })
  const validTime =
    isAfter(new Date(), new Date('2023-11-23T00:00:00+07:00')) &&
    isBefore(new Date(), new Date('2024-01-01T00:00:00+07:00'))
  return validTime && validProduct
}

export function getBulkDiscountValidTotal(
  items: IOrderItem[],
  orderPlatform: TOrderPlatform,
  orderType: TOrderType
) {
  return items
    .filter(
      item =>
        !['birthday-cake'].includes(item.category) &&
        ![
          'V1408',
          'V1692',
          'V1691',
          'V1690',
          'V1689',
          'V1688',
          'V1687',
          'V1686',
          'V1685',
          'V1684',
          'V1683'
        ].includes(item.sku)
    )
    .reduce((result, item) => {
      return item.quantity * getItemSoldPrice({ item, orderPlatform, orderType }) + result
    }, 0)
}

export function isBulkDiscountValid(
  items: IOrderItem[],
  orderPlatform: TOrderPlatform,
  orderType: TOrderType
) {
  const subtotal = getBulkDiscountValidTotal(items, orderPlatform, orderType)

  return subtotal >= 80000 && orderPlatform === 'SAVOR_SHOP'
}

export function getSubTOtalWithoutPricedGift(
  items: IOrderItem[],
  orderPlatform: TOrderPlatform,
  orderType: TOrderType
) {
  const subTotalWithoutPricedGift = items.reduce((result, item) => {
    // console.log(
    //   '🚀 ~ file: bulk-discount.ts:57 ~ subTotalWithoutPricedGift:',
    //   item.description,
    //   getItemSoldPrice(item, orderPlatform, orderType, false, true)
    // )
    return (
      item.quantity *
        getItemSoldPrice({
          item,
          orderPlatform,
          orderType,
          withoutModifier: false,
          totalForDiscount: true
        }) +
      result
    )
  }, 0)
  return subTotalWithoutPricedGift
}
export function isBillTotalDiscountValid(
  items: IOrderItem[],
  orderPlatform: TOrderPlatform,
  orderType: TOrderType
) {
  const subTotalWithoutPricedGift = getSubTOtalWithoutPricedGift(items, orderPlatform, orderType)
  if (
    orderPlatform === 'NOW' &&
    isAfter(new Date(), new Date('2023-07-10T00:00:00+07:00')) &&
    isBefore(new Date(), new Date('2023-07-24T00:00:00+07:00'))
  ) {
    return subTotalWithoutPricedGift >= 50000
  }
  if (orderPlatform === 'GRAB_FOOD') {
    return subTotalWithoutPricedGift >= 30000
  }
  if (some(items, { category: 'birthday-cake' })) {
    return subTotalWithoutPricedGift >= 300000
  }
}

export function getJarTetBuy6Get1Quantity(items: IOrderItem[]) {
  const validQuantity = items
    .filter(item =>
      [
        'V2136',
        'V2137',
        'V2138',
        'V2139',
        'V2140',
        'V2141',
        'V2142',
        'V2143',
        'V2144',
        'V2145',
        'V2146',
        'V2147',
        'V2148',
        'V2149',
        'V2150',
        'V2151',
        'V2152',
        'V2153'
      ].includes(item.sku)
    )
    .reduce((result, item) => {
      return (result += item.quantity)
    }, 0)
  const giftNum = Math.floor(validQuantity / 6)

  return { validQuantity, giftNum }
}

function calucateComboQuantity(items: IOrderItem[], sku1: string, sku2: string) {
  let elem1 = false
  let elem2 = false
  const totalElemQuantity = items
    .filter(item => [sku1, sku2].includes(item.sku))
    .reduce((result, item) => {
      if (item.sku === sku1) {
        elem1 = true
        result += item.quantity
      }
      if (item.sku === sku2) {
        elem2 = true
        result += item.quantity
      }
      return result
    }, 0)
  let validComboQuantity = 0
  if (elem1 && elem2) {
    validComboQuantity = Math.floor(totalElemQuantity / 2)
  }
  return validComboQuantity
}
export function isComboBaeminT4(items: IOrderItem[]) {
  const comboDrinkQuantity = calucateComboQuantity(items, 'V2331', 'V2327')
  const comboSandwichQuantity = calucateComboQuantity(items, 'V1830', 'V1825')
  return comboDrinkQuantity + comboSandwichQuantity
}

export const drinkDiscountMap: DrinkDiscountMap = {
  V2331: {
    salePrice: 19000,
    description: 'Trà Ô Long chanh leo (M)'
  },
  V2333: {
    salePrice: 15000,
    description: 'Trà Ô Long kim quất (M)'
  },
  V2329: {
    salePrice: 22000,
    description: 'Trà Ô Long nhài sữa trân châu (M)'
  },
  V0608: {
    salePrice: 22000,
    description: 'Trà sữa trân châu đường đen (M)'
  },
  V0400: {
    salePrice: 15000,
    description: 'Cà phê đen đá 250ml'
  },
  V0401: {
    salePrice: 19000,
    description: 'Cà phê sữa đá 250ml'
  },
  V1091: {
    salePrice: 19000,
    description: 'Bạc xỉu đá'
  },
  V0001: {
    salePrice: 15000,
    description: 'Sữa chua uống Savor chai 250ml'
  }
}

export const drinkDiscountNames = Object.values(drinkDiscountMap).map(item => item.description)

export function getDrinkDiscountQuantity(items: IOrderItem[]) {
  return items.reduce((result, item) => {
    if (!['bread', 'sticky-rice'].includes(item.category)) {
      return result
    }
    if (item.isCombo || item.comboId) {
      result -= item.quantity
    }
    result += item.quantity
    return result
  }, 0)
}

export function checkValidDiscountOpenS17({
  outletCode,
  orderPlatform,
  voucher,
  typeCheck
}: {
  outletCode: string
  orderPlatform: TOrderPlatform
  voucher?: IVoucher | null | undefined
  typeCheck: string
}) {
  if (orderPlatform !== 'SAVOR_SHOP') {
    return false
  }
  if (!['S17', 'S7'].includes(outletCode)) {
    return false
  }
  if (outletCode === 'S17') {
    if (isBefore(new Date(), new Date('2024-07-04T00:00:00+07:00'))) {
      return false
    }
    if (isAfter(new Date(), new Date('2024-08-05T00:00:00+07:00'))) {
      return false
    }
  }
  if (outletCode === 'S7') {
    if (isBefore(new Date(), new Date('2024-07-28T00:00:00+07:00'))) {
      return false
    }
    if (isAfter(new Date(), new Date('2024-09-01T00:00:00+07:00'))) {
      return false
    }
  }

  if (typeCheck === 'toShow') {
    return true
  }
  return voucher && voucher.voucherType === 'OPENING_20_PERCENT'
}

export function getStaffDiscountByBusiness({
  breadDiscount,
  cakeDiscount,
  percent,
  items,
  discountItemMap,
  orderPlatform,
  orderType
}: {
  breadDiscount: boolean
  cakeDiscount: boolean
  percent: number
  items: IOrderItem[]
  discountItemMap: any
  orderPlatform: TOrderPlatform
  orderType: TOrderType
}) {
  return reduce(
    items,
    (result, item) => {
      if (item.sku !== 'V1408' && getItemDiscount(item, discountItemMap) > 0) {
        // xét giống withoutDiscountsSubTotal
        return result
      }
      const soldPrice = getItemSoldPrice({
        item,
        orderPlatform,
        orderType
      })
      // xét theo birthday-cake vì đã xử lý sync db và convert theo productcat Bánh sinh nhật và Phụ kiện BSN ở hàm convertCategory
      if (!breadDiscount && item.category !== 'birthday-cake') {
        // product savor bread nhưng ko được giảm giá
        return result
      }
      if (!cakeDiscount && item.category === 'birthday-cake') {
        // product savor cake nhưng ko được giảm giá
        return result
      }
      return result + (soldPrice * item.quantity * percent) / 100
    },
    0
  )
}

export function getDeliverĐeadline(onlineOrder: any): Date {
  return onlineOrder && onlineOrder.deadline ? new Date(onlineOrder.deadline) : new Date()
}
export function checkValidGiftFlag(
  onlineOrder: any,
  orderPlatform: TOrderPlatform,
  items: IOrderItem[]
) {
  if (!some(items, item => includes(['birthday-cake', 'mooncake'], item.category))) {
    return false
  }
  const deliverDeadline = getDeliverĐeadline(onlineOrder)
  return (
    orderPlatform === 'SAVOR_SHOP' &&
    isAfter(deliverDeadline, new Date('2024-08-31T23:59:59+07:00')) &&
    isBefore(deliverDeadline, new Date('2024-09-03T00:00:00+07:00'))
  )
}
