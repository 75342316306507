import React, { useContext, Fragment } from 'react'
import { RootStoreContext } from '../../../model/root-store'
import { SelectVoucher } from './SelectVoucher'
import { observer } from 'mobx-react-lite'

export const CurrentOrderVoucher = observer(function CurrentOrderVoucher() {
  const rootStore = useContext(RootStoreContext)
  const orderStore = rootStore.orderStore

  return orderStore.availableVouchers.length ? (
    <Fragment>
      {orderStore.availableVouchers.length > 0 && (
        <SelectVoucher availableVouchers={orderStore.availableVouchers} />
      )}
    </Fragment>
  ) : null
})
