import React, { useContext } from 'react'
import { Header, Icon } from 'semantic-ui-react'
import styles from './CurrentOrder.module.scss'
import { RootStoreContext } from '../../../model/root-store'
import { observer } from 'mobx-react-lite'

export const CurrentOrderHeader = observer(function CurrentOrderHeader() {
  const { orderStore } = useContext(RootStoreContext)
  return (
    <Header className={`${styles['order-header']}`}>
      <span>
        Đơn hàng{' '}
        <small className={orderStore.orderPlatform !== 'SAVOR_SHOP' ? styles['highlighted'] : ''}>
          {orderStore.orderPlatform}
        </small>
      </span>
      <span className={`${styles['clear-order-button']}`}>
        <Icon name="trash" color="red" onClick={() => orderStore.clearOrder()} />
      </span>
    </Header>
  )
})
