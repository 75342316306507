import { observable, decorate, action, runInAction } from 'mobx'
import { RootStore } from './root-store'

type TCreateOrderViewLayout = 'split' | 'items' | 'order'

export class UiStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }
  createOrderViewLayout: TCreateOrderViewLayout = 'split'
  showProductStatuses: Boolean = true
  filteredOutlet: string = 'all'
  openSidebar: boolean = false
  notifyingReload: boolean = false
  setNotifyingReload(value: boolean) {
    this.notifyingReload = value
  }

  changeCreateOrderViewLayout(layout: TCreateOrderViewLayout) {
    this.createOrderViewLayout = layout
  }

  changeShowProductStatuses(showProductStatuses: boolean) {
    this.showProductStatuses = showProductStatuses
  }

  changeFilteredOutlet(filteredOutlet: string) {
    this.filteredOutlet = filteredOutlet
  }

  setOpenSidebar(value: boolean) {
    this.openSidebar = value
  }
}

decorate(UiStore, {
  createOrderViewLayout: observable,
  showProductStatuses: observable,
  filteredOutlet: observable,
  changeCreateOrderViewLayout: action,
  changeShowProductStatuses: action,
  changeFilteredOutlet: action,
  openSidebar: observable,
  setOpenSidebar: action,
  notifyingReload: observable,
  setNotifyingReload: action
})

export const uiStore = new UiStore()
