import React, { useContext, Fragment } from 'react'
import { RootStoreContext } from '../../../model/root-store'
import { List, Icon } from 'semantic-ui-react'
import { ModalEditCustomer } from '../../../components/ModalEditCustomer'
import { LabelCustomerCardStatus } from '../../../components/LabelCustomerCardStatus'
import { FormattedDate } from 'react-intl'
import { observer } from 'mobx-react-lite'

export const CurrentOrderCustomer = observer(function CurrentOrderCustomer() {
  const rootStore = useContext(RootStoreContext)
  const orderStore = rootStore.orderStore

  function handleClearCustomer() {
    orderStore.clearCustomer()
  }

  return orderStore.computedCustomer ? (
    <Fragment>
      <List>
        <List.Item>
          <List.Content floated="right">
            <Icon name="remove" link onClick={handleClearCustomer} />
          </List.Content>
          <List.Content>
            <List.Header>KH: {orderStore.computedCustomer.fullName}</List.Header>
            <List.Description>
              {orderStore.computedCustomer.cellphone}
              {orderStore.computedCustomer.isStaff && ' (Nhân viên 4handy)'}
            </List.Description>
          </List.Content>
        </List.Item>

        <List.Item>
          <List.Content>
            <List.Header>
              Trạng thái thẻ:
              <ModalEditCustomer customer={orderStore.customer}>
                <LabelCustomerCardStatus customerCardStage={orderStore.customerCardStage} />
              </ModalEditCustomer>
            </List.Header>
            {orderStore.isPendingCustomerCard && orderStore.computedCustomer.registerTime && (
              <List.Description>
                Ngày đăng ký thẻ: <FormattedDate value={orderStore.computedCustomer.registerTime} />
                .
              </List.Description>
            )}
            {orderStore.computedCustomer.numRemainDiscount &&
            !orderStore.computedCustomer.remainDiscountValue ? (
              <List.Description>
                Số cốc nước giảm giá còn lại được mua :{' '}
                {orderStore.computedCustomer.numRemainDiscount}
              </List.Description>
            ) : null}
            {orderStore.computedCustomer.remainDiscountValue ? (
              <List.Description>
                Tổng giá trị còn được giảm giá : {orderStore.computedCustomer.remainDiscountValue}
              </List.Description>
            ) : null}
            {orderStore.computedCustomer.numSaleBread ? (
              <List.Description>
                Số đơn đã mua được giảm giá NV hôm nay : {orderStore.computedCustomer.numSaleBread}
              </List.Description>
            ) : null}
          </List.Content>
        </List.Item>
      </List>
    </Fragment>
  ) : null
})
