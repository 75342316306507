import React from 'react'
import { range } from 'lodash'
import { ChangeEvent } from 'react'

const options = range(1930, 2011)
  .sort((a, b) => {
    return b - a
  })
  .map(year => {
    return { key: year, value: year, text: `${year}` }
  })

options.push({ key: 0, value: 0, text: '=== Chưa chọn ===' })

interface DropdownBirthyearProps {
  onSelect: (birthyear: string) => any
  value: string
}

export function DropdownBirthyear({ onSelect, value }: DropdownBirthyearProps) {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onSelect(e.target.value)
  }
  return (
    <select
      className="ui dropdown"
      value={value}
      onChange={handleChange}
      data-testid="dropdown-birthyear"
    >
      <option value="">===Chọn năm sinh===</option>
      {options.map(option => (
        <option key={option.key} value={option.value} data-testid="dropdown-birthyear-option">
          {option.text}
        </option>
      ))}
    </select>
  )
}
