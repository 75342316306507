import React, { SyntheticEvent, Fragment } from 'react'
import {
  Modal,
  Form,
  Divider,
  InputProps,
  Header,
  Card,
  Dimmer,
  Loader,
  Segment,
  Input
} from 'semantic-ui-react'

import { range, debounce, isString } from 'lodash'
import { ICustomer, customerStore } from '../model/customer.model'
import { toast } from 'react-toastify'
import Bluebird from 'bluebird'

import { ObjectId } from '../libs/object-id'
import { DropdownBirthyear } from './DropdownBirthyear'
import { InputDistrict } from './InputDistrict'
import { IDistrict } from '../model/district.store'
import { handleErrorResponse } from '../libs/error-handler'

const options = range(1930, 2011).map(year => {
  return { key: year, value: year, text: `${year}` }
})

options.push({ key: 0, value: 0, text: '=== Chưa chọn ===' })

interface IModalEditOrderCustomerProps {
  onSelectCustomer: Function
}

type TFieldKey =
  | 'fullName'
  | 'cellphone'
  | 'birthyear'
  | 'numberInCustomerId'
  | 'customerId'
  | 'street'
  | 'district'

interface IModalEditOrderCustomerState {
  openModal: boolean
  searchText: string
  newCustomer: boolean
  fullName: string
  cellphone: string
  birthyear: string
  district: IDistrict | null
  districtLabel: string
  street: string
  numberInCustomerId: string
  customerId: string
  customers: ICustomer[]
  loadingCustomers: boolean
  clickedSearch: boolean
  creatingCustomer: boolean
  registerCustomerCard: boolean
  [fieldName: string]: any
}

export class ModalSelectCustomer extends React.Component<
  IModalEditOrderCustomerProps,
  IModalEditOrderCustomerState
> {
  constructor(props: any) {
    super(props)
    this.state = this.initialState
  }

  initialState: IModalEditOrderCustomerState = {
    openModal: false,
    newCustomer: false,
    searchText: '',
    fullName: 'Khách Hàng',
    cellphone: '',
    birthyear: '',
    district: null,
    districtLabel: '',
    street: '',
    numberInCustomerId: '',
    customerId: '',
    customers: [],
    loadingCustomers: false,
    clickedSearch: false,
    creatingCustomer: false,
    registerCustomerCard: false
  }

  searchInput: Input | null = null
  nameInput: Input | null = null

  handleInputRef = (input: Input) => {
    this.searchInput = input
  }

  resetState = () => {
    this.setState(this.initialState)
  }

  handleSearchTextChange = (e: SyntheticEvent, { value }: InputProps) => {
    this.setState({ searchText: value, cellphone: value })

    if (isString(value) && value.length >= 4) {
      this.debouncedSearchCustomer()
    }
  }

  handleToggleNewCustomer = () => {
    this.setState(({ newCustomer }) => ({
      newCustomer: !newCustomer
    }))
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput.focus()
      }
    })
  }

  handleToggleRegisterCustomerCard = () => {
    this.setState(({ registerCustomerCard }) => ({
      registerCustomerCard: !registerCustomerCard
    }))
  }

  handleChangeField = (fieldName: TFieldKey) => (e: SyntheticEvent, { value }: InputProps) => {
    this.setState({ [fieldName]: value })
  }

  handleRemoveField = (fieldName: TFieldKey) => () => {
    this.setState({ [fieldName]: '' })
    if (fieldName === 'fullName') {
      setTimeout(() => {
        if (this.nameInput) this.nameInput.focus()
      })
    }
  }

  handleBirthyearChange = (birthyear: string) => {
    this.setState({ birthyear: birthyear })
  }

  handleSelectDistrict = (district: IDistrict) => {
    if (!district) {
      this.setState({
        districtLabel: '',
        district: null
      })
      return
    }
    this.setState({
      districtLabel: district.label,
      district: district
    })
  }

  handleNumberInCustomerIdChange = (e: SyntheticEvent, { value }: InputProps) => {
    this.setState({ numberInCustomerId: value, customerId: `CX${value}` })
  }

  createCustomerPromise?: Bluebird<any>
  handleCreateCustomer = async () => {
    if (!this.state.fullName) {
      toast.error('Chưa điền tên khách hàng')
      return
    }
    if (!this.state.cellphone) {
      toast.error('Chưa điền số điện thoại khách hàng')
      return
    }

    if (this.state.registerCustomerCard) {
      if (this.state.fullName === 'Khách Hàng') {
        toast.error('Chưa có họ và tên khách hàng')
        return
      }
      if (this.state.fullName && this.state.fullName.split(/\s+/).length <= 1) {
        toast.error('Chưa có đủ họ và tên khách hàng')
        return
      }
      if (!this.state.district || !this.state.district.city) {
        toast.error('Chưa có thông tin tỉnh/thành phố')
        return
      }
      if (!this.state.district || !this.state.district.district) {
        toast.error('Chưa có thông tin quận/huyện')
        return
      }
      if (!this.state.birthyear) {
        toast.error('Chưa có thông tin năm sinh')
        return
      }
      if (!this.state.customerId) {
        toast.error('Chưa có số thẻ khách hàng')
        return
      }
      if (this.state.customerId && !this.state.customerId.match(/^C[XY]\d{7}$/)) {
        toast.error('Mã thẻ khách hàng không hợp lệ')
        return
      }
    }

    if (!this.state.customerId || !this.state.birthyear) {
      this.setState({
        customerId: this.state.cellphone
      })
    }

    const customer: ICustomer = {
      _id: ObjectId(),
      fullName: this.state.fullName,
      cellphone: this.state.cellphone,
      customerId: this.state.customerId
    }

    if (this.state.registerCustomerCard) {
      customer.address = {
        district: this.state.district ? this.state.district.district : '',
        city: this.state.district ? this.state.district.city : '',
        street: this.state.street
      }
      customer.birthday = new Date(parseInt(this.state.birthyear), 1, 1)
      customer.registered = true
      customer.registerTime = new Date()
    }

    this.createCustomerPromise = customerStore.createCustomer(customer)

    this.setState({ creatingCustomer: true })
    try {
      const responseCustomer = await this.createCustomerPromise
      this.selectCustomer(responseCustomer)
    } catch (e) {
      handleErrorResponse(e)
    } finally {
      if (!this.createCustomerPromise!.isCancelled()) {
        this.setState({ creatingCustomer: false })
      }
    }
  }

  selectCustomer = (customer: ICustomer) => {
    this.props.onSelectCustomer(customer)
    this.resetState()
  }

  updateOrderPromise?: Bluebird<any>
  handleSelectCustomer = (customer: ICustomer) => () => {
    this.selectCustomer(customer)
  }

  searchingCustomerPromise?: Bluebird<any>
  handleSearchCustomer = async () => {
    this.setState({
      loadingCustomers: true,
      clickedSearch: true
    })
    try {
      this.searchingCustomerPromise = customerStore.searchCustomer(this.state.searchText)
      const customers = await this.searchingCustomerPromise
      this.setState({ customers })
    } catch (e) {
    } finally {
      if (!this.searchingCustomerPromise!.isCancelled()) {
        this.setState({ loadingCustomers: false })
      }
    }
  }

  debouncedSearchCustomer = debounce(this.handleSearchCustomer, 500)

  componentWillUnmount() {
    if (this.searchingCustomerPromise) this.searchingCustomerPromise.cancel()
    if (this.updateOrderPromise) this.updateOrderPromise.cancel()
    if (this.createCustomerPromise) this.createCustomerPromise.cancel()
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.focus()
      }
    })
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  render() {
    return (
      <Modal
        trigger={this.props.children}
        open={this.state.openModal}
        onOpen={this.handleOpenModal}
        onClose={this.handleCloseModal}
      >
        <Modal.Header>Sửa thông tin khách hàng</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Checkbox
              slider
              label="Tạo khách hàng mới"
              checked={this.state.newCustomer}
              onChange={this.handleToggleNewCustomer}
            />
          </Form>

          <Divider />
          {this.state.newCustomer ? (
            <Form>
              <Form.Field>
                <label>Họ và tên KH</label>
                <Input
                  ref={ref => (this.nameInput = ref)}
                  type="text"
                  value={this.state.fullName}
                  onChange={this.handleChangeField('fullName')}
                  placeholder="VD: Nguyễn Văn A"
                  icon={{
                    name: 'remove',
                    onClick: this.handleRemoveField('fullName'),
                    link: true
                  }}
                  required
                />
              </Form.Field>
              <Form.Input
                type="tel"
                value={this.state.cellphone}
                onChange={this.handleChangeField('cellphone')}
                label="Số điện thoại"
                placeholder="VD: 0917456432"
                required
              />

              <Form.Checkbox
                slider
                label="Làm thẻ khách hàng"
                checked={this.state.registerCustomerCard}
                onChange={this.handleToggleRegisterCustomerCard}
              />

              {this.state.registerCustomerCard && (
                <>
                  <Form.Field required>
                    <label>Năm sinh</label>
                    <DropdownBirthyear
                      value={this.state.birthyear}
                      onSelect={this.handleBirthyearChange}
                    />
                  </Form.Field>

                  <InputDistrict onSelect={this.handleSelectDistrict} required={true} />

                  <Form.Input
                    type="text"
                    value={this.state.street}
                    onChange={this.handleChangeField('street')}
                    label="Phố"
                    placeholder="VD: Tây Sơn"
                    required
                  />

                  <Form.Field required>
                    <label>Mã thẻ KH</label>
                    <Input
                      type="tel"
                      value={this.state.numberInCustomerId}
                      onChange={this.handleNumberInCustomerIdChange}
                      label={{ content: 'CX', basic: true }}
                      labelPosition="left"
                      placeholder="Điền 7 chữ số thẻ khách hàng vào đây. VD: 1292325"
                    />
                  </Form.Field>
                </>
              )}

              <div className="ui one buttons">
                <Form.Button
                  onClick={this.handleCreateCustomer}
                  color="green"
                  disabled={this.state.creatingCustomer}
                >
                  Tạo khách hàng
                </Form.Button>
              </div>
            </Form>
          ) : (
            <Fragment>
              <Form>
                <Form.Field>
                  <label>Tìm khách hàng</label>
                  <Input
                    type="tel"
                    value={this.state.searchText}
                    onChange={this.handleSearchTextChange}
                    ref={this.handleInputRef}
                    action={{
                      color: 'teal',
                      labelPosition: 'right',
                      icon: 'search',
                      content: 'Tìm kiếm',
                      onClick: this.handleSearchCustomer,
                      disabled: this.state.loadingCustomers
                    }}
                    placeholder="Nhập số điện thoại khách hàng hoặc số trên mã thẻ khách hàng..."
                  />
                </Form.Field>
              </Form>
              <Segment basic vertical>
                <Dimmer inverted active={this.state.loadingCustomers}>
                  <Loader />
                </Dimmer>
                {!this.state.customers.length && this.state.clickedSearch && (
                  <Header>Không tìm thấy khách hàng nào!</Header>
                )}
                {this.state.customers.length > 0 && (
                  <div>
                    <Header>Chọn 1 khách hàng</Header>
                    <Card.Group>
                      {this.state.customers.map(customer => (
                        <Card
                          onClick={this.handleSelectCustomer(customer)}
                          key={customer.customerId}
                        >
                          <Card.Content>
                            <Card.Header>{customer.fullName}</Card.Header>
                            <Card.Meta>{customer.customerId}</Card.Meta>
                            <Card.Description>
                              <p>Số điện thoại: {customer.cellphone}</p>
                            </Card.Description>
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                  </div>
                )}
              </Segment>
            </Fragment>
          )}
        </Modal.Content>
      </Modal>
    )
  }
}
