import React from 'react'

import { routes } from './routes'

import { Switch, Route } from 'react-router-dom'

export const PageHead = React.memo(() => {
  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path || 'N/A'}
          path={route.path}
          exact={route.exact}
          component={route.header}
        />
      ))}
    </Switch>
  )
})
