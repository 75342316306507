import React, { PureComponent } from 'react'
import { Provider } from 'mobx-react'
import { PageHead } from './config/PageHead'
import { modifierStore } from './model/modifier.store'

import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer } from 'react-toastify'
import { rootStore } from './model/root-store'
import { SoundNotification } from './components/SoundNotification'
import GaTracker from './components/layouts/GaTracker'
import { MainMenu } from './layout/MainMenu'
import { MainSidebar } from './layout/MainSidebar'

const App = class extends PureComponent {
  render() {
    return (
      <Provider modifierStore={modifierStore} rootStore={rootStore}>
        <div
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <PageHead />
          <ToastContainer position="top-right" />
          <MainMenu />
          <MainSidebar />
          <SoundNotification />
          <GaTracker />
        </div>
      </Provider>
    )
  }
}

export default App
