import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'
import { reaction, runInAction } from 'mobx'
import { WORK_URL } from '../config/environments'
import { RootStore } from '../model/root-store'

export class Agent {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }
  agent = axios.create()
  get<T = any>(endpointUrl: string, config?: AxiosRequestConfig): Promise<T> {
    return this.agent.get<T>(WORK_URL + endpointUrl, config).then(response => response.data)
  }
  post<T = any>(endpointUrl: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.agent.post<T>(WORK_URL + endpointUrl, data, config).then(response => response.data)
  }
  put<T = any>(endpointUrl: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.agent.put<T>(WORK_URL + endpointUrl, data, config).then(response => response.data)
  }
  delete(endpointUrl: string, config?: AxiosRequestConfig): AxiosPromise {
    return this.agent.delete(WORK_URL + endpointUrl, config).then(response => response.data)
  }
  authenticationReact = reaction(
    () => {
      return this.rootStore.authenticationStore.token
    },
    token => {
      if (token) {
        this.agent = axios.create({
          headers: { Authorization: `Bearer ${token}` }
        })
      } else {
        this.agent = axios.create()
      }
    },
    {
      fireImmediately: true
    }
  )
}
