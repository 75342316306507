import React from 'react'
import { inject, observer } from 'mobx-react'
import Downshift from 'downshift'

import { IProduct } from '../model/product.store'
import { Form, List, Segment } from 'semantic-ui-react'
import styles from './ProductInput.module.scss'
import { FinalPrice } from './FinalPrice'
import { reject } from 'lodash'

interface ProductInputProps {
  products: IProduct[]
  onSelect: (selectedProduct: IProduct) => void
}
//<ProductInput products={} onSelect={}/>
export const ProductInput = inject('rootStore')(
  observer(
    class extends React.Component<ProductInputProps> {
      render() {
        const products = reject(this.props.products, { isCombo: true })
        return (
          <Downshift onSelect={product => this.props.onSelect(product)} itemToString={() => ''}>
            {({
              getInputProps,
              getMenuProps,
              getItemProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectItem
            }) => (
              <div>
                <Form>
                  <Form.Field>
                    <label>Tìm sản phẩm</label>
                    <input
                      {...getInputProps({
                        onKeyDown: (event: any) => {
                          if (event.key === 'Enter' && inputValue) {
                            const matchProduct = products.find(
                              product =>
                                Boolean(product.sku.toLowerCase() === inputValue.toLowerCase()) ||
                                Boolean(
                                  product.barcodes &&
                                    product.barcodes.some(barcode =>
                                      Boolean(barcode && barcode.toLowerCase() === inputValue)
                                    )
                                )
                            )
                            if (matchProduct) {
                              selectItem(matchProduct)
                            }
                          }
                        },
                        onBlur: (event: any) => {
                          event.nativeEvent.preventDownshiftDefault = true
                        }
                      })}
                      placeholder="Nhập tên sản phẩm..."
                    />
                  </Form.Field>
                </Form>
                {isOpen && inputValue ? (
                  <Segment style={{ position: 'relative', zIndex: '500' }}>
                    <List {...getMenuProps()} divided size="massive">
                      {products
                        .filter(
                          item =>
                            !inputValue ||
                            item.description.toLowerCase().includes(inputValue.toLowerCase()) ||
                            (item.latinDescription &&
                              item.latinDescription
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())) ||
                            item.sku.toLowerCase() === inputValue.toLowerCase() ||
                            (item.barcodes &&
                              item.barcodes.some(barcode =>
                                Boolean(
                                  barcode && barcode.toLowerCase() === inputValue.toLowerCase()
                                )
                              ))
                        )
                        .splice(0, 10)
                        .map((item, index) => (
                          <List.Item
                            {...getItemProps({
                              key: item.description,
                              index,
                              item,
                              className: highlightedIndex === index ? styles['selected'] : ''
                            })}
                          >
                            <List.Header
                              className={`${styles['header']} ${
                                highlightedIndex === index ? styles['selected'] : ''
                              }`}
                            >
                              <small style={{ fontSize: '0.5em' }}>{item.sku}</small>{' '}
                              {item.description}
                            </List.Header>
                            {!!(item.normalPrice || item.salePrice) && (
                              <List.Description style={{ textAlign: 'right' }}>
                                <FinalPrice
                                  normalPrice={item.normalPrice}
                                  salePrice={item.salePrice}
                                />
                              </List.Description>
                            )}
                          </List.Item>
                        ))}
                    </List>
                  </Segment>
                ) : null}
              </div>
            )}
          </Downshift>
        )
      }
    }
  )
)
