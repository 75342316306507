import React from 'react'
import { Button } from 'semantic-ui-react'
import { IOrder } from '../../model/list-order.store'
import { inject, observer } from 'mobx-react'
import { RootStore } from '../../model/root-store'

interface PrintSaleEntryButtonProps {
  rootStore?: RootStore
  order: IOrder
  saleEntryId: string
}

export const PrintSaleEntryButton = inject('rootStore')(
  observer(
    class extends React.Component<PrintSaleEntryButtonProps> {
      handleClickPrintSaleEntry = () => {
        this.props.rootStore!.listSaleEntryStore.printSaleEntry(
          this.props.order,
          this.props.saleEntryId
        )
      }

      render() {
        return (
          <Button color="blue" onClick={this.handleClickPrintSaleEntry}>
            In Hóa Đơn
          </Button>
        )
      }
    }
  )
)
