import { observable, decorate, action, runInAction, toJS } from 'mobx'
import { startOfDay, endOfDay } from 'date-fns'
import _ from 'lodash'
import { IOrder } from './list-order.store'
import { RootStore } from './root-store'
import { handleAgentErrorResponse } from '../libs/error-handler'
import { ICustomer } from './customer.model'
import { getObjectId } from '../libs/object-id'

export interface ISaleEntry {
  _id: string
  saleEntryId: string
  originalOrder: IOrder
  customer?: ICustomer
  referrer: string
  voucherToken?: string
  payments: any
  total: number
  done: Date | null
  created: Date | null
  serviceMode: string
  saleEntryType: string
  receivedMoney: number
  orderNumber: number
  customerPoints: number
  order: any
  // business?: string
  // status: string
  // cashier: string
  // supporter?: string
  // outlet: string
  // sellingOutlet?: string
  // seller?: string
  // shift: string
  // order: {
  //   items: any[]
  // }
  // returnSaleEntry?: string
  // survey?: string
  // voucher?: string
  // customerDeals?: string[]
  // referrerMethod?: string
  // surveyAnswer?: object
  // saleProgram?: string
  // coupon?: string
  // shipping?: any
  // partnership?: any
  // paymentType: string
  // cashReceived?: number
  // onlineOrder?: string
  // removedOnlineOrder?: string
  // originalSaleEntry?: string
  // contraSaleEntries?: string
  // date: Date | null
  // updated: Date | null
  // updatedBy?: string
  // deleted?: Date
  // deletedBy?: string
  // deletedNote?: string
  // user?: string
  // transactions?: any
}

export class ListSaleEntryStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  saleEntries: ISaleEntry[] = []
  loading: boolean = false

  loadSaleEntries(saleEntries: ISaleEntry[]) {
    this.saleEntries = saleEntries
  }

  async listSaleEntries() {
    try {
      this.setLoading(true)

      const shift = this.rootStore!.currentShiftStore.currentShift!.shift
      const outletId = getObjectId(this.rootStore!.currentShiftStore.currentShift!.outlet)

      const saleEntries: ISaleEntry[] = await this.rootStore!.agent.get('/sale-entries', {
        params: {
          shift,
          outlet: outletId,
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
          businesses: '5ac9a4186fe594434d13a8d0'
        }
      })
      const rejectAbbySE = _.reject(saleEntries, saleEntry => {
        const orderItems = _.get(saleEntry.order, 'items', [])
        return _.some(orderItems, item => _.isEqual(item.business, '5710bb19731ce0c4ebf685dd'))
      })
      this.loadSaleEntries(rejectAbbySE)
      this.setLoading(false)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }

  async searchSaleEntries(startDate: Date, endDate: Date, shift: string, outletId: string) {
    try {
      this.loadSaleEntries([])
      this.setLoading(true)
      const saleEntries: ISaleEntry[] = await this.rootStore!.agent.get('/sale-entries', {
        params: {
          shift,
          outlet: outletId,
          startDate,
          endDate,
          businesses: '5ac9a4186fe594434d13a8d0',
          status: 'closed'
        }
      })
      const rejectAbbySE = _.reject(saleEntries, saleEntry => {
        const orderItems = _.get(saleEntry.order, 'items', [])
        return _.some(orderItems, item => _.isEqual(item.business, '5710bb19731ce0c4ebf685dd'))
      })
      this.loadSaleEntries(rejectAbbySE)
      this.setLoading(false)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }

  setLoading(state: boolean) {
    this.loading = state
  }

  async printSaleEntry(order: IOrder, saleEntryId: string) {
    try {
      const response = await this.rootStore!.agent.post('/sale-entries/print', {
        saleEntryId
      })

      this.rootStore!.orderStore.setOrderAndSaleEntryPrinting(order, response.saleEntry)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }
}

decorate(ListSaleEntryStore, {
  saleEntries: observable,
  loading: observable,
  loadSaleEntries: action,
  printSaleEntry: action,
  setLoading: action
})
