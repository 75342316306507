import React from 'react'
import { sortBy } from 'lodash'

import { IProduct } from '../../model/product.store'
import ReactTable from 'react-table'
import { StockProduct } from '../../components/ToggleProductStatusesInput'
import { List, Checkbox } from 'semantic-ui-react'
import { cleanAccents } from '../../libs/clean-accents'

interface ListDishStatusesProps {
  products: IProduct[]
  onToggle: (product: StockProduct, inStock: boolean) => void
  onEvaluatePriorityProduct: (product: StockProduct) => StockProduct
}

export const ListDishStatuses: React.SFC<ListDishStatusesProps> = React.memo(
  ({ products, onToggle, onEvaluatePriorityProduct }) => {
    const columns = [
      {
        Header: 'Sku',
        id: 'sku',
        accessor: (product: StockProduct) => {
          if (product.inStock) {
            return product.sku
          }

          return <s>{product.sku}</s>
        },
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        maxWidth: 200,
        filterMethod: (filter: any, row: any) => {
          const sku = row.sku.props ? row.sku.props.children.toLowerCase() : row.sku.toLowerCase()
          if (sku.indexOf(filter.value.toLowerCase()) >= 0) {
            return true
          }
          return false
        }
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: (product: StockProduct) => {
          if (product.inStock) {
            return product.description
          }

          return <s>{product.description}</s>
        },
        maxWidth: 300,
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        filterMethod: (filter: any, row: any) => {
          const description = row.description.props
            ? row.description.props.children.toLowerCase()
            : row.description.toLowerCase()

          if (
            cleanAccents(description).includes(cleanAccents(filter.value.toLowerCase())) ||
            description.includes(filter.value.toLowerCase())
          ) {
            return true
          }
          return false
        }
      },
      {
        Header: 'Category',
        id: 'savorTag',
        accessor: (product: StockProduct) => {
          if (product.inStock) {
            return product.savorTag
          }

          return <s>{product.savorTag}</s>
        },
        maxWidth: 300,
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        filterMethod: (filter: any, row: any) => {
          let savorTag
          if (row.savorTag && row.savorTag.props) {
            row.savorTag.props.children
              ? (savorTag = row.savorTag.props.children.toLowerCase())
              : (savorTag = '')
          } else {
            savorTag = row.savorTag ? row.savorTag.toLowerCase() : ''
          }

          if (
            cleanAccents(savorTag).includes(cleanAccents(filter.value.toLowerCase())) ||
            savorTag.includes(filter.value.toLowerCase())
          ) {
            return true
          }
          return false
        }
      },
      {
        Header: 'Thành phần',
        id: 'bundledProducts',
        accessor: (product: StockProduct) => {
          if (!product.bundledProducts || product.bundledProducts.length === 0) return

          return (
            <List>
              {product.bundledProducts.map((bundledProduct: any) => (
                <List.Item>
                  <List.Icon>
                    <Checkbox
                      key={bundledProduct.sku}
                      toggle
                      checked={bundledProduct.inStock}
                      onClick={() => onToggle(bundledProduct, !bundledProduct.inStock)}
                    />
                  </List.Icon>

                  <List.Content>
                    {bundledProduct.inStock ? (
                      `${bundledProduct.sku} ${bundledProduct.description}`
                    ) : (
                      <s>
                        {bundledProduct.sku} {bundledProduct.description}
                      </s>
                    )}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          )
        },
        style: { textAlign: 'left' },
        filterable: false
      }
    ]

    const finalProducts = sortBy(
      products.map((pro: IProduct) => {
        return onEvaluatePriorityProduct(pro as StockProduct)
      }),
      ['priority']
    )

    return (
      <ReactTable
        data={finalProducts}
        columns={columns}
        filterable
        defaultPageSize={10}
        minRows={10}
        className="-highlight -striped"
        noDataText={!products.length && 'Không có sản phẩm'}
      />
    )
  }
)
