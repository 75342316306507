import React from 'react'
import { Label, Icon } from 'semantic-ui-react'
import { TCustomerCardStage } from '../model/order.store'

export function LabelCustomerCardStatus({
  customerCardStage,
  ...rest
}: {
  customerCardStage: TCustomerCardStage
}) {
  switch (customerCardStage) {
    case 'no customer':
      return <Label color="blue">Chưa lưu SĐT</Label>
    case 'not registered':
      return (
        <Label color="red" {...rest}>
          Chưa đăng ký thẻ <Label.Detail>Bổ sung</Label.Detail>
        </Label>
      )
    case 'not activated':
      return (
        <Label color="yellow" {...rest}>
          Chưa kích hoạt
          <Label.Detail>
            <Icon name="pencil" />
          </Label.Detail>
        </Label>
      )
    case 'activated':
      return (
        <Label color="green" {...rest}>
          Đã kích hoạt
          <Label.Detail>
            <Icon name="pencil" />
          </Label.Detail>
        </Label>
      )
    default:
      return <Label color="black">N/A</Label>
  }
}
