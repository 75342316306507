import { decorate, observable, action, computed, reaction, runInAction } from 'mobx'
import { handleAgentErrorResponse } from '../libs/error-handler'
import { RootStore } from './root-store'
export interface IOutlet {
  _id: string
  name: string
  code: string
  isStore: boolean
  sellBirthdayCake: boolean
  momoStoreId: string
}

export class OutletListStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  outlets: IOutlet[] = []

  get savorStoreOutlets() {
    return this.outlets.filter(
      outlet => (outlet.isStore && outlet.code.match(/^[SK]\d+/)) || outlet.sellBirthdayCake
    )
  }
  loadOutlets(outlets: IOutlet[]) {
    this.outlets = outlets
  }

  findOutletById(outletId: string) {
    return this.outlets.find(outlet => {
      return outletId === outlet._id
    })
  }

  getMomoStoreId(outletId: string) {
    const outlet = this.outlets.find(outlet => {
      return outletId === outlet._id
    })
    return outlet ? outlet.momoStoreId : ''
  }

  async fetchOutlets() {
    try {
      const outlets: IOutlet[] = await this.rootStore.agent.get('/outlets')
      this.loadOutlets(outlets)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }

  authenticationReaction = reaction(
    () => {
      return this.rootStore.authenticationStore.token
    },
    () => {
      this.fetchOutlets()
    },
    {
      fireImmediately: true
    }
  )
}

decorate(OutletListStore, {
  outlets: observable,
  savorStoreOutlets: computed,
  loadOutlets: action
})
