import { TVoucherType } from './customer.model'
import { TOrderPlatform } from './order.store'

export interface IPartnerDiscount {
  orderPlatform: TOrderPlatform
  code: TVoucherType
  name?: string
  rate?: number
  percentValue?: number
  maxValue?: number
  amount?: number
  minValue: number
  start?: string
  end?: string
}

export const partnerDiscounts: IPartnerDiscount[] = [
  // Baemin
  {
    code: 'HANOIPA1',
    orderPlatform: 'BAEMIN',
    amount: 15000,
    minValue: 49000
  },
  {
    code: 'HANOIPA2',
    orderPlatform: 'BAEMIN',
    amount: 18000,
    minValue: 59000
  },
  {
    code: 'HANOIPA3',
    orderPlatform: 'BAEMIN',
    amount: 22000,
    minValue: 79000
  },
  {
    code: 'HANOIPA4',
    orderPlatform: 'BAEMIN',
    amount: 26000,
    minValue: 99000
  },
  {
    code: 'HANOIPA5',
    orderPlatform: 'BAEMIN',
    amount: 30000,
    minValue: 119000
  },
  {
    code: 'HANOIPA6',
    orderPlatform: 'BAEMIN',
    amount: 35000,
    minValue: 149000
  },
  {
    code: 'HANOIPA7',
    orderPlatform: 'BAEMIN',
    amount: 40000,
    minValue: 199000
  },
  {
    code: 'HANOIPA8',
    orderPlatform: 'BAEMIN',
    amount: 50000,
    minValue: 249000
  },
  // Be food
  {
    code: 'BE150',
    orderPlatform: 'BE_FOOD',
    amount: 7500,
    minValue: 50000
  },
  {
    code: 'BE250',
    orderPlatform: 'BE_FOOD',
    amount: 10000,
    minValue: 80000
  },
  {
    code: 'BE200',
    orderPlatform: 'BE_FOOD',
    amount: 10000,
    minValue: 50000
  },
  {
    code: 'BE500',
    orderPlatform: 'BE_FOOD',
    amount: 25000,
    minValue: 119000
  },
  // Gojek
  {
    code: 'CART_OFF_100K',
    orderPlatform: 'GO_FOOD',
    amount: 22750,
    minValue: 100000
  },
  {
    code: 'CART_OFF_120K',
    orderPlatform: 'GO_FOOD',
    amount: 26000,
    minValue: 120000
  },
  {
    code: 'CART_OFF_150K',
    orderPlatform: 'GO_FOOD',
    amount: 32500,
    minValue: 150000
  },
  {
    code: 'CART_OFF_170K',
    orderPlatform: 'GO_FOOD',
    amount: 39000,
    minValue: 170000
  },
  {
    code: 'CART_OFF_65K',
    orderPlatform: 'GO_FOOD',
    amount: 13000,
    minValue: 65000
  },
  {
    code: 'CART_OFF_85K',
    orderPlatform: 'GO_FOOD',
    amount: 19500,
    minValue: 85000
  },
  {
    code: 'CART_OFF_50K',
    orderPlatform: 'GO_FOOD',
    amount: 6500,
    minValue: 50000
  },
  // Grab
  {
    code: 'BOSS20',
    orderPlatform: 'GRAB_FOOD',
    amount: 20000,
    minValue: 40000
  },
  {
    code: 'BOSS30B',
    orderPlatform: 'GRAB_FOOD',
    amount: 30000,
    minValue: 80000
  },
  {
    code: 'BOSS40',
    orderPlatform: 'GRAB_FOOD',
    amount: 40000,
    minValue: 100000
  },
  {
    code: 'BOSS50',
    orderPlatform: 'GRAB_FOOD',
    amount: 50000,
    minValue: 150000
  },
  {
    code: 'BOSS70',
    orderPlatform: 'GRAB_FOOD',
    amount: 70000,
    minValue: 200000
  },
  // Shopee food
  {
    code: 'SIEUTIEC10',
    orderPlatform: 'NOW',
    amount: 5000,
    minValue: 20000
  },
  {
    code: 'SIEUTIEC20',
    orderPlatform: 'NOW',
    amount: 11000,
    minValue: 50000
  },
  {
    code: 'SIEUTIEC30',
    orderPlatform: 'NOW',
    amount: 15000,
    minValue: 70000
  },
  {
    code: 'SIEUTIEC40',
    orderPlatform: 'NOW',
    amount: 20000,
    minValue: 100000
  },
  {
    code: 'SIEUTIEC50',
    orderPlatform: 'NOW',
    amount: 26000,
    minValue: 140000
  },
  {
    code: 'SIEUTIEC50K',
    orderPlatform: 'NOW',
    amount: 26000,
    minValue: 160000
  },
  {
    code: 'SIEUTIEC70',
    orderPlatform: 'NOW',
    amount: 32000,
    minValue: 200000
  },
  {
    code: 'SIEUTIEC30K',
    orderPlatform: 'NOW',
    amount: 15000,
    minValue: 70000
  },
  {
    code: 'SIEUTIEC40K',
    orderPlatform: 'NOW',
    amount: 20000,
    minValue: 100000
  },
  {
    code: 'SIEUTIEC99',
    orderPlatform: 'NOW',
    amount: 45000,
    minValue: 250000
  },
  {
    code: 'SIEUTIEC35K',
    orderPlatform: 'NOW',
    amount: 17500,
    minValue: 119000
  },
  {
    code: 'SIEUTIEC45K',
    orderPlatform: 'NOW',
    amount: 22500,
    minValue: 149000
  },
  {
    code: 'SIEUTIEC75K',
    orderPlatform: 'NOW',
    amount: 37500,
    minValue: 249000
  },
  {
    code: 'SIEUTIEC109K',
    orderPlatform: 'NOW',
    amount: 54500,
    minValue: 369000
  },
  {
    code: 'SIEUTIEC139K',
    orderPlatform: 'NOW',
    amount: 69500,
    minValue: 469000
  },
  {
    code: 'SIEUTIEC199K',
    orderPlatform: 'NOW',
    amount: 99500,
    minValue: 669000
  },
  ///
  {
    code: '1111SIEUDEAL45',
    orderPlatform: 'NOW',
    rate: 0.5,
    percentValue: 0.25,
    maxValue: 45000,
    minValue: 0,
    start: '2023-11-01T00:00:00+07:00',
    end: '2023-11-12T00:00:00+07:00'
  },
  {
    code: '1111SIEUDEAL35',
    orderPlatform: 'NOW',
    rate: 0.5,
    percentValue: 0.3,
    maxValue: 35000,
    minValue: 0,
    start: '2023-11-01T00:00:00+07:00',
    end: '2023-11-12T00:00:00+07:00'
  },
  {
    code: '1111SIEUDEAL110',
    orderPlatform: 'NOW',
    rate: 0.5,
    percentValue: 0.3,
    maxValue: 111000,
    minValue: 100000,
    start: '2023-11-01T00:00:00+07:00',
    end: '2023-11-12T00:00:00+07:00'
  }
]
