import React, { Fragment } from 'react'
import { Card, Button } from 'semantic-ui-react'
import { DoneOrderbutton } from './DoneOrderButton'
import { OrderItem } from '../../components/OrderItem'
import { ModalCancelOrder } from './ModalCancelOrder'
import { FormattedCurrency } from '../../components/FormattedCurrency'
import styles from './OrderCard.module.scss'
import { DiscountItems } from '../../components/DiscountItems'
import { OrderStore } from '../../model/order.store'
import { ModalSelectCustomer } from '../../components/ModalSelectCustomer'
import { ModalChangePartnership } from '../../components/ModalChangePartnership'
import { ICustomer } from '../../model/customer.model'
import tableStyles from '../../scss/table.module.scss'
import { observer, inject } from 'mobx-react'
import { ModalEditOrderItem } from './ModalEditOrderItem'
import { ModalPayment } from '../CreateOrderView/CurrentOrder/ModalPayment'
import { format } from 'date-fns'
import { RootStore } from '../../model/root-store'

interface OrderCardProps {
  order: OrderStore
  rootStore?: RootStore
}

export const OrderCard = inject('rootStore')(
  observer(function ({ rootStore, order, ...rest }: OrderCardProps) {
    const onSelectCustomer = async (customer: ICustomer) => {
      order.setCustomer(customer)
    }

    const renderShippingInformation = () => {
      if (
        order.shippingCustomerName ||
        order.shippingCustomerPhone ||
        order.shippingCustomerAddress
      ) {
        return (
          <>
            <strong>
              Thông tin Ship : {order.shippingCustomerName && order.shippingCustomerName}
              {order.shippingCustomerPhone && ' - ' + order.shippingCustomerPhone}
              {order.shippingCustomerAddress && ' - ' + order.shippingCustomerAddress}
            </strong>
          </>
        )
      }
      return null
    }

    return (
      <Card {...rest}>
        <Card.Content>
          <Card.Header>
            #{order.orderId}
            <Button.Group>
              <ModalSelectCustomer onSelectCustomer={onSelectCustomer}>
                <Button basic size="mini">
                  Thẻ KH
                </Button>
              </ModalSelectCustomer>
              <ModalChangePartnership order={order}>
                <Button basic size="mini">
                  Đối tác
                </Button>
              </ModalChangePartnership>
            </Button.Group>
            <ModalCancelOrder order={order}>
              <Button basic color="red" floated="right">
                Hủy đơn
              </Button>
            </ModalCancelOrder>
          </Card.Header>
          {order.customer && (
            <>
              <strong>
                Tích điểm : {order.customer.fullName} - {order.customer.cellphone}
              </strong>
              <br />
            </>
          )}
          <strong>
            {order.orderPlatform} - {order.ordered && format(order.ordered, 'HH:mm - DD/MM')}
          </strong>
          <br />
          {renderShippingInformation()}
          <Card.Description>
            <table
              style={{
                width: '100%'
              }}
            >
              <tbody>
                {order.computedItems.map(item => (
                  <ModalEditOrderItem
                    key={item.id}
                    itemId={item.id}
                    order={order}
                    trigger={<OrderItem item={item} order={order} />}
                  />
                ))}
                {order.discounts.length > 0 && <DiscountItems discounts={order.discounts} />}
                {(order.orderType === 'SHIP' ||
                  order.shippingCollect > 0 ||
                  order.shippingPay > 0) && (
                  <Fragment>
                    <tr className={`${tableStyles['top-bordered']} ${tableStyles['header-row']}`}>
                      <td className={styles['discount-description']}>Phí ship thu KH</td>
                      <td className={tableStyles['quantity-cell']}>
                        <FormattedCurrency value={order.shippingCollect} />
                      </td>
                    </tr>
                    <tr className={`${tableStyles['top-bordered']}`}>
                      <td className={styles['discount-description']}>Phí ship thực tế</td>
                      <td className={tableStyles['quantity-cell']}>
                        -<FormattedCurrency value={order.shippingPay} />
                      </td>
                    </tr>
                  </Fragment>
                )}
                {order.commissionAmount > 0 && (
                  <tr className={`${tableStyles['top-bordered']}`}>
                    <td className={styles['discount-description']}>Chiết khấu đối tác</td>
                    <td className={tableStyles['quantity-cell']}>
                      -<FormattedCurrency value={order.commissionAmount} />
                    </td>
                  </tr>
                )}
                {order.deposit > 0 && (
                  <tr className={`${tableStyles['top-bordered']}`}>
                    <td className={styles['discount-description']}>Trả trước</td>
                    <td className={tableStyles['quantity-cell']}>
                      -<FormattedCurrency value={order.deposit} />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th className={styles.bordered}>Phải thu</th>
                  <td className={styles.bordered}>
                    <FormattedCurrency value={order.computedReceiveAmount}>
                      {(computedReceiveAmount: string) => (
                        <span className={styles['footer-number']}>{computedReceiveAmount}</span>
                      )}
                    </FormattedCurrency>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Card.Description>
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <ModalPayment
              orderStore={order}
              buttonActions={[<DoneOrderbutton key="done" order={order} />]}
            >
              <button className="ui positive button">Done</button>
            </ModalPayment>
          </div>
        </Card.Content>
      </Card>
    )
  })
)
