import React, { Component } from 'react'

import { Input, Button, Header } from 'semantic-ui-react'

interface QuantityChangerProps {
  onQuantityChanged: any
  quantity: number
  label?: string
  [key: string]: any
}

export class QuantityChanger extends Component<QuantityChangerProps> {
  render() {
    const { onQuantityChanged, quantity, label, saleCode = '', ...rest } = this.props
    return (
      <div
        {...rest}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Header
          style={{
            marginRight: 10,
            marginBottom: 0
          }}
        >
          {this.props.label || 'SL: '}
        </Header>
        <Button
          onClick={() => this.props.onQuantityChanged(this.props.quantity - 1)}
          icon="minus"
          style={{ marginRight: 0 }}
          disabled={!!saleCode}
        />
        <Input
          type="number"
          value={this.props.quantity}
          style={{ maxWidth: 80 }}
          onChange={(e, { value }) => this.props.onQuantityChanged(value)}
          disabled={!!saleCode}
        />
        <Button
          onClick={() => this.props.onQuantityChanged(this.props.quantity + 1)}
          icon="add"
          disabled={!!saleCode}
        />
      </div>
    )
  }
}

export default QuantityChanger
