import { TPaymentType, TOrderPlatform } from '../model/order.store'

const PAYMENT_TYPES: TPaymentType[] = [
  'CASH',
  'TRANSFER',
  'MOMO',
  'ZALOPAY',
  'AIRPAY',
  'GRABPAY',
  'VNPAY',
  'VINID',
  'BAEMIN',
  'GO_JEK',
  'BE_FOOD',
  'LALAMOVE',
  'CARD'
]

export function isPaymentTypeAvailable(orderPlatform: TOrderPlatform, paymentType: TPaymentType) {
  switch (paymentType) {
    case 'CASH':
      if (orderPlatform === 'SAVOR_SHOP' || orderPlatform === 'LOSHIP') return true
      return false
    case 'CARD':
    case 'TRANSFER':
    case 'ZALOPAY':
      if (orderPlatform === 'SAVOR_SHOP') return true
      return false
    case 'VINID':
      return false
    case 'MOMO':
      return false
    case 'GRABPAY':
      if (orderPlatform === 'GRAB_FOOD') return true
      return false
    case 'AIRPAY':
      if (orderPlatform === 'NOW') return true
      return false
    case 'VNPAY':
      return false
    case 'BE_FOOD':
      if (orderPlatform === 'BE_FOOD') return true
      return false
    case 'LOSHIP':
      if (orderPlatform === 'LOSHIP') return true
      return false
    case 'GO_JEK':
      if (orderPlatform === 'GO_FOOD') return true
      return false
    default:
      return false
  }
}

export function getAvailablePaymentTypes(orderPlatform: TOrderPlatform) {
  return PAYMENT_TYPES.filter(paymentType => isPaymentTypeAvailable(orderPlatform, paymentType))
}
