import React from 'react'
import Downshift from 'downshift'
import { Segment, List, Input } from 'semantic-ui-react'
import { IOutlet } from '../model/outlet-list.store'
import { observer, inject } from 'mobx-react'
import { RootStore } from '../model/root-store'

interface InputOutletProps {
  onSelect: (item: any, state?: any) => void
  initialSelectedItem?: IOutlet
  initialValue?: string | IOutlet
  required?: boolean
  rootStore?: RootStore
  savorOnly?: boolean
  disabled?: boolean
}

function instanceOfOutlet(value: string | IOutlet): value is IOutlet {
  return !!(value as IOutlet)._id
}

const itemToString = (outlet: IOutlet | null) => {
  if (!outlet) return ''
  return `${outlet.code} - ${outlet.name}`
}

export const InputOutlet = inject('rootStore')(
  observer(function ({
    onSelect,
    required,
    initialSelectedItem,
    initialValue,
    rootStore,
    savorOnly = true,
    disabled
  }: InputOutletProps) {
    const outletListStore = rootStore!.outletListStore
    let outlet: IOutlet | undefined
    if (initialValue) {
      outlet = outletListStore.outlets.find(outlet =>
        instanceOfOutlet(initialValue)
          ? initialValue._id === outlet._id
          : initialValue === outlet._id
      )
    }
    let outlets = savorOnly ? outletListStore.savorStoreOutlets : outletListStore.outlets
    return (
      <Downshift
        onChange={onSelect}
        itemToString={itemToString}
        initialSelectedItem={outlet || initialSelectedItem}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex,
          clearSelection,
          selectedItem,
          toggleMenu
        }) => (
          <div className={`field ${required && 'required'}`}>
            <label>Cơ sở</label>
            <Input
              {...getInputProps()}
              placeholder="Nhập tên cơ sở..."
              icon={
                selectedItem
                  ? {
                      name: 'remove',
                      onClick: clearSelection,
                      link: true
                    }
                  : {
                      name: isOpen ? 'angle up' : 'angle down',
                      onClick: toggleMenu,
                      link: true,
                      'data-testid': 'toggle-outlets'
                    }
              }
              disabled={disabled}
            />
            {isOpen ? (
              <Segment style={{ position: 'relative', zIndex: '500' }}>
                <List {...getMenuProps()} divided size="massive">
                  {outlets
                    .filter(
                      item =>
                        !inputValue ||
                        item.code.toLowerCase().includes(inputValue.toLowerCase()) ||
                        item.name.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .splice(0, 10)
                    .map((item, index) => (
                      <List.Item
                        {...getItemProps({
                          key: item.code,
                          index,
                          item,
                          style: {
                            fontWeight: highlightedIndex === index ? 'bold' : 'normal'
                          }
                        })}
                      >
                        {item.code} - {item.name}
                      </List.Item>
                    ))}
                </List>
              </Segment>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  })
)
