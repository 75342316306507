import React from 'react'
import Downshift from 'downshift'
import { Segment, List, Input } from 'semantic-ui-react'
import { observer } from 'mobx-react'

interface InputNowOrderProps {
  onSelect?: (item: any, state?: any) => void
  nowOrders: any
  required?: boolean
  disabled?: boolean
}

const itemToString = (order: any | null) => {
  if (!order) return ''
  return `${order.code}`
}

export const InputNowOrder = observer(function({
  onSelect,
  required,
  disabled,
  nowOrders
}: InputNowOrderProps) {
  return (
    <Downshift onChange={onSelect} itemToString={itemToString} initialIsOpen>
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
        clearSelection,
        selectedItem,
        toggleMenu
      }) => (
        <div className={`field ${required && 'required'}`}>
          <Input
            {...getInputProps()}
            placeholder="Nhập mã now order ..."
            icon={
              selectedItem
                ? {
                    name: 'remove',
                    onClick: clearSelection,
                    link: true
                  }
                : {
                    name: isOpen ? 'angle up' : 'angle down',
                    onClick: toggleMenu,
                    link: true
                  }
            }
            disabled={disabled}
          />
          {isOpen ? (
            <Segment style={{ position: 'relative', zIndex: '500' }}>
              <List {...getMenuProps()} divided size="massive">
                {nowOrders
                  .filter(
                    (item: any) =>
                      !inputValue || item.code.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .splice(0, 10)
                  .map((item: any, index: number) => (
                    <List.Item
                      {...getItemProps({
                        key: item.code,
                        index,
                        item,
                        style: {
                          fontWeight: highlightedIndex === index ? 'bold' : 'normal'
                        }
                      })}
                    >
                      {item.code} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {item.deliver_address.orderer_phone}
                      <span style={{ float: 'right' }}>{item.total_value_detail.text}</span>
                    </List.Item>
                  ))}
              </List>
            </Segment>
          ) : null}
        </div>
      )}
    </Downshift>
  )
})
