import React, { SyntheticEvent } from 'react'
import { Modal, Form, InputProps, Input, Button, Dimmer, Loader } from 'semantic-ui-react'

import { range } from 'lodash'
import { ICustomer, customerStore } from '../model/customer.model'
import { toast } from 'react-toastify'

import request from 'superagent'

import { DropdownBirthyear } from './DropdownBirthyear'
import { InputDistrict } from './InputDistrict'
import Districts, { IDistrict } from '../model/district.store'
import { handleErrorResponse } from '../libs/error-handler'
import { getYear } from 'date-fns'
import { WORK_URL } from '../config/environments'
import { RootStore } from '../model/root-store'
import { inject, observer } from 'mobx-react'

const options = range(1930, 2011).map(year => {
  return { key: year, value: year, text: `${year}` }
})

options.push({ key: 0, value: 0, text: '=== Chưa chọn ===' })

interface IModalEditCustomerProps {
  customer: ICustomer | null
  rootStore?: RootStore
}

type TFieldKey =
  | 'fullName'
  | 'cellphone'
  | 'birthyear'
  | 'numberInCustomerId'
  | 'customerId'
  | 'street'
  | 'district'

interface IModalEditCustomerState extends ICustomer {
  loadingCustomer: boolean
  updatingCustomer: boolean
  openModal: boolean
  fullName: string
  cellphone: string
  searchCustomerType: string
  birthyear: string
  district: IDistrict | null
  districtLabel: string
  street: string
  numberInCustomerId: string
  customerId: string
  customers: ICustomer[]
  registerCustomerCard: boolean
  [key: string]: any
}

export const ModalEditCustomer = inject('rootStore')(
  observer(
    class extends React.Component<IModalEditCustomerProps, IModalEditCustomerState> {
      constructor(props: IModalEditCustomerProps) {
        super(props)
        this.state = this.initialState
      }

      loadCustomer = () => {
        if (this.props.customer) {
          this.setState({ loadingCustomer: true })
          request
            .get(WORK_URL + '/api_savor/customers/' + this.props.customer._id)
            .then(response => {
              const myCustomer = response.body as ICustomer
              const customerId =
                myCustomer && myCustomer.customerId.match(/^C/) ? myCustomer.customerId : ''
              let districtLabel =
                myCustomer && myCustomer.address
                  ? `${myCustomer.address.district}, ${myCustomer.address.city}`
                  : ''
              let district: IDistrict | null =
                Districts.getDistricts().find(district => district.label.includes(districtLabel)) ||
                null

              if (district) districtLabel = district.label

              this.setState({
                ...myCustomer,
                district,
                districtLabel,
                searchCustomerType: '',
                numberInCustomerId: customerId.replace('CX', ''),
                birthyear:
                  myCustomer && myCustomer.birthday
                    ? `${getYear(new Date(myCustomer.birthday))}`
                    : '',
                street: myCustomer && myCustomer.address ? myCustomer.address.street || '' : '',
                openModal: this.state.openModal,
                registerCustomerCard:
                  myCustomer && myCustomer.customerId.match(/^C/) ? true : false,
                loadingCustomer: false,
                customerId
              })
            })
            .catch(e => {
              handleErrorResponse(e)
              this.setState({ loadingCustomer: false })
            })
        }
      }

      initialState: IModalEditCustomerState = {
        loadingCustomer: false,
        _id: '',
        openModal: false,
        fullName: 'Khách Hàng',
        cellphone: '',
        birthyear: '',
        district: null,
        districtLabel: '',
        street: '',
        searchCustomerType: 'cellphone',
        numberInCustomerId: '',
        customerId: '',
        customers: [],
        registerCustomerCard: true,
        updatingCustomer: false
      }

      nameInput: Input | null = null

      resetState = () => {
        this.setState(this.initialState)
      }

      handleToggleRegisterCustomerCard = () => {
        if (
          this.props.customer &&
          this.props.customer.customerId &&
          this.props.customer.customerId.match(/^C/) &&
          this.state.registerCustomerCard
        ) {
          toast.error('Không thể hủy đăng ký khách hàng.')
          return
        }
        this.setState(({ registerCustomerCard }) => ({
          registerCustomerCard: !registerCustomerCard
        }))
      }

      handleChangeField =
        (fieldName: TFieldKey) =>
        (e: SyntheticEvent, { value }: InputProps) => {
          this.setState({ [fieldName]: value })
        }

      handleRemoveField = (fieldName: TFieldKey) => () => {
        this.setState({ [fieldName]: '' })
        if (fieldName === 'fullName') {
          setTimeout(() => {
            if (this.nameInput) this.nameInput.focus()
          })
        }
      }

      handleBirthyearChange = (birthyear: string) => {
        this.setState({ birthyear: birthyear })
      }

      handleSelectDistrict = (district: IDistrict) => {
        if (!district) {
          this.setState({
            districtLabel: '',
            district: null
          })
          return
        }
        this.setState({
          districtLabel: district.label,
          district: district
        })
      }

      handleNumberInCustomerIdChange = (e: SyntheticEvent, { value }: InputProps) => {
        this.setState({ numberInCustomerId: value, customerId: `CX${value}` })
      }

      handleUpdateCustomer = async () => {
        if (!this.state.fullName) {
          toast.error('Chưa điền tên khách hàng')
          return
        }
        if (!this.state.cellphone) {
          toast.error('Chưa điền số điện thoại khách hàng')
          return
        }

        if (this.state.registerCustomerCard) {
          if (this.state.fullName === 'Khách Hàng') {
            toast.error('Chưa có họ và tên khách hàng')
            return
          }
          if (this.state.fullName && this.state.fullName.split(/\s+/).length <= 1) {
            toast.error('Chưa có đủ họ và tên khách hàng')
            return
          }
          if (!this.state.district || !this.state.district.city) {
            toast.error('Chưa có thông tin tỉnh/thành phố')
            return
          }
          if (!this.state.district || !this.state.district.district) {
            toast.error('Chưa có thông tin quận/huyện')
            return
          }
          if (!this.state.birthyear) {
            toast.error('Chưa có thông tin năm sinh')
            return
          }
          if (!this.state.customerId) {
            toast.error('Chưa có số thẻ khách hàng')
            return
          }
          if (this.state.customerId && !this.state.customerId.match(/^C[XY]\d{7}$/)) {
            toast.error('Mã thẻ khách hàng không hợp lệ')
            return
          }
        }

        const customer: ICustomer = {
          _id: this.state._id,
          fullName: this.state.fullName,
          cellphone: this.state.cellphone,
          searchCustomerType: this.state.searchCustomerType,
          customerId: this.state.cellphone
        }

        if (this.state.registerCustomerCard) {
          customer.customerId = this.state.customerId
          customer.address = {
            district: this.state.district ? this.state.district.district : '',
            city: this.state.district ? this.state.district.city : '',
            street: this.state.street
          }
          customer.birthday = new Date(parseInt(this.state.birthyear), 0, 1)
          customer.registered = true
          customer.registerTime = new Date()
        }

        this.setState({ updatingCustomer: true })
        try {
          await customerStore.updateCustomer(customer)
          this.props.rootStore!.orderStore.setCustomer(customer)
          this.resetState()
        } catch (e) {
          this.setState({ updatingCustomer: false })
          handleErrorResponse(e)
        }
      }

      handleEditCustomer = async () => {
        if (!this.state.fullName) {
          toast.error('Chưa điền tên khách hàng')
          return
        }
        if (this.state.fullName === 'Khách Hàng') {
          toast.error('Chưa có họ và tên khách hàng')
          return
        }
        if (this.state.fullName && this.state.fullName.split(/\s+/).length <= 1) {
          toast.error('Chưa có đủ họ và tên khách hàng')
          return
        }
        if (!this.state.district || !this.state.district.city) {
          toast.error('Chưa có thông tin tỉnh/thành phố')
          return
        }
        if (!this.state.district || !this.state.district.district) {
          toast.error('Chưa có thông tin quận/huyện')
          return
        }
        if (!this.state.birthyear) {
          toast.error('Chưa có thông tin năm sinh')
          return
        }
        if (!this.state.street) {
          toast.error('Chưa có thông tin phố')
          return
        }

        const customer: ICustomer = {
          _id: this.state._id,
          fullName: this.state.fullName,
          cellphone: this.state.cellphone,
          customerId: this.state.customerId,
          address: {
            district: this.state.district ? this.state.district.district : '',
            city: this.state.district ? this.state.district.city : '',
            street: this.state.street
          },
          birthday: new Date(parseInt(this.state.birthyear), 0, 1)
        }

        this.setState({ updatingCustomer: true })
        try {
          await customerStore.updateCustomer(customer)
          this.props.rootStore!.orderStore.setCustomer(customer)
          this.resetState()
        } catch (e) {
          this.setState({ updatingCustomer: false })
          handleErrorResponse(e)
        }
      }

      handleOpenModal = () => {
        this.loadCustomer()
        this.setState({ openModal: true })
      }

      handleCloseModal = () => {
        this.setState({ openModal: false })
      }

      render() {
        const customerCardStage = this.props.rootStore!.orderStore.customerCardStage

        return (
          <Modal
            trigger={this.props.children}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>Sửa thông tin khách hàng</Modal.Header>
            <Modal.Content>
              {this.state.loadingCustomer && (
                <Dimmer inverted active={this.state.loadingCustomers}>
                  <Loader />
                </Dimmer>
              )}

              {!this.state.loadingCustomer && (
                <Form>
                  <Form.Field>
                    <label>Họ và tên KH</label>
                    <Input
                      ref={ref => (this.nameInput = ref)}
                      type="text"
                      value={this.state.fullName}
                      onChange={this.handleChangeField('fullName')}
                      placeholder="VD: Nguyễn Văn A"
                      icon={{
                        name: 'remove',
                        onClick: this.handleRemoveField('fullName'),
                        link: true
                      }}
                      required
                    />
                  </Form.Field>
                  <Form.Input
                    type="tel"
                    value={this.state.cellphone}
                    onChange={this.handleChangeField('cellphone')}
                    label="Số điện thoại"
                    placeholder="VD: 0917456432"
                    required
                    disabled={
                      customerCardStage === 'activated' || customerCardStage === 'not activated'
                    }
                  />

                  <Form.Checkbox
                    slider
                    label="Làm thẻ khách hàng"
                    checked={this.state.registerCustomerCard}
                    onChange={this.handleToggleRegisterCustomerCard}
                  />

                  {this.state.registerCustomerCard && (
                    <>
                      <Form.Field required>
                        <label>Năm sinh</label>
                        <DropdownBirthyear
                          value={this.state.birthyear}
                          onSelect={this.handleBirthyearChange}
                        />
                      </Form.Field>

                      <InputDistrict
                        onSelect={this.handleSelectDistrict}
                        districtLabel={this.state.districtLabel}
                        required={true}
                      />

                      <Form.Input
                        type="text"
                        value={this.state.street}
                        onChange={this.handleChangeField('street')}
                        label="Phố"
                        placeholder="VD: Tây Sơn"
                        required
                      />

                      <Form.Field required>
                        <label>Mã thẻ KH</label>
                        <Input
                          type="tel"
                          value={this.state.numberInCustomerId}
                          onChange={this.handleNumberInCustomerIdChange}
                          label={{ content: 'CX', basic: true }}
                          labelPosition="left"
                          placeholder="Điền 7 chữ số thẻ khách hàng vào đây. VD: 1292325"
                          disabled={
                            customerCardStage === 'activated' ||
                            customerCardStage === 'not activated'
                          }
                        />
                      </Form.Field>
                    </>
                  )}
                </Form>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={
                  customerCardStage === 'activated' || customerCardStage === 'not activated'
                    ? this.handleEditCustomer
                    : this.handleUpdateCustomer
                }
                color="green"
                disabled={this.state.updatingCustomer}
                style={{ minWidth: '50%' }}
              >
                Sửa
              </Button>
              <Button onClick={this.resetState}>Thoát</Button>
            </Modal.Actions>
          </Modal>
        )
      }
    }
  )
)
