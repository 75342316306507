import { decorate, observable, computed, action, runInAction, reaction } from 'mobx'
import request from 'superagent'
import { SERVER_URL } from '../config/environments'
import { RootStore } from './root-store'
import { getObjectId } from '../libs/object-id'

export class DeviceStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }
  initialized: boolean = false
  numLabelPrinters: number = 0
  get labelPrinterStatus() {
    if (!this.initialized) return 'loading'
    return this.numLabelPrinters > 0 ? 'connected' : 'disconnected'
  }
  loadNumPrinters({ numLabelPrinters }: { numLabelPrinters: number }) {
    this.initialized = true
    this.numLabelPrinters = numLabelPrinters
  }
  async getNumPrinters() {
    try {
      const outlet = this.rootStore.outletListStore.findOutletById(
        getObjectId(this.rootStore!.currentShiftStore.outlet)
      )
      if (outlet) {
        const outletCode = outlet
        const response = await request.get(SERVER_URL + `/api/${outletCode}/device-status`)
        this.loadNumPrinters(response.body)
      }
    } catch (e) {
      this.loadNumPrinters({
        numLabelPrinters: 0
      })
    }
  }

  statusReaction = reaction(
    () => {
      return this.rootStore.currentShiftStore.currentShift
        ? this.rootStore.currentShiftStore.currentShift.shiftRecordId
        : ''
    },
    () => {
      this.getNumPrinters()
      if (this.rootStore.realtimeEvent.socket) {
        this.rootStore.realtimeEvent.socket.on('deviceStatus', (data: any) => {
          this.loadNumPrinters(data)
        })
      }
    },
    {
      fireImmediately: true
    }
  )
}

decorate(DeviceStore, {
  initialized: observable,
  numLabelPrinters: observable,
  labelPrinterStatus: computed,
  loadNumPrinters: action
})
