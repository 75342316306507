import React, { ChangeEvent, SyntheticEvent } from 'react'
import { groupBy, map, get } from 'lodash'
import { observer, inject } from 'mobx-react'
import { Header, Button, Grid, Form, Dropdown, DropdownProps, List } from 'semantic-ui-react'
import { format } from 'date-fns'
import ReactTable from 'react-table'
import DatePicker from 'react-datepicker'

import { RootStore } from '../../model/root-store'
import { PrintSaleEntryButton } from './PrintSaleEntryButton'
import { FormattedCurrency } from '../../components/FormattedCurrency'
import { IPayment } from '../../model/order.store'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-table/react-table.css'

import { TShift } from '../../model/current-shift.store'
import { getObjectId } from '../../libs/object-id'
import { PrintSaleEntry } from '../PrintSaleEntry/PrintSaleEntry'

interface ListSaleEntriesViewProps {
  rootStore?: RootStore
}

interface ListSaleEntriesViewState {
  startDate: Date
  endDate: Date
  shift: string | null
  outletId: string
}

export const ListSaleEntriesView = inject('rootStore')(
  observer(
    class extends React.Component<ListSaleEntriesViewProps, ListSaleEntriesViewState> {
      state = {
        startDate: new Date(),
        endDate: new Date(),
        shift: this.props.rootStore!.currentShiftStore!.currentShift
          ? this.props.rootStore!.currentShiftStore.currentShift.shift
          : 'S',
        outletId: getObjectId(this.props.rootStore!.currentShiftStore.outlet)
      }

      handleChangeStartDate = (date: Date) => {
        this.setState({
          startDate: date
        })
      }

      handleChangeEndDate = (date: Date) => {
        this.setState({
          endDate: date
        })
      }

      convertToShift(str: string): TShift | null {
        if (str === 'S' || str === 'C' || str === 'T' || str === 'T_1') return str
        return null
      }

      handleChangeShift = (e: ChangeEvent<HTMLSelectElement>) => {
        this.setState({ shift: this.convertToShift(e.target.value) })
      }

      handleChangeOutlet = (e: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
        this.setState({ outletId: value as string })
      }

      handleSearch = () => {
        const { startDate, endDate, shift, outletId } = this.state
        this.props.rootStore!.listSaleEntryStore.searchSaleEntries(
          startDate,
          endDate,
          shift,
          outletId
        )
      }

      componentDidMount() {
        this.props.rootStore!.listSaleEntryStore.listSaleEntries()
      }

      render() {
        const saleEntries = this.props.rootStore!.listSaleEntryStore.saleEntries
        const { orderPrinting, saleEntryPrinting } = this.props.rootStore!.orderStore

        const columns = [
          {
            Header: 'SaleEntryId',
            accessor: 'saleEntryId',
            width: 200,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            filterMethod: (filter: any, row: any) => {
              const saleEntryId = row.saleEntryId.toLowerCase()
              if (saleEntryId.indexOf(filter.value.toLowerCase()) >= 0) {
                return true
              }
              return false
            }
          },
          {
            Header: 'Referrer',
            accessor: 'referrer',
            width: 150,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            filterMethod: (filter: any, row: any) => {
              if (!row.referrer) return false

              const referrer = row.referrer.toLowerCase()
              if (referrer.indexOf(filter.value.toLowerCase()) >= 0) {
                return true
              }
              return false
            }
          },
          {
            Header: 'Miêu tả',
            id: 'description',
            minWidth: 300,
            maxWidth: 380,
            accessor: (saleEntry: any) => {
              const computedItems = get(saleEntry, 'originalOrder.computedItems', [])
              return (
                <>
                  {computedItems.map((item: any, index: number) => {
                    const modifiersGroup = groupBy(item.modifiers, 'modifierSetCode')

                    return (
                      <>
                        <Dropdown trigger={<span>{item.description}</span>}>
                          <Dropdown.Menu>
                            {map(modifiersGroup, (modifiers: any) => {
                              return (
                                <Dropdown.Item
                                  text={map(modifiers, modifier => {
                                    return modifier.description
                                  }).join(', ')}
                                />
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <br />
                      </>
                    )
                  })}
                </>
              )
            },
            filterMethod: (filter: any, row: any) => {
              if (!row.fullName) return false

              const fullName = row.fullName.toLowerCase()
              if (fullName.indexOf(filter.value.toLowerCase()) >= 0) {
                return true
              }
              return false
            }
          },
          {
            Header: 'Tổng tiền',
            id: 'total',
            width: 150,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            accessor: (saleEntry: any) => {
              return <FormattedCurrency value={saleEntry.total} />
            },
            filterable: false
          },
          {
            Header: 'Phương thức thanh toán',
            id: 'payments',
            width: 200,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            accessor: (saleEntry: any) => {
              return saleEntry.payments.map((payment: IPayment) => (
                <p key={payment._id}>
                  {payment.paymentType} :
                  <FormattedCurrency value={payment.amount} />
                </p>
              ))
            },
            filterable: false
          },
          {
            Header: 'Ngày tạo',
            id: 'done',
            width: 180,
            accessor: (saleEntry: any) => {
              return format(saleEntry.done, 'DD/MM/YYYY - HH:mm:ss')
            },
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            filterable: false
          },
          {
            Header: 'Hành động',
            id: 'action',
            width: 200,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            accessor: (saleEntry: any) => {
              return (
                <Button.Group size="mini">
                  <PrintSaleEntryButton
                    order={saleEntry.originalOrder}
                    saleEntryId={saleEntry.saleEntryId}
                  />
                  <Button
                    color="green"
                    onClick={() =>
                      this.props.rootStore!.listOrderStore.printOrder(saleEntry.originalOrder)
                    }
                  >
                    In nhãn
                  </Button>
                </Button.Group>
              )
            },
            filterable: false
          }
        ]

        return (
          <>
            <PrintSaleEntry successText="In hóa đơn thành công!" />
            <div
              style={{
                height: '100%',
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: '5px',
                overflowY: 'auto',
                overflowX: 'hidden',
                WebkitOverflowScrolling: 'touch',
                opacity: orderPrinting && saleEntryPrinting ? 0 : 1
              }}
            >
              <Header style={{ marginTop: 15 }}>List Sale Entries</Header>
              <Form>
                <Grid padded columns={7}>
                  <Grid.Row>
                    <Grid.Column width="1" verticalAlign="middle">
                      <Header size="medium">Start:</Header>
                    </Grid.Column>
                    <Grid.Column width="3">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                      />
                    </Grid.Column>

                    <Grid.Column width="1" verticalAlign="middle">
                      <Header size="medium">End:</Header>
                    </Grid.Column>
                    <Grid.Column width="3">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.endDate}
                        onChange={this.handleChangeEndDate}
                      />
                    </Grid.Column>

                    <Grid.Column width="2">
                      <select
                        className="ui dropdown"
                        onChange={this.handleChangeShift}
                        defaultValue={this.state.shift}
                      >
                        <option value="S">Sáng</option>
                        <option value="C">Chiều</option>
                        <option value="T">Tối</option>
                        <option value="T_1">Tối 21h - 24h</option>
                      </select>
                    </Grid.Column>

                    <Grid.Column width="3">
                      <Dropdown
                        selection
                        placeholder="Cở sở"
                        defaultValue={this.state.outletId}
                        options={this.props
                          .rootStore!.outletListStore.savorStoreOutlets.map(outlet => {
                            return {
                              key: outlet._id,
                              value: outlet._id,
                              text: outlet.code,
                              content: `${outlet.code} - ${outlet.name}`
                            }
                          })
                          .concat({
                            key: 'NONE',
                            value: '',
                            text: '',
                            content: 'Không chọn'
                          })}
                        onChange={this.handleChangeOutlet}
                      />
                    </Grid.Column>

                    <Grid.Column width="2">
                      <Button
                        disabled={this.props.rootStore!.listSaleEntryStore.loading}
                        color="green"
                        onClick={this.handleSearch}
                      >
                        Tìm
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
              <br />
              <ReactTable
                data={saleEntries}
                columns={columns}
                filterable
                defaultPageSize={100}
                minRows={5}
                className="-striped -highlight"
                loading={this.props.rootStore!.listSaleEntryStore.loading}
                noDataText={
                  !this.props.rootStore!.listSaleEntryStore.loading &&
                  !saleEntries.length &&
                  'Không có sale entry'
                }
              />
            </div>
          </>
        )
      }
    }
  )
)
