import React from 'react'
import { some, find, get } from 'lodash'

import { observer, inject } from 'mobx-react'
import { Modal, Header, Divider, Button, Icon, List, Grid, Form } from 'semantic-ui-react'
import { truncate } from 'lodash'

import { IComputedItem, TComboElement } from '../../../model/order.store'
import { IModifier, ModifierStore } from '../../../model/modifier.store'
import QuantityChanger from './QuantityChanger'
import NoteChanger from './NoteChanger'
import { FormattedCurrency } from '../../../components/FormattedCurrency'
import { ModalChooseCombo } from './ModalChooseCombo'
import {
  baeminDrinkCombo,
  grabDrinkCombo,
  vinIDrinkCombo
} from '../../../model/combo-products.data'
import ReactDatePicker from 'react-datepicker'
import styles from './ModalChangeOrderItem.module.scss'
import { IVariant, IProduct } from '../../../model/product.store'
import { toast } from 'react-toastify'
import ButtonConfirm from '../../../components/ButtonConfirm'
import { RootStore } from '../../../model/root-store'
import { OrderStore } from '../../../model/order.store'
import { FinalPrice } from '../../../components/FinalPrice'
import { getOutOfStock } from '../../../libs/is-oos'
import { StrikableText } from '../../../components/StrikableText'
import { getModifierPrice } from '../../../libs/order.util'
import { concat } from 'react-intl/locale-data/af'

interface ModalChangeOrderItemProps {
  trigger: any
  item: IComputedItem
  modifierStore?: ModifierStore
  rootStore?: RootStore
}

interface ModalChangeOrderItemState {
  openModal: boolean
  width: number
  canDiscount?: boolean
}

function RemoveButton({ onClick, ...rest }: { onClick: Function; [key: string]: any }) {
  return (
    <ButtonConfirm
      icon="remove"
      color="red"
      content="Xóa món"
      floated="right"
      onClick={onClick}
      {...rest}
    />
  )
}

export const ModalChangeOrderItem = inject(
  'modifierStore',
  'rootStore'
)(
  observer(
    class extends React.Component<ModalChangeOrderItemProps, ModalChangeOrderItemState> {
      constructor(props: Readonly<ModalChangeOrderItemProps>) {
        super(props)
        this.state = {
          openModal: false,
          width: 0,
          canDiscount: this.props.item.canDiscount
        }
      }

      componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
      }

      componentDidUpdate(prevProps: ModalChangeOrderItemProps) {
        if (this.props.item.canDiscount !== prevProps.item.canDiscount) {
          this.setState({ canDiscount: this.props.item.canDiscount })
        }
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
      }

      updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth })
      }

      handleSelectVariant = (item: IComputedItem, variant: IVariant) => () => {
        this.props.rootStore!.orderStore.selectVariant(item, variant)
      }

      handleAddButtonClick(item: IComputedItem, modifier: IModifier) {
        return () => {
          const err = this.props.rootStore!.orderStore.addModifier(item, modifier)
          if (err) {
            toast.error(err)
          }
        }
      }

      handleSubtractButtonClick(item: IComputedItem, modifier: IModifier) {
        return () => {
          this.props.rootStore!.orderStore.subtractModifier(item, modifier)
        }
      }

      handleRemoveModifierQuantity(item: IComputedItem, modifier: IModifier) {
        return () => {
          this.props.rootStore!.orderStore.removeModifier(item, modifier)
        }
      }

      handleChangeItemQuantity = (item: IComputedItem) => {
        return (quantity: number) => {
          this.props.rootStore!.orderStore.changeItemQuantity(item, quantity)
        }
      }
      handleChangeItemManufacturingDate = (item: IComputedItem) => {
        return (date: Date) => {
          this.props.rootStore!.orderStore.changeItemManufacturingDate(item, date)
        }
      }
      handleChangeItemNote = (item: IComputedItem) => {
        return (note: string) => {
          this.props.rootStore!.orderStore.changeItemNote(item, note)
        }
      }
      handleChangeDrinkDiscount(item: IComputedItem, canDiscount: boolean) {
        return () => {
          this.props.rootStore!.orderStore.changeItemCanDiscount(item, canDiscount)
        }
      }

      handleRemoveItem = (item: IComputedItem) => {
        this.props.rootStore!.orderStore.removeItem(item)
        this.handleCloseModal()
      }

      changeToGrabCombo = (item: IProduct) => {
        item.comboElements = [
          {
            name: 'Bánh mì/Xôi',
            itemOptions: [
              {
                sku: item.sku,
                description: item.description,
                price: item.normalPrice,
                countAs: 1
              }
            ],
            maxQuantity: 1,
            selectedItem: { sku: '' }
          }
        ]
          .concat([
            {
              name: 'Đồ uống',
              itemOptions: grabDrinkCombo.itemOptions,
              maxQuantity: 1,
              selectedItem: { sku: '' }
            }
          ])
          .map(it => {
            return it
          })
      }
      changeToBAEMINCombo = (item: IProduct) => {
        item.comboElements = [
          {
            name: 'Bánh mì',
            itemOptions: [
              {
                sku: item.sku,
                description: item.description,
                price: item.normalPrice,
                countAs: 1
              }
            ],
            maxQuantity: 1,
            selectedItem: { sku: '' }
          }
        ]
          .concat([
            {
              name: 'Đồ uống',
              itemOptions: baeminDrinkCombo.itemOptions,
              maxQuantity: 1,
              selectedItem: { sku: '' }
            }
          ])
          .map(it => {
            return it
          })
      }

      changeToVinIdCombo = (item: IProduct) => {
        item.comboElements = [
          {
            name: 'Xôi',
            itemOptions: [
              {
                sku: item.sku,
                description: item.description,
                price: item.normalPrice,
                countAs: 1
              }
            ],
            maxQuantity: 1,
            selectedItem: { sku: '' }
          }
        ]
          .concat([
            {
              name: 'Đồ uống',
              itemOptions: vinIDrinkCombo.itemOptions,
              maxQuantity: 1,
              selectedItem: { sku: '' }
            }
          ])
          .map(it => {
            return it
          })
      }

      handleOpenModal = () => {
        this.setState({ openModal: true })
      }

      handleCloseModal = () => {
        this.setState({ openModal: false })
      }

      render() {
        const item = this.props.item
        const modifierStore = this.props.modifierStore
        const modifierSets = modifierStore!.getComputedModifierSets(item)
        return (
          <Modal
            trigger={this.props.trigger}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
            className={`${styles['modal-change-item']}`}
          >
            <Modal.Header>
              {truncate(`${item.description}`, {
                length: 40
              })}

              <Button color="green" floated="right" onClick={this.handleCloseModal}>
                Xong
              </Button>
              {item.saleCodeId ? (
                <Modal.Description style={{ marginTop: '5px', fontSize: '14px' }}>
                  SC: {item.saleCodeId}
                </Modal.Description>
              ) : null}
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <div className={`${styles['basic-info']}`}>
                  {!item.comboId && !item.isCombo ? (
                    <QuantityChanger
                      className={`${styles['quantity-changer']}`}
                      label="Số lượng"
                      onQuantityChanged={this.handleChangeItemQuantity(item)}
                      quantity={item.quantity}
                      saleCode={item.saleCode || ''}
                    />
                  ) : (
                    ''
                  )}

                  {/* Chọn loại */}
                  {item.variants && item.variants.length > 0 && (
                    <div className={`${styles['variant-selector']}`}>
                      <Header style={{ display: 'inline' }}>Chọn loại</Header>
                      <List horizontal>
                        {item.variants.map(variant => {
                          let isOOS = some(this.props.rootStore!.productStatusStore.products, {
                            sku: variant.sku,
                            inStock: false
                          })
                          const matchVariant = find(
                            this.props.rootStore!.productStatusStore.products,
                            {
                              sku: variant.sku
                            }
                          )

                          if (!matchVariant) {
                            isOOS = true
                          }
                          // console.log(variant.sku, matchVariant, isOOS)

                          return (
                            <List.Item
                              key={variant.sku}
                              onClick={isOOS ? undefined : this.handleSelectVariant(item, variant)}
                            >
                              <Icon
                                color={item.selectedVariant!.sku === variant.sku ? 'green' : 'grey'}
                                name={
                                  item.selectedVariant!.sku === variant.sku
                                    ? 'checkmark box'
                                    : 'square outline'
                                }
                              />
                              <List.Content>
                                <List.Header>
                                  <StrikableText strike={isOOS}>
                                    {variant.description}
                                  </StrikableText>
                                </List.Header>
                                <List.Description>
                                  <FinalPrice
                                    normalPrice={variant.normalPrice}
                                    salePrice={variant.salePrice}
                                  />
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          )
                        })}
                      </List>
                      <Divider />
                    </div>
                  )}
                  {/* Chọn combo */}
                  {['V1066', 'V1067', 'V0381', 'V1023', 'V0377'].includes(item.sku) &&
                  this.props.rootStore!.orderStore!.orderPlatform === 'GRAB_FOOD' ? (
                    <ModalChooseCombo
                      key={item.sku}
                      product={item}
                      trigger={
                        <Button
                          color="green"
                          floated="right"
                          onClick={() => this.changeToGrabCombo(item)}
                        >
                          Chọn Đồ Uống Grab
                        </Button>
                      }
                    ></ModalChooseCombo>
                  ) : (
                    ''
                  )}
                  {['V0287', 'V0098', 'V0096'].includes(item.sku) &&
                  this.props.rootStore!.orderStore!.orderPlatform === 'BAEMIN' ? (
                    <ModalChooseCombo
                      key={item.sku}
                      product={item}
                      trigger={
                        <Button
                          color="green"
                          floated="right"
                          onClick={() => this.changeToBAEMINCombo(item)}
                        >
                          Chọn Đồ Uống BAEMIN
                        </Button>
                      }
                    ></ModalChooseCombo>
                  ) : (
                    ''
                  )}
                  {['sticky-rice'].includes(item.category) &&
                  this.props.rootStore!.orderStore!.orderPlatform === 'VINID' ? (
                    <ModalChooseCombo
                      key={item.sku}
                      product={item}
                      trigger={
                        <Button
                          color="green"
                          floated="right"
                          onClick={() => this.changeToVinIdCombo(item)}
                        >
                          Chọn Trà chanh miễn phí
                        </Button>
                      }
                    ></ModalChooseCombo>
                  ) : (
                    ''
                  )}
                  {!item.comboId ? (
                    <RemoveButton basic onClick={() => this.handleRemoveItem(item)} />
                  ) : (
                    ''
                  )}
                </div>
                <Divider />
                {/* Chi tiết addon */}
                <Grid columns={this.state.width <= 800 ? 2 : 4} divided>
                  {modifierSets.map(modifierSet => (
                    <Grid.Column key={modifierSet.code}>
                      <Header>{modifierSet.name}</Header>
                      <List key={modifierSet.code}>
                        {modifierSet.modifiers.map(modifier => {
                          const matchItemModifier = item.modifiers.find(
                            itemModifier => itemModifier.sku === modifier.sku
                          )

                          const isOOS = getOutOfStock(
                            this.props.rootStore!.productStatusStore.productMap,
                            modifier.sku
                          )

                          const price = getModifierPrice(
                            modifier,
                            this.props.rootStore!.orderStore!.orderPlatform
                          )

                          return !!modifier.maxQuantity &&
                            modifier.maxQuantity > 0 &&
                            modifier.maxQuantity <= 2 ? (
                            <List.Item
                              key={modifier.sku}
                              onClick={
                                isOOS
                                  ? undefined
                                  : !!matchItemModifier &&
                                    matchItemModifier.quantity === modifier.maxQuantity
                                  ? this.handleRemoveModifierQuantity(item, modifier)
                                  : this.handleAddButtonClick(item, modifier)
                              }
                            >
                              {modifierSet.maxTotalQuantity === 1 &&
                              modifierSet.minTotalQuantity === 1 ? (
                                <Icon
                                  color={
                                    !!matchItemModifier && matchItemModifier.quantity > 0
                                      ? 'green'
                                      : 'grey'
                                  }
                                  name={
                                    !!matchItemModifier && matchItemModifier.quantity === 1
                                      ? 'selected radio'
                                      : !!matchItemModifier && matchItemModifier.quantity === 2
                                      ? 'angle double up'
                                      : 'radio'
                                  }
                                />
                              ) : (
                                <Icon
                                  color={
                                    !!matchItemModifier && matchItemModifier.quantity > 0
                                      ? 'green'
                                      : 'grey'
                                  }
                                  name={
                                    !!matchItemModifier && matchItemModifier.quantity === 1
                                      ? 'checkmark box'
                                      : !!matchItemModifier && matchItemModifier.quantity === 2
                                      ? 'angle double up'
                                      : 'square outline'
                                  }
                                />
                              )}

                              <List.Content style={{}}>
                                <List.Header>
                                  <StrikableText strike={isOOS}>
                                    {modifier.description}{' '}
                                    {!!matchItemModifier &&
                                      matchItemModifier.quantity >= 2 &&
                                      ` x${matchItemModifier.quantity}`}
                                  </StrikableText>
                                </List.Header>
                                {!!price && price > 0 && (
                                  <List.Description style={{ textAlign: 'right' }}>
                                    <FormattedCurrency value={price} />
                                  </List.Description>
                                )}
                              </List.Content>
                            </List.Item>
                          ) : (
                            <List.Item
                              key={modifier.sku}
                              className={`${styles['item-modifier']} ${
                                matchItemModifier && matchItemModifier.quantity > 0
                                  ? styles.selected
                                  : ''
                              }`}
                            >
                              <List.Content>
                                <List.Header>
                                  <StrikableText strike={isOOS}>
                                    {modifier.description}
                                  </StrikableText>
                                </List.Header>
                                {modifier.price && modifier.price > 0 && (
                                  <List.Description style={{ textAlign: 'right' }}>
                                    <FormattedCurrency value={modifier.price} />
                                  </List.Description>
                                )}
                              </List.Content>
                              <div className={`${styles['item-buttons']}`}>
                                <Button.Group>
                                  <Button
                                    className={styles['modifier-quantity-button']}
                                    basic
                                    onClick={
                                      isOOS
                                        ? undefined
                                        : this.handleSubtractButtonClick(item, modifier)
                                    }
                                    icon={<Icon name="minus" />}
                                  />
                                  <Button.Or
                                    text={matchItemModifier ? matchItemModifier.quantity : 0}
                                  />
                                  <Button
                                    className={styles['modifier-quantity-button']}
                                    basic
                                    onClick={
                                      isOOS ? undefined : this.handleAddButtonClick(item, modifier)
                                    }
                                    icon={<Icon name="plus" />}
                                  />
                                </Button.Group>
                              </div>
                            </List.Item>
                          )
                        })}
                      </List>
                    </Grid.Column>
                  ))}
                </Grid>
                <Divider />
                {/* Ghi chú */}
                <Grid divided>
                  <NoteChanger
                    onNoteChanged={this.handleChangeItemNote(item)}
                    note={item.note ? item.note : ''}
                  ></NoteChanger>
                </Grid>
                <Grid divided>
                  <Header
                    style={{
                      marginRight: 10,
                      marginBottom: 0,
                      marginTop: 10
                    }}
                  >
                    Ngày SX
                  </Header>{' '}
                  <Form>
                    <Form.Field>
                      <ReactDatePicker
                        selected={item.manufacturingDate}
                        onChange={this.handleChangeItemManufacturingDate(item)}
                        disabled={!!item.saleCode}
                      />
                    </Form.Field>
                  </Form>
                </Grid>
                {/* Tick chọn giảm giá */}
                {this.props.rootStore!.orderStore!.orderPlatform !== 'SAVOR_SHOP' ? (
                  <Grid divided>
                    <Header
                      style={{
                        marginBottom: 0,
                        marginTop: 10
                      }}
                    >
                      Có giảm giá
                    </Header>{' '}
                    <Form>
                      <Form.Checkbox
                        style={{
                          marginRight: 10,
                          marginBottom: 0,
                          marginTop: 10
                        }}
                        checked={this.state.canDiscount}
                        onChange={() => {
                          this.setState(
                            () => ({ canDiscount: !item.canDiscount }),
                            () => {
                              this.handleChangeDrinkDiscount(item, !item.canDiscount)()
                            }
                          )
                        }}
                      />
                    </Form>
                  </Grid>
                ) : null}
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 2,
                  content: 'Xong',
                  color: 'green',
                  onClick: this.handleCloseModal,
                  style: { minWidth: '100%', marginLeft: 0 }
                }
              ]}
            />
          </Modal>
        )
      }
    }
  )
)
