import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { routes } from '../config/routes'
import { PrivateRoute } from '../components/PrivateRoute'
import { NoAuthRoute } from '../components/NoAuthRoute'

export const MainView = React.memo(function() {
  return (
    <Switch>
      {routes
        .filter(route => route.visibleOnMenu)
        .map(route => {
          if (route.needAuthentication) {
            return (
              <PrivateRoute
                key={route.path || 'N/A'}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            )
          }
          if (route.needAuthentication === false) {
            return (
              <NoAuthRoute
                key={route.path || 'N/A'}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            )
          }
          return (
            <Route
              key={route.path || 'N/A'}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          )
        })}
    </Switch>
  )
})
