import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

ReactGA.initialize('UA-28918899-8')

type GaTrackerProps = RouteComponentProps

const GaTracker = ({ location }: GaTrackerProps) => {
  useEffect(() => {
    trackPage(location.pathname + location.search)
  }, [location.pathname, location.search])

  function trackPage(page: any) {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({
        page
      })
      ReactGA.pageview(page)
    } else {
      console.log(`Track ${page}`)
    }
  }

  return null
}

export default withRouter(GaTracker)
