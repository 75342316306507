import React from 'react'

import styles from './OrderItem.module.scss'
import tableStyles from '../scss/table.module.scss'
import { FormattedCurrency } from './FormattedCurrency'
import { IComputedItem, OrderStore } from '../model/order.store'
import { getModifierPrice } from '../libs/order.util'
import { get } from 'lodash'
import { format } from 'date-fns'
export function OrderItem({
  item,
  order,
  ...rest
}: {
  item: IComputedItem
  order: OrderStore
  [key: string]: any
}) {
  return (
    <tr
      {...rest}
      className={
        tableStyles['top-bordered'] + ' ' + tableStyles['group-row-item'] + ' ' + rest.className
      }
    >
      <td className={`${styles.itemDescription}`}>
        {item.description} {item.noLoyalty && '(KTĐ)'}{' '}
        {item.quantity > 1 ? `x${item.quantity}` : undefined}
        {item.modifiers.length > 0 && (
          <div className={`${styles.itemSubDescription}`}>
            {item.modifiers.map((modifier, index) => {
              const orderPlatform = get(order, 'orderPlatform')
              const modifierPrice = getModifierPrice(modifier, orderPlatform)
              return (
                <span key={modifier.sku}>
                  {modifier.description} {modifier.quantity > 1 && `x${modifier.quantity}`}{' '}
                  {!!modifierPrice && modifierPrice > 0 && (
                    <FormattedCurrency value={(modifierPrice || 0) * modifier.quantity} />
                  )}
                  {index !== item.modifiers.length - 1 && ', '}
                </span>
              )
            })}
          </div>
        )}
        {item.programReminder ? (
          <div>
            <small style={{ color: 'red' }}>{item.programReminder}</small>
          </div>
        ) : undefined}
        {item.saleProgram ? (
          <div>
            <small style={{ color: 'green' }}>{item.saleProgram}</small>
          </div>
        ) : undefined}
        {item.hasSaltedEgg ? (
          <div>
            <small style={{ color: 'red' }}>Có trứng muối</small>
          </div>
        ) : undefined}
        {item.comboName ? (
          <div>
            <small style={{ color: 'blue' }}>{item.comboName}</small>
          </div>
        ) : undefined}
        {item.note ? (
          <div>
            <small style={{ color: 'orange' }}>Ghi chú: {item.note}</small>
          </div>
        ) : undefined}
        {item.manufacturingDate ? (
          <div>
            <small style={{ color: 'red' }}>
              NSX: {format(item.manufacturingDate, 'DD/MM/YYYY')}
            </small>
          </div>
        ) : undefined}
        {item.saleCodeId ? (
          <div>
            <small style={{ color: 'blue' }}>Sale code: {item.saleCodeId}</small>
          </div>
        ) : undefined}
      </td>
      <td className={`${tableStyles['quantity-cell']}`}>
        <FormattedCurrency value={item.lineTotal} />
        {!!item.discount && (
          <div>
            <small style={{ color: 'green' }}>
              (-
              <FormattedCurrency value={item.discount} />)
            </small>
          </div>
        )}
      </td>
    </tr>
  )
}
