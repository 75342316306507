import React from 'react'

import { withRouter } from 'react-router-dom'
import { Header, Container } from 'semantic-ui-react'

export const NotFoundView = withRouter(function({ history }) {
  return (
    <Container>
      <Header>Đường dẫn không xác định</Header>
    </Container>
  )
})
