import React from 'react'
import { Button, Grid, Image } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import { chunk } from 'lodash'
import { OrderStore, TOrderPlatform, TPaymentType } from '../model/order.store'
import loshipLogo from './../assets/logo/logo_loship.png'
import momoLogo from './../assets/logo/logo-momo.jpg'
import airPayLogo from './../assets/logo/shopee-pay-logo.png'
import vnpayLogo from './../assets/logo/vnpay-logo.png'
import grabPayLogo from './../assets/logo/grab-pay-logo.png'
import baeminLogo from './../assets/logo/baemin-logo.png'
import beFoodLogo from './../assets/logo/be-food-logo.png'
import vinIdLogo from '../assets/logo/vin-id-logo.png'
import lalamoveLogo from '../assets/logo/lalamove.png'
import gojekLogo from '../assets/logo/go-jek.png'
import zalopayLogo from '../assets/logo/zalopay-logo.png'
import { isPaymentTypeAvailable } from '../libs/payment-helper'

interface AddPaymentProps {
  orderStore: OrderStore
}

interface AddPaymentState {
  paymentType: string
  // code: string
}

function PaymentColumn({
  paymentType,
  activePaymentType,
  handleChoosePaymentType,
  logo,
  title
}: {
  paymentType: TPaymentType
  activePaymentType: string | null
  orderPlatform: TOrderPlatform
  handleChoosePaymentType: (paymentType: TPaymentType) => void
  logo: string | undefined
  title: string | undefined
}) {
  return (
    <Grid.Column stretched key={paymentType}>
      <Button
        inverted
        onClick={() => handleChoosePaymentType(paymentType)}
        color="grey"
        fluid
        active={activePaymentType === paymentType}
        style={{ color: 'black' }}
      >
        {!!title && <h1>{title}</h1>}
        {!!logo && <Image src={logo} alt={paymentType} centered fluid />}
      </Button>
    </Grid.Column>
  )
}

type TAvailablePayment = {
  paymentType: TPaymentType
  title?: string
  logo?: string
}

export const AddPayment = observer(
  class extends React.Component<AddPaymentProps, AddPaymentState> {
    handleChoosePaymentType = (type: TPaymentType) => {
      this.props.orderStore.chooosePaymentType(type)
    }

    render() {
      const toDisablePaymentType: (paymentType: any) => boolean = paymentType =>
        !isPaymentTypeAvailable(this.props.orderStore.orderPlatform, paymentType)

      let availablePayments: TAvailablePayment[] = [
        { paymentType: 'CASH', title: 'Tiền mặt', logo: undefined },
        { paymentType: 'TRANSFER', title: 'Chuyển khoản', logo: undefined },
        { paymentType: 'ZALOPAY', title: undefined, logo: zalopayLogo },
        { paymentType: 'MOMO', title: undefined, logo: momoLogo },
        { paymentType: 'LOSHIP', title: undefined, logo: loshipLogo },
        { paymentType: 'AIRPAY', title: undefined, logo: airPayLogo },
        { paymentType: 'GRABPAY', title: undefined, logo: grabPayLogo },
        { paymentType: 'VNPAY', title: undefined, logo: vnpayLogo },
        { paymentType: 'CARD', title: 'Thẻ ATM', logo: undefined },
        { paymentType: 'VINID', title: undefined, logo: vinIdLogo },
        { paymentType: 'GO_JEK', title: undefined, logo: gojekLogo },
        { paymentType: 'BE_FOOD', title: undefined, logo: beFoodLogo },
        { paymentType: 'LALAMOVE', title: undefined, logo: lalamoveLogo }
      ]
      availablePayments = availablePayments.filter(
        payment => !toDisablePaymentType(payment.paymentType)
      )

      const rows = chunk<TAvailablePayment>(availablePayments, 3)

      return (
        <Grid>
          {rows.map(row => {
            const key = row.map(paymnent => paymnent.paymentType).join('-')
            return (
              <Grid.Row key={key} columns="3">
                {row.map(payment =>
                  PaymentColumn({
                    paymentType: payment.paymentType,
                    activePaymentType: this.props.orderStore.activePaymentType,
                    logo: payment.logo,
                    title: payment.title,
                    orderPlatform: this.props.orderStore.orderPlatform,
                    handleChoosePaymentType: this.handleChoosePaymentType
                  })
                )}
              </Grid.Row>
            )
          })}
        </Grid>
      )
    }
  }
)
