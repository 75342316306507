import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss'
import 'semantic-ui-css/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { addLocaleData, IntlProvider } from 'react-intl'
import { configure } from 'mobx'
import vi from 'react-intl/locale-data/vi'

import { config } from 'bluebird'

config({ cancellation: true })
configure({ enforceActions: 'always' })
addLocaleData([...vi])

ReactDOM.render(
  <Router>
    <IntlProvider locale="vi">
      <App />
    </IntlProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
