import React, { ComponentPropsWithoutRef, memo } from 'react'
import { FormattedNumber } from 'react-intl'

interface FormattedCurrencyProps extends ComponentPropsWithoutRef<any> {
  value: number
  [key: string]: any
}

export const FormattedCurrency = memo(({ value, ...rest }: FormattedCurrencyProps) => (
  <FormattedNumber
    value={value}
    style="currency"
    currency="VND"
    currencyDisplay="symbol"
    {...rest}
  />
))
