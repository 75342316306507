import React from 'react'
import { Modal, Button, Form, Grid } from 'semantic-ui-react'
import { OrderItem } from '../../components/OrderItem'
import { toast } from 'react-toastify'
import { observer, inject } from 'mobx-react'
import { RootStore } from '../../model/root-store'
import { handleAgentErrorResponse } from '../../libs/error-handler'
import { OrderStore } from '../../model/order.store'

interface ModalCancelOrderProps {
  order: OrderStore
  rootStore?: RootStore
}

interface ModalCancelOrderState {
  cancelReason: String
  loading: boolean
}

export const ModalCancelOrder = inject('rootStore')(
  observer(
    class extends React.Component<ModalCancelOrderProps, ModalCancelOrderState> {
      state = {
        cancelReason: '',
        loading: false
      }

      handleChangeInput = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement

        this.setState({
          cancelReason: target.value
        })
      }

      handleClickCancelOrder = () => {
        this.setState({ loading: true })

        if (!this.state.cancelReason) {
          this.setState({ loading: false })
          return toast.error('Chưa nhập lý do hủy đơn')
        }

        this.props
          .rootStore!.listOrderStore.cancelOrder(this.props.order, this.state.cancelReason)
          .then(() => {
            toast.success('Hủy đơn thành công !')
          })
          .catch(e => {
            handleAgentErrorResponse(e)
            this.setState({ loading: false })
          })
      }

      render() {
        return (
          <Modal trigger={this.props.children}>
            <Modal.Header>Hủy đơn hàng #{this.props.order.orderId}</Modal.Header>
            <Modal.Content>
              <Grid columns={2} divided>
                <Grid.Column>
                  <Modal.Description>
                    <table
                      style={{
                        width: '100%'
                      }}
                    >
                      <tbody>
                        {this.props.order.computedItems.map(item => (
                          <OrderItem key={item.id} item={item} order={this.props.order} />
                        ))}
                      </tbody>
                    </table>
                  </Modal.Description>
                </Grid.Column>
                <Grid.Column>
                  <Form size="large">
                    <Form.TextArea
                      required
                      label="Lý do hủy đơn"
                      placeholder="Nhập lý do hủy đơn ..."
                      name="reason"
                      value={this.state.cancelReason}
                      onChange={this.handleChangeInput}
                    />
                  </Form>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                color="red"
                disabled={this.state.loading}
                onClick={this.handleClickCancelOrder}
              >
                Hủy đơn
              </Button>
            </Modal.Actions>
          </Modal>
        )
      }
    }
  )
)
