import React from 'react'

import { inject, observer } from 'mobx-react'

import { IComputedItem, OrderStore } from '../../../model/order.store'
import { ModalChangeOrderItem } from './ModalChangeOrderItem'
import { OrderItem } from '../../../components/OrderItem'

interface CurrentOrderItemProps {
  item: IComputedItem
  order: OrderStore
}

export const CurrentOrderItem = ({ item, order, ...rest }: CurrentOrderItemProps) => {
  return (
    <ModalChangeOrderItem
      item={item}
      trigger={<OrderItem item={item} order={order} {...rest} />}
      {...rest}
    />
  )
}
