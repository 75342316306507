import React from 'react'
import { inject, observer } from 'mobx-react'
import { IProduct } from '../model/product.store'
import { RootStore } from '../model/root-store'
import { ProductInput } from './ProductInput'
import { reject } from 'lodash'

interface SellingProductInputProps {
  rootStore?: RootStore
  onSelect: (selectedProduct: IProduct) => void
}

export const SellingProductInput = inject('rootStore')(
  observer(
    class extends React.Component<SellingProductInputProps> {
      render() {
        const productStore = this.props.rootStore!.productStore
        const products = reject(productStore.products, { isModifier: true })

        return <ProductInput products={products} onSelect={this.props.onSelect} />
      }
    }
  )
)
