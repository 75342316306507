import React from 'react'
import { FormattedCurrency } from './FormattedCurrency'

export function FinalPrice({
  salePrice,
  normalPrice
}: {
  salePrice?: number
  normalPrice: number
}) {
  return (
    <>
      {!!salePrice && (
        <s>
          <FormattedCurrency value={normalPrice} />
        </s>
      )}{' '}
      <FormattedCurrency value={salePrice || normalPrice} />
    </>
  )
}
