import React from 'react'
import { AuthentiationStore } from './authentication.store'
import { OutletListStore } from './outlet-list.store'
import { Agent } from '../libs/agent'
import { CurrentShiftStore } from './current-shift.store'
import { runInAction } from 'mobx'
import { OrderStore } from './order.store'
import { ListOrderStore } from './list-order.store'
import { ProductStore } from './product.store'
import { RealtimeEvent } from '../config/real-time'
import { DeviceStore } from './device.store'
import { ProductStatusStore } from './product-status.store'
import { ListSaleEntryStore } from './list-sale-entries.store'
import { UiStore } from './ui.store'

export class RootStore {
  authenticationStore!: AuthentiationStore
  outletListStore!: OutletListStore
  agent!: Agent
  currentShiftStore!: CurrentShiftStore
  orderStore!: OrderStore
  listOrderStore!: ListOrderStore
  listSaleEntryStore!: ListSaleEntryStore
  productStore!: ProductStore
  productStatusStore!: ProductStatusStore
  realtimeEvent!: RealtimeEvent
  deviceStore!: DeviceStore
  uiStore!: UiStore
  constructor() {
    runInAction(() => {
      this.agent = new Agent()
      this.agent.loadRootStore(this)

      this.authenticationStore = new AuthentiationStore()
      this.authenticationStore.loadRootStore(this)
      this.authenticationStore.loadLocalData()

      this.currentShiftStore = new CurrentShiftStore()
      this.currentShiftStore.loadRootStore(this)
      this.currentShiftStore.loadLocalData()

      this.realtimeEvent = new RealtimeEvent()
      this.realtimeEvent.loadRootStore(this)

      this.outletListStore = new OutletListStore()
      this.outletListStore.loadRootStore(this)

      this.orderStore = new OrderStore()
      this.orderStore.loadRootStore(this)

      this.listOrderStore = new ListOrderStore()
      this.listOrderStore.loadRootStore(this)

      this.listSaleEntryStore = new ListSaleEntryStore()
      this.listSaleEntryStore.loadRootStore(this)

      this.productStore = new ProductStore()
      this.productStore.loadRootStore(this)
      this.productStore.init()

      this.productStatusStore = new ProductStatusStore()
      this.productStatusStore.loadRootStore(this)

      this.deviceStore = new DeviceStore()
      this.deviceStore.loadRootStore(this)

      this.uiStore = new UiStore()
      this.uiStore.loadRootStore(this)
    })
  }
}
export const rootStore = new RootStore()
export const RootStoreContext = React.createContext<RootStore>(rootStore)
