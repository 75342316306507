import React, { ChangeEvent } from 'react'
import { IVoucher } from '../../../model/customer.model'
import { DropdownProps, Form } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { RootStore } from '../../../model/root-store'

interface ISelectVoucherProps {
  availableVouchers: IVoucher[]
  rootStore?: RootStore
}

export const SelectVoucher = inject('rootStore')(
  observer(
    class extends React.Component<ISelectVoucherProps> {
      handleSelectVoucher = (e: any, props: DropdownProps) => {
        const matchVoucher = this.props.availableVouchers.find(
          voucher => voucher._id === props.value
        )
        if (matchVoucher) {
          this.props.rootStore!.orderStore.setVoucher(matchVoucher)
        }
      }

      handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const matchVoucher = this.props.availableVouchers.find(
          voucher => voucher._id === e.target.value
        )

        if (matchVoucher) {
          this.props.rootStore!.orderStore.setVoucher(matchVoucher)
        }
      }

      render() {
        const orderStore = this.props.rootStore!.orderStore

        return (
          <Form>
            <Form.Field inline>
              <label>Voucher</label>
              <select
                onChange={this.handleSelect}
                value={orderStore.voucher ? orderStore.voucher._id : ''}
              >
                {orderStore.availableVouchers.map(voucher => {
                  return (
                    <option key={voucher._id} value={voucher._id}>
                      {voucher.name}
                    </option>
                  )
                })}
              </select>
            </Form.Field>
          </Form>
        )
      }
    }
  )
)
