import React, { Component } from 'react'
import { Modal, Form, Grid } from 'semantic-ui-react'
import { TShippingProvider, OrderStore } from '../model/order.store'
import { observer, inject } from 'mobx-react'
import _, { clone, isNumber, toNumber } from 'lodash'
import { InputCurrency } from './InputCurrency'
import { toast } from 'react-toastify'
interface ModalChangeShippingProps {
  order: OrderStore
}
interface ModalChangeShippingState {
  shippingProvider: TShippingProvider
  shippingPay: number
  shippingCollect: number
  shippingBillNumber: string
  shippingPaymentMethod: string
  shippingCustomerName: string
  shippingCustomerPhone: string
  shippingCustomerAddress: string
  onlineOrderId: string
  openModal: boolean
}

const DEFAULT_STATE: ModalChangeShippingState = {
  shippingProvider: 'Lalamove',
  shippingPay: 0,
  shippingCollect: 30000,
  onlineOrderId: '',
  shippingBillNumber: '',
  shippingPaymentMethod: '',
  shippingCustomerName: '',
  shippingCustomerPhone: '',
  shippingCustomerAddress: '',
  openModal: false
}

export const ModalChangeShipping = inject('rootStore')(
  observer(
    class extends Component<ModalChangeShippingProps, ModalChangeShippingState> {
      state: ModalChangeShippingState = clone(DEFAULT_STATE)

      handleSubmitShippingData = async () => {
        if (this.state.shippingProvider === 'Lalamove') {
          if (!this.state.shippingBillNumber) {
            return toast.error('Chưa điền mã Lalamove')
          }
          if (!this.state.shippingBillNumber.match(/(^\w{18}$|^\w{10}$|^\w{12}$)/)) {
            return toast.error(`Mã vận đơn Lalamove chỉ gồm 10,12 hoặc 18 ký tự chữ cái hoặc số`)
          }
        }
        if (
          this.state.shippingBillNumber &&
          (!this.state.shippingPaymentMethod ||
            _.includes(['', 'NONE'], this.state.shippingPaymentMethod))
        ) {
          return toast.error('Chưa chọn phương thức thanh toán phí ship')
        }
        if (
          ['cash', 'customerSubtract'].includes(this.state.shippingPaymentMethod) &&
          !this.state.shippingPay
        ) {
          return toast.error('Chưa điền Phí ship trả ĐV Vận chuyển')
        }
        if (!this.state.onlineOrderId) {
          return toast.error('Chưa điền mã Online order')
        }
        if (this.state.onlineOrderId) {
          await this.props.order.fetchOnlineOrder(this.state.onlineOrderId)
        }
        if (this.state.onlineOrderId && this.props.order.onlineOrder) {
          const onlineOrder = this.props.order.onlineOrder
          if (isNumber(_.get(onlineOrder, 'shipping.collect'))) {
            this.state.shippingCollect = _.get(onlineOrder, 'shipping.collect')
          } else if (isNumber(_.get(onlineOrder, 'serverCalculatedShipCollect'))) {
            this.state.shippingCollect = _.get(onlineOrder, 'serverCalculatedShipCollect')
          }
        }
        this.props.order.setShippingData({
          shippingProvider: this.state.shippingProvider,
          shippingPay: toNumber(this.state.shippingPay),
          shippingCollect: toNumber(this.state.shippingCollect),
          shippingBillNumber: this.state.shippingBillNumber,
          shippingPaymentMethod: this.state.shippingPaymentMethod,
          shippingCustomerName: this.state.shippingCustomerName,
          shippingCustomerPhone: this.state.shippingCustomerPhone,
          shippingCustomerAddress: this.state.shippingCustomerAddress,
          onlineOrderId: this.state.onlineOrderId
        })
        if (!this.props.order.autosave) {
          this.setState(DEFAULT_STATE)
        } else {
          this.setState({ openModal: false })
        }
      }

      handleClearShippingData = () => {
        this.props.order.setShippingData({
          shippingProvider: null,
          shippingPay: 0,
          shippingCollect: 0,
          shippingBillNumber: '',
          shippingPaymentMethod: '',
          shippingCustomerName: '',
          shippingCustomerPhone: '',
          shippingCustomerAddress: '',
          onlineOrderId: ''
        })
        this.setState(clone(DEFAULT_STATE))
      }

      handleOpenModal = () => {
        this.setState({
          openModal: true,
          shippingProvider: this.props.order.shippingProvider || DEFAULT_STATE.shippingProvider,
          shippingPay: this.props.order.shippingPay || DEFAULT_STATE.shippingPay,
          shippingCollect: this.props.order.shippingCollect || DEFAULT_STATE.shippingCollect,
          shippingBillNumber:
            this.props.order.shippingBillNumber || DEFAULT_STATE.shippingBillNumber,
          shippingPaymentMethod:
            this.props.order.shippingPaymentMethod || DEFAULT_STATE.shippingPaymentMethod,
          shippingCustomerName:
            this.props.order.shippingCustomerName || DEFAULT_STATE.shippingCustomerName,
          shippingCustomerPhone:
            this.props.order.shippingCustomerPhone || DEFAULT_STATE.shippingCustomerPhone,
          shippingCustomerAddress:
            this.props.order.shippingCustomerAddress || DEFAULT_STATE.shippingCustomerAddress,
          onlineOrderId: this.props.order.onlineOrderId || DEFAULT_STATE.onlineOrderId
        })
      }

      handleCloseModal = () => {
        this.setState({ openModal: false })
      }

      render() {
        return (
          <Modal
            trigger={this.props.children}
            open={this.state.openModal}
            onOpen={this.handleOpenModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>Ship hàng</Modal.Header>
            <Modal.Content>
              <Form>
                <Grid stackable columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <label>Mã Online Order</label>
                        <input
                          type="text"
                          value={this.state.onlineOrderId}
                          onChange={(e: any) => {
                            this.setState({ onlineOrderId: e.target.value })
                          }}
                          placeholder="VD: 123456"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Nhà cung cấp</label>
                        <select
                          className="ui dropdown"
                          value={this.state.shippingProvider}
                          onChange={(e: any) => {
                            this.setState({ shippingProvider: e.target.value })
                          }}
                        >
                          <option>Lalamove</option>
                          <option>Ahamove</option>
                          <option>Ship ngoài</option>
                          <option>Ship nội bộ</option>
                          <option>Giao tại cửa hàng</option>
                        </select>
                      </Form.Field>
                      <Form.Field>
                        <label>Phí ship thu khách hàng</label>
                        <input
                          type="number"
                          value={this.state.shippingCollect}
                          onChange={(e: any) => {
                            this.setState({ shippingCollect: e.target.value })
                          }}
                          placeholder="VD: 10000"
                        />
                      </Form.Field>
                      {(this.state.shippingProvider === 'Ship ngoài' ||
                        ['cash', 'customerSubtract'].includes(
                          this.state.shippingPaymentMethod
                        )) && (
                        <Form.Field>
                          <label>Phí ship trả đơn vị vận chuyển</label>
                          <input
                            type="number"
                            value={this.state.shippingPay}
                            onChange={(e: any) => {
                              this.setState({ shippingPay: e.target.value })
                            }}
                            placeholder="VD: 10000"
                          />
                        </Form.Field>
                      )}
                      {(this.state.shippingProvider === 'Lalamove' ||
                        this.state.shippingProvider === 'Ahamove') && (
                        <Form.Field>
                          <label>Mã đơn ship</label>
                          <input
                            type="text"
                            value={this.state.shippingBillNumber}
                            onChange={(e: any) => {
                              this.setState({ shippingBillNumber: e.target.value })
                            }}
                            placeholder="VD: DCKD923"
                          />
                        </Form.Field>
                      )}
                      {(this.state.shippingProvider === 'Lalamove' ||
                        this.state.shippingProvider === 'Ahamove') && (
                        <Form.Field>
                          <label>Phương thức thanh toán phí ship</label>
                          <select
                            value={this.state.shippingPaymentMethod}
                            onChange={(e: any) => {
                              this.setState({ shippingPaymentMethod: e.target.value })
                            }}
                          >
                            <option value="NONE">Chưa có</option>
                            <option value="cash">Tiền mặt</option>
                            <option value="digiWallet">Ví điện tử</option>
                            <option value="customerSubtract">Trừ vào tiền thu khách</option>
                          </select>
                        </Form.Field>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 'done',
                  color: 'green',
                  content: 'Lưu',
                  style: { minWidth: '50%' },
                  onClick: this.handleSubmitShippingData
                },
                {
                  key: 'clear',
                  color: 'blue',
                  content: 'Xóa',
                  onClick: this.handleClearShippingData
                }
              ]}
            />
          </Modal>
        )
      }
    }
  )
)
