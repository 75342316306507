import { observable, action, computed, decorate, runInAction } from 'mobx'
import { cloneDeep, clone, get } from 'lodash'
import { products } from './product.data'
import { handleAgentErrorResponse } from '../libs/error-handler'
import getSlug from 'speakingurl'
import { RootStore } from './root-store'
import { TOrderPlatform, TItemOption, TComboElement } from './order.store'

type TStoreCategory =
  | 'sticky-rice'
  | 'tokbokki'
  | 'sandwich'
  | 'fried'
  | 'drink'
  | 'bread'
  | 'addon'
  | 'noodle'
  | 'savor-drink'
  | 'juice'
  | 'other'
  | 'milk-tea'
  | 'cake'
  | 'birthday-cake'
  | 'combo'
  | 'combo-savor'
  | 'covid-combo'
  | 'banh-keo-tet'
  | 'valentine'
  | 'gift'
  | 'mooncake'

type TStoreSubCat =
  | 'savor-drink'
  | 'fruit-tea'
  | 'olong-tra'
  | 'dua-nuong'
  | 'hong-tra'
  | 'baemin-combo'
  | 'now-combo'
  | 'gojek-combo'
  | 'befood-combo'
  | 'accessory'
  | 'cake-combo'
  | 'grab-combo'
  | 'bread-combo'
  | 'sticky-rice-combo'
  | 'sua-tuoi'
  | 'tiger-sugar'
  | 'potato'
  | 'chicken'
  | 'nem'
  | 'bong-lan'
  | 'sinh-nhat'
  | 'banh-ngot'
  | 'banh-mi-sot'
  // btt
  | 'btt-tradi-puree'
  | 'btt-modern-puree'
  | 'btt-salted-egg'
  | 'btt-ntc'
  | 'btt-box'
  // bsn
  | 'fresh-fruit'
  | 'bcake-kid'
  | 'bcake-mousse'
  | 'special-taste'
  | 'bcake-bltm'
  | 'bcake-flower'
  | 'bcake-event'
  | 'bcake-xmas'
  | 'bcake-korea'
  | 'bcake-socola'
  | 'bcake-strawberry'
  | 'bcake-order'

type TOrderPlatformProperty = {
  code: TOrderPlatform
  normalPrice: number
  salePrice?: number
}

type TBundledProduct = {
  _id: string
  product:
    | string
    | {
        price: number
        salePrice: number
      }
  sku: string
  description: string
  quantity: number
}

export interface IProduct {
  _id?: string
  sku: string
  description: string
  latinDescription?: string
  comboId?: string
  comboName?: string
  normalPrice: number
  quantity?: number
  salePrice?: number
  quantityForAFormula?: number
  category: TStoreCategory
  subCat?: TStoreSubCat
  subCatWorkName?: string
  imageLink?: string
  defaultModifierSkus?: string[]
  variants?: IVariant[]
  noLoyalty?: boolean
  barcodes?: string[]
  rawProducts?: {
    description: string
    sku: string
    quantity?: number
    inputQuantity?: number
    product: string
  }[]
  comboElements?: TComboElement[]

  orderPlatforms?: TOrderPlatformProperty[]
  inStock?: boolean
  savorCategory?: string
  savorTag?: string
  bundledProducts?: TBundledProduct[]
  restockNote?: string
  isModifier?: boolean
  isCombo?: boolean
  canDiscount?: boolean
  shelfLife?: number
}

export type TSavorCategory =
  | 'Đồ uống'
  | 'Món'
  | 'Rau'
  | 'Addon'
  | 'Bánh mì'
  | 'Vận hành'
  | 'Đồ ăn'
  | 'Đồ uống Savor'
  | 'Tráng miệng'
  | 'Nước ép'
  | 'Món xôi'

type TServerVariant = {
  group: { [key: string]: string | number }
  sku: string
  description: string
  product: {
    sku: string
    price: number
    salePrice?: number
    orderPlatforms?: TOrderPlatformProperty[]
  }
}

type TWorkComboElement = {
  name: string
  itemOptions: [
    {
      sku: string
      description: string
      countAs: number
    }
  ]
  maxQuantity: number
}

export interface IWorkProduct {
  sku: string
  description: string
  price: number
  salePrice?: number
  savorCategory: TSavorCategory
  noLoyalty?: boolean
  isCombo?: boolean
  comboElements?: TWorkComboElement[]
  imageLink?: string
  barcodes?: string[]
  convertProducts?: object[]
  defaultModifiers?: {
    sku: string
  }[]
  variants?: TServerVariant[]
  orderPlatforms?: TOrderPlatformProperty[]
  isModifier?: boolean
  bundledProducts?: TBundledProduct[]
}
export interface IConvertProduct {
  sku: string
  description: string
  outputQuantity?: number | undefined | null
  quantity?: number | undefined | null
}

export interface IVariant {
  group: { [key: string]: string | number }
  sku: string
  description: string
  normalPrice: number
  salePrice?: number
  orderPlatforms?: TOrderPlatformProperty[]
}

type TWorkProductMap = { [key: string]: IWorkProduct }

export class ProductStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  storeProducts: IProduct[] = []
  workProducts: IWorkProduct[] = []

  numProducts = 0
  convertCategory(product: IWorkProduct): TStoreCategory {
    let convertedCategory: TStoreCategory = 'other'
    const catName = get(product, 'category.cat.name')
    if (catName) {
      switch (catName) {
        case 'Bánh sinh nhật':
          convertedCategory = 'birthday-cake'
          break
        case 'Phụ kiện BSN':
          convertedCategory = 'birthday-cake'
          break
        case 'Bánh trung thu':
          convertedCategory = 'mooncake'
          break
        case 'Phụ kiện BTT':
          convertedCategory = 'mooncake'
          break
        case 'Bánh kẹo Tết':
          convertedCategory = 'banh-keo-tet'
          break
        default:
          convertedCategory = 'other'
      }
    }
    if (convertedCategory === 'other') {
      switch (product.savorCategory) {
        default:
          return 'other'
      }
    }
    return convertedCategory
  }

  convertServerVariant(serverVariant: TServerVariant, workProductMap: TWorkProductMap) {
    const matchWorkProduct = workProductMap[serverVariant.sku]
    return {
      group: serverVariant.group,
      sku: serverVariant.sku,
      description: serverVariant.description,
      normalPrice: serverVariant.product.price,
      salePrice: serverVariant.product.salePrice,
      orderPlatforms: matchWorkProduct
        ? matchWorkProduct.orderPlatforms
        : serverVariant.product.orderPlatforms
    }
  }

  getCakeOptionsFromWork(
    workComboElements: TWorkComboElement[],
    workProductMap: TWorkProductMap
  ): TItemOption[] {
    const cakeOptions: TItemOption[] = get(
      workComboElements.find(comboElement => comboElement.name === 'Bánh ngọt'),
      'itemOptions',
      []
    )

    return cakeOptions.reduce((result: TItemOption[], option: TItemOption) => {
      const newOption: TItemOption = {
        sku: option.sku,
        description: option.description,
        countAs: option.countAs
      }

      const matchWorkProduct = workProductMap[option.sku]
      if (matchWorkProduct) {
        newOption.price = matchWorkProduct.price
      }

      result.push(newOption)
      return result
    }, [])
  }

  get products(): IProduct[] {
    type TStoreProductMap = {
      [key: string]: IProduct
    }

    const storeProductMap: TStoreProductMap = this.storeProducts.reduce(
      (result, product: IProduct) => {
        result[product.sku] = product
        if (product.variants && product.variants.length > 0) {
          product.variants.forEach(variant => {
            result[variant.sku] = product
          })
        }
        return result
      },
      {} as TStoreProductMap
    )

    const workProductMap: TWorkProductMap = this.workProducts.reduce(
      (result: any, product: IWorkProduct) => {
        result[product.sku] = product
        return result
      },
      {}
    )

    const products = this.storeProducts
      .map(storeProduct => {
        const newProduct = clone(storeProduct)

        const matchWorkProduct = workProductMap[storeProduct.sku]
        if (matchWorkProduct) {
          newProduct.normalPrice = matchWorkProduct.price
          newProduct.description = matchWorkProduct.description
          newProduct.salePrice = matchWorkProduct.salePrice

          if (matchWorkProduct.defaultModifiers) {
            newProduct.defaultModifierSkus = matchWorkProduct.defaultModifiers.map(
              modifier => modifier.sku
            )
          }

          if (matchWorkProduct.variants) {
            newProduct.variants = matchWorkProduct.variants.map(variant =>
              this.convertServerVariant(variant, workProductMap)
            )
          }

          if (matchWorkProduct.orderPlatforms) {
            newProduct.orderPlatforms = matchWorkProduct.orderPlatforms
          }

          newProduct.isModifier = matchWorkProduct.isModifier
          if (matchWorkProduct.bundledProducts && matchWorkProduct.bundledProducts.length) {
            newProduct.bundledProducts = matchWorkProduct.bundledProducts
          }

          if (
            newProduct.sku === 'V1408' &&
            matchWorkProduct.comboElements &&
            matchWorkProduct.comboElements.length
          ) {
            const workCakeOptions = this.getCakeOptionsFromWork(
              matchWorkProduct.comboElements,
              workProductMap
            )
            newProduct.comboElements = [
              {
                name: '1',
                itemOptions: cloneDeep(workCakeOptions),
                maxQuantity: 1
              },
              {
                name: '2',
                itemOptions: cloneDeep(workCakeOptions),
                maxQuantity: 1
              }
            ]
          }
        }

        return newProduct
      })
      .concat(
        this.workProducts
          .filter(product => {
            return !storeProductMap[product.sku]
          })
          .map(product => {
            let comboElements: TComboElement[] = []
            if (product.sku === 'V1408' && product.comboElements && product.comboElements.length) {
              const cakeOptions = this.getCakeOptionsFromWork(product.comboElements, workProductMap)
              comboElements = [
                {
                  name: '1',
                  itemOptions: cloneDeep(cakeOptions),
                  maxQuantity: 1
                },
                {
                  name: '2',
                  itemOptions: cloneDeep(cakeOptions),
                  maxQuantity: 1
                }
              ]
            }
            return {
              sku: product.sku,
              description: product.description,
              normalPrice: product.price,
              salePrice: product.salePrice,
              category: this.convertCategory(product),
              subCatWorkName: get(product, 'category.subCat.name') || 'Chưa phân loại',
              imageLink: product.imageLink,
              noLoyalty: product.noLoyalty,
              barcodes: product.barcodes,
              isCombo: product.isCombo,
              variants: product.variants
                ? product.variants.map(variant =>
                    this.convertServerVariant(variant, workProductMap)
                  )
                : undefined,
              orderPlatforms: product.orderPlatforms,
              defaultModifierSkus: product.defaultModifiers
                ? product.defaultModifiers.map(modifier => modifier.sku)
                : undefined,
              isModifier: product.isModifier,
              bundledProducts: product.bundledProducts,
              comboElements
            }
          })
      )
      .map(product => ({
        ...product,
        latinDescription: getSlug(product.description, {
          lang: 'vn',
          separator: ' ',
          custom: { ',': '' }
        })
      }))

    return products
  }

  get drinkProducts(): IProduct[] {
    return this.products.filter(product => product.category === 'drink')
  }

  removeProduct() {
    this.products.splice(0, 1)
  }

  loadStoreProducts(products: IProduct[]) {
    this.storeProducts = cloneDeep(products).map(product => ({
      ...product,
      canDiscount: true
    }))
  }

  loadWorkProducts(products: IWorkProduct[]) {
    this.workProducts = products
  }

  async init() {
    this.loadStoreProducts(products)

    this.fetchWorkProducts().catch(e => {
      handleAgentErrorResponse(e)
    })
  }

  async fetchWorkProducts() {
    const products = await this.rootStore.agent.get<IWorkProduct[]>(
      '/api_savor/selling-products-savor-mobile'
    )
    this.loadWorkProducts(products)
  }
}

decorate(ProductStore, {
  storeProducts: observable,
  workProducts: observable,
  products: computed,
  drinkProducts: computed,
  loadStoreProducts: action,
  removeProduct: action,
  loadWorkProducts: action
})
