import React, { ChangeEvent, Fragment, SyntheticEvent } from 'react'
import { map, groupBy, clone, findLast, find, get, flatMapDeep } from 'lodash'
import {
  Container,
  Header,
  Segment,
  List,
  Checkbox,
  Form,
  Table,
  Button,
  Dropdown,
  Menu,
  DropdownProps,
  Label,
  Grid
} from 'semantic-ui-react'
import request from 'superagent'
import { observer, inject } from 'mobx-react'
import { RootStore } from '../../model/root-store'
import { handleAgentErrorResponse } from '../../libs/error-handler'
import { toast } from 'react-toastify'
import Downshift from 'downshift'
import { IProduct, IConvertProduct as IRawProduct } from '../../model/product.store'

import { ProductInput } from '../../components/ProductInput'
import { products } from '../../model/product.data'
import { some } from 'bluebird'

interface FoodPrepProps {
  rootStore?: RootStore
}
interface FoodPrepState {
  products: IProduct[]
  outputProduct: IProduct | null
  outputQuantity?: number | null
  inputQuantity?: number
  saving: boolean
}

export const FoodPrep = inject('rootStore')(
  observer(
    class extends React.Component<FoodPrepProps, FoodPrepState> {
      state: FoodPrepState = {
        products: [],
        outputProduct: null,
        outputQuantity: null,
        saving: false
      }

      getFoodPrepProducts = () => {
        this.props
          .rootStore!.agent.get('/api_savor/food-prep-products')
          .then((products: IProduct[]) => {
            this.setState({ products: products })
            this.setState({ outputProduct: null })
            this.setState({ outputQuantity: null })
          })
      }

      handleClickItem = (e: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
        if (value === '') {
          this.setState({ outputProduct: null })
        } else {
          const product =
            this.state.products.find(product => {
              return product.sku === value
            }) || null
          this.setState({ outputProduct: product })
        }
      }

      handlSelectOutputItem = () => {
        if (!this.state.outputQuantity) {
          return toast.error('Chưa có số lượng sản phẩm đầu ra')
        }

        const noneQuantityProduct = (this.state.outputProduct!.rawProducts
          ? this.state.outputProduct!.rawProducts
          : []
        ).find(rawProduct => {
          return !rawProduct.quantity && !rawProduct.inputQuantity
        })

        if (noneQuantityProduct) {
          return toast.error('Có sản phẩm nguyên liệu chưa có số lượng')
        }

        const currentShift = this.props.rootStore!.currentShiftStore.currentShift
        const outputQuantity =
          Math.round(
            this.state.outputQuantity *
              get(this.state.outputProduct!, 'quantityForAFormula', 1) *
              10
          ) / 10
        const expectedInputItems = (this.state.outputProduct!.rawProducts
          ? this.state.outputProduct!.rawProducts
          : []
        ).map(rawProduct => {
          return {
            product: rawProduct.product,
            sku: rawProduct.sku,
            description: rawProduct.description,
            quantity:
              Math.round(
                (rawProduct.quantity
                  ? rawProduct.quantity * outputQuantity * 10
                  : rawProduct.inputQuantity
                  ? rawProduct.inputQuantity * 10
                  : rawProduct.inputQuantity) || 0
              ) / 10
          }
        })

        this.setState({ saving: true })

        this.props
          .rootStore!.agent.post('/pl-tasks/savor', {
            status: 'done',
            outlet: currentShift!.outlet,
            destination: currentShift!.outlet,
            plTaskType: 'new',
            numFormula: this.state.outputQuantity,
            product: this.state.outputProduct!._id,
            sku: this.state.outputProduct!.sku,
            description: this.state.outputProduct!.description,
            deliverQuantity: outputQuantity,
            confirmQuantity: outputQuantity,
            orderQuantity: outputQuantity,
            actualInputItems: expectedInputItems,
            expectedInputItems: expectedInputItems
          })
          .then(() => {
            toast.success('Tạo Pl Task chuyển đổi thành công', { autoClose: false })
            this.setState({ outputProduct: null, outputQuantity: null, saving: false })
          })
          .catch(e => {
            handleAgentErrorResponse(e)
            this.setState({ saving: false })
          })
      }

      handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const num = Number(e.target.value)
        this.setState({ outputQuantity: num })
      }

      componentDidMount() {
        this.getFoodPrepProducts()
      }

      render() {
        return (
          <div className="ui container" style={{ marginTop: '2em' }}>
            <Header as="h2">
              Chọn sản phẩm quy đổi:{' '}
              <Dropdown
                placeholder="Ấn vào đây để chọn sản phẩm"
                options={flatMapDeep(
                  this.state.products.map(product => {
                    return {
                      key: product._id,
                      value: product.sku,
                      text: product.sku,
                      content: `${product.sku} - ${product.description}`
                    }
                  })
                )}
                onChange={this.handleClickItem}
              />
            </Header>

            {!!this.state.outputProduct && (
              <Fragment>
                <Segment>
                  {this.state.outputProduct.sku} - {this.state.outputProduct.description} -{' '}
                  {this.state.outputProduct.quantityForAFormula
                    ? `1 công thức gồm ${this.state.outputProduct.quantityForAFormula} thành phẩm`
                    : ''}
                  <Form>
                    <Form.Field>
                      <label>
                        Số lượng{' '}
                        {this.state.outputProduct.quantityForAFormula ? 'công thức' : 'thành phẩm'}
                      </label>
                      <input
                        type="number"
                        onChange={this.handleInputChange}
                        value={this.state.outputQuantity || ''}
                      />
                    </Form.Field>
                  </Form>
                </Segment>
                <Segment>
                  <table className="ui table">
                    <thead>
                      <tr>
                        <th>Mã nguyên liệu</th>
                        <th>Tên nguyên liệu</th>
                        <th>Số lượng nguyên liệu</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.outputProduct.rawProducts!.map((rawProduct, index) => (
                        <tr key={rawProduct.sku}>
                          <td>{rawProduct.sku}</td>
                          <td>{rawProduct.description}</td>
                          <td>
                            {!!rawProduct.quantity ? (
                              Math.round(
                                rawProduct.quantity *
                                  (this.state.outputQuantity || 0) *
                                  get(this.state.outputProduct!, 'quantityForAFormula', 1) *
                                  10
                              ) / 10
                            ) : (
                              <Form>
                                <Form.Field>
                                  <input
                                    type="number"
                                    onChange={e => {
                                      if (this.state.outputProduct) {
                                        const selectedProduct = clone(this.state.outputProduct)
                                        selectedProduct.rawProducts =
                                          selectedProduct.rawProducts || []
                                        if (
                                          selectedProduct.rawProducts &&
                                          selectedProduct.rawProducts[index]
                                        ) {
                                          selectedProduct.rawProducts[
                                            index
                                          ].inputQuantity = parseInt(e.target.value)
                                        }

                                        this.setState({
                                          outputProduct: selectedProduct
                                        })
                                      }
                                    }}
                                    value={this.state.inputQuantity}
                                  />
                                </Form.Field>
                              </Form>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        color="red"
                        onClick={() => this.handlSelectOutputItem()}
                        disabled={this.state.saving}
                      >
                        Chuyển đổi
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Fragment>
            )}
          </div>
        )
      }
    }
  )
)
