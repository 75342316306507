import React, { useContext, useMemo } from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../model/root-store'

interface PrivateRouteProps {
  component: any
  [key: string]: any
}

export const PrivateRoute = observer(function({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const { authenticationStore } = useContext(RootStoreContext)
  const MyRoute = useMemo(
    () => (
      <Route
        {...rest}
        render={props =>
          authenticationStore.isAuthenticated ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    ),
    [rest.location.pathname, rest.location.search]
  )
  return MyRoute
})
