import React from 'react'
import { observer, inject } from 'mobx-react'
import { Header, Card, Dimmer, Loader, Button, Transition } from 'semantic-ui-react'
import { IOrder } from '../../model/list-order.store'

import { toast } from 'react-toastify'
import Bluebird from 'bluebird'
import { OrderCard } from './OrderCard'
import { RootStore } from '../../model/root-store'
import { handleAgentErrorResponse } from '../../libs/error-handler'

interface ListOrdersViewProps {
  rootStore?: RootStore
}

export const ListOrdersView = inject('rootStore')(
  observer(
    class extends React.Component<ListOrdersViewProps> {
      state = {
        loadingOrders: false
      }

      listingOrders: Bluebird<any> | null = null

      loadOrders = async () => {
        this.setState({ loadingOrders: true })
        try {
          await this.props.rootStore!.listOrderStore.listOrders({ status: 'ordered' })
          this.setState({ loadingOrders: false })
        } catch (e) {
          handleAgentErrorResponse(e)
        }
      }

      handleNewOrderEvent = (order: IOrder) => {
        toast.info(`Mới có đơn hàng ${order.orderId}`)
        this.props.rootStore!.listOrderStore.findAndUpdateOrder(order)
      }

      handleDoneOrderEvent = (order: IOrder) => {
        this.props.rootStore!.listOrderStore.findAndUpdateOrder(order)
      }

      handleCancelOrderEvent = (order: IOrder) => {
        this.props.rootStore!.listOrderStore.findAndUpdateOrder(order)
      }

      componentDidMount() {
        this.loadOrders()
        this.props.rootStore!.realtimeEvent.subcribe('newOrder', this.handleNewOrderEvent)
        this.props.rootStore!.realtimeEvent.subcribe('doneOrder', this.handleDoneOrderEvent)
        this.props.rootStore!.realtimeEvent.subcribe('cancelOrder', this.handleCancelOrderEvent)
      }

      componentWillUnmount() {
        if (this.listingOrders) this.listingOrders.cancel()
        this.props.rootStore!.realtimeEvent.unsubcribe('newOrder', this.handleNewOrderEvent)
        this.props.rootStore!.realtimeEvent.unsubcribe('doneOrder', this.handleDoneOrderEvent)
        this.props.rootStore!.realtimeEvent.unsubcribe('cancelOrder', this.handleCancelOrderEvent)
      }

      render() {
        const rootStore = this.props.rootStore
        const listOrderStore = rootStore!.listOrderStore
        const savorStoreOutlets = rootStore!.outletListStore.savorStoreOutlets
        const canFilterOutlet =
          rootStore!.authenticationStore.canUseCurrentOutlet &&
          !rootStore!.currentShiftStore.loggedInShift
        const _uiStore = this.props.rootStore!.uiStore

        return (
          <div
            style={{
              height: '100%',
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: '5px',
              overflowY: 'auto',
              overflowX: 'hidden',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <Dimmer inverted active={this.state.loadingOrders}>
              <Loader />
            </Dimmer>
            <Header style={{ marginTop: 15 }}>
              List Orders &nbsp;
              {canFilterOutlet && (
                <>
                  <Button
                    inverted
                    active={_uiStore!.filteredOutlet === 'all'}
                    onClick={() => _uiStore!.changeFilteredOutlet('all')}
                    size="tiny"
                    color="green"
                  >
                    All
                  </Button>
                  {savorStoreOutlets.map(outlet => (
                    <Button
                      key={outlet._id}
                      active={_uiStore!.filteredOutlet === outlet._id}
                      onClick={() => _uiStore!.changeFilteredOutlet(outlet._id)}
                      inverted
                      size="tiny"
                      color="green"
                    >
                      {outlet.code} - {outlet.name}
                    </Button>
                  ))}
                </>
              )}
            </Header>
            {this.props.rootStore!.listOrderStore.orderedOrders.length === 0 && (
              <Header>
                <Header.Content>Chưa có order nào.</Header.Content>
              </Header>
            )}
            <Transition.Group animation="fade up" as={Card.Group} duration={400} itemsPerRow={2}>
              {listOrderStore!.orderedOrders.map(
                order =>
                  (order.outlet === _uiStore!.filteredOutlet ||
                    _uiStore!.filteredOutlet === 'all') && (
                    <OrderCard order={order} key={order.orderId} />
                  )
              )}
            </Transition.Group>
          </div>
        )
      }
    }
  )
)
